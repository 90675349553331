import { sliceResetFns } from "store/store";
import { StateCreator } from "zustand";
import { ToolsStairBoxSlice } from "./stairBox.types";
import { SCAFFOLD_PLANKTYPE, SCAFFOLD_SUPPLIER } from "shared/enums/scaffold";
import { STAIR_TYPE } from "shared/enums/world";

/** Initial state */
const initialToolsStairBoxState = {
  toolsStairBoxHeight: 10,
  toolsStairBoxWidth: 0.73,
  toolsStairBoxDepth: 2.57,
  toolsStairBoxConsoleWidth: 0.39,
  toolsStairBoxFallProtectionHeight: 1,
  toolsStairBoxStairInnerGuardRail: true,
  toolsStairBoxStairGuardRail: true,
  toolsStairBoxFallProtectionNet: false,
  toolsStairBoxTopVariant: null,
  toolsStairBoxStair: STAIR_TYPE.SINGLE,
  toolsStairBoxGuardRails: [true, false, true, true] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsStairBoxConsoles: [false, false, false, false] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsStairBoxFrames: [10, 8, 6, 4, 2],
  toolsStairBoxPlankMaterial: SCAFFOLD_PLANKTYPE.STEEL,
  toolsStairBoxRotationY: null,
  toolsStairBoxDistanceToWall: 0.3,
  toolsStairBoxDistanceToWallEnabled: false,
  toolsStairBoxSupplier: SCAFFOLD_SUPPLIER.LAYHER
};

const createToolsStairBoxSlice: StateCreator<
  ToolsStairBoxSlice,
  [],
  [],
  ToolsStairBoxSlice
> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialToolsStairBoxState));

  /** Return state */
  return {
    ...initialToolsStairBoxState,
    toolsStairBoxActions: {
      setToolsStairBoxStair: (stair) => {
        set({ toolsStairBoxStair: stair });
      },
      setToolsStairBoxHeight: (height) => {
        set({ toolsStairBoxHeight: height });
      },
      setToolsStairBoxDepth: (depth) => {
        set({ toolsStairBoxDepth: depth });
      },
      setToolsStairBoxWidth: (width) => {
        set({ toolsStairBoxWidth: width });
      },
      setToolsStairBoxGuardRails: (options) => {
        set({ toolsStairBoxGuardRails: options });
      },
      setToolsStairBoxFrames: (frames) => {
        set({ toolsStairBoxFrames: frames });
      },
      setToolsStairBoxPlankMaterial: (material) => {
        set({ toolsStairBoxPlankMaterial: material });
      },
      setToolsStairBoxConsoles: (options) => {
        set({ toolsStairBoxConsoles: options });
      },
      setToolsStairBoxConsoleWidth: (width) => {
        set({ toolsStairBoxConsoleWidth: width });
      },
      setToolsStairBoxFallProtectionHeight: (height) => {
        set({ toolsStairBoxFallProtectionHeight: height });
      },
      setToolsStairBoxFallProtectionNet: (net) => {
        set({ toolsStairBoxFallProtectionNet: net });
      },
      setToolsStairBoxTopVariant: (topVariant) => {
        set({ toolsStairBoxTopVariant: topVariant });
      },
      setToolsStairBoxRotationY: (rotation) => {
        set({ toolsStairBoxRotationY: rotation });
      },
      setToolsStairBoxDistanceToWall: (distance) => {
        set({ toolsStairBoxDistanceToWall: distance });
      },
      setToolsStairBoxSupplier: (supplier) => {
        set({ toolsStairBoxSupplier: supplier });
      },
      setToolsStairBoxStairGuardRail: (guardRail) => {
        set({ toolsStairBoxStairGuardRail: guardRail });
      },
      setToolsStairBoxStairInnerGuardRail: (innerGuardRail) => {
        set({ toolsStairBoxStairInnerGuardRail: innerGuardRail });
      },
      setToolsStairBoxDistanceToWallEnabled: (enabled: boolean) => {
        set({ toolsStairBoxDistanceToWallEnabled: enabled });
      }
    }
  };
};

export default createToolsStairBoxSlice;
