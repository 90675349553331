import { calcLengthPartitions } from "suppliers/scaffold/scaffold.utils";
import {
  MIN_STANDARD_HEIGHT,
  SPIGOT_LENGTH
} from "suppliers/scaffold/constants";
import { minus, plus } from "math";
import { BoxFrame } from "shared/interfaces/firestore";
import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import { calculateOptimalSegmentLengthCombination } from "math/optimizers";
import { componentsDecks } from "../components";

export const generateOptimalPlankConfiguration = (props: {
  length: number;
  width: number;
  plankType?: SCAFFOLD_PLANKTYPE;
}) => {
  const { length, width, plankType } = props;
  if (!plankType) return [];
  const decks = componentsDecks.filter((deck) => deck.material === plankType);

  const correctLengthDecks = decks.filter((deck) => deck.length === length);

  if (correctLengthDecks.length === 0) {
    return [];
  }

  const uniqueWidths = [
    ...new Set(correctLengthDecks.map((deck) => deck.width))
  ];

  return calculateOptimalSegmentLengthCombination({
    lengthSegments: uniqueWidths,
    targetLength: width
  });
};

/** Get valid frames with height adjusted to world position */
export const getValidFrames = (props: {
  boxPosition: [number, number, number];
  frames?: BoxFrame[];
  height: number;
  standardLengths: number[];
}) => {
  const { boxPosition, frames, height, standardLengths } = props;

  if (!frames) return [];
  const worldFrames = frames.map((frame) => ({
    ...frame,
    ...(frame.platform && {
      platform: plus(frame.platform, boxPosition[1])
    }),
    height: plus(frame.height, boxPosition[1])
  }));
  /** Top deck height */
  const topDeckHeight = plus(boxPosition[1], height, SPIGOT_LENGTH);

  /** Frames or not: requires pre calculation to be made:
   * - Top standard length
   * - Standard start height
   */
  const topStandardLength = calcLengthPartitions(
    minus(plus(topDeckHeight, SPIGOT_LENGTH), MIN_STANDARD_HEIGHT),
    standardLengths
  );

  return worldFrames.filter(
    (frame) => frame.height > topStandardLength.remainingLength
  );
};
