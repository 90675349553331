import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { WorldHandlerSlice, WorldObjects } from "./world.interface";
import {
  addBaseCollarsToGraph,
  removeBaseCollarsFromGraph
} from "./baseCollar/baseCollar.utils";
import {
  addStandardsToGraph,
  removeStandardsFromGraph
} from "./standard/standard.utils";
import {
  addLedgersToGraph,
  removeLedgersFromGraph
} from "./ledger/ledger.utils";
import Graph from "graphology";
import {
  addBasePlatesToGraph,
  removeBasePlatesFromGraph
} from "./basePlate/basePlate.utils";
import {
  addBaseBoardsToGraph,
  removeBaseBoardsFromGraph
} from "./baseBoard/baseBoard.utils";
import { sliceResetFns } from "store/store";
import { WORLD_RENDER_MODE } from "./world.enums";
import {
  addBoxesToGraph,
  deleteAndAddComponents,
  getBoxComponentsFromState,
  getComponentsGraphData,
  removeBoxComponentEdges
} from "./box/box.utils";
import { BoxComponents } from "./box/box.interface";
import { addPlanksToGraph, removePlanksFromGraph } from "./plank/plank.utils";
import {
  addConsolesToGraph,
  removeConsolesFromGraph
} from "./console/console.utils";
import {
  addToeBoardsToGraph,
  removeToeBoardsFromGraph
} from "./toeBoard/toeBoard.utils";
import { removeComponentsFromGraph } from "./world.utils";
import {
  addStairwaysToGraph,
  removeStairwaysFromGraph
} from "./stairway/stairway.utils";
import {
  addStairwayInnerGuardRailsToGraph,
  removeStairwayInnerGuardRailsFromGraph
} from "./stairwayInnerGuardrail/stairwayInnerGuardRails.utils";
import {
  addStairwayGuardRailsToGraph,
  removeStairwayGuardRailsFromGraph
} from "./stairwayGuardrail/stairwayGuardRails.utils";
import {
  addDiagonalBracesToGraph,
  removeDiagonalBracesFromGraph
} from "./diagonalBrace/diagonalBrace.utils";
import { removeAnchorsFromGraph } from "./anchor/anchor.utils";
import {
  addBeamSpigotsToGraph,
  removeBeamSpigotsFromGraph
} from "./beamSpigot/beamSpigot.utils";
import { applyGroundDimensions } from "./ground/ground";
import { applyPolygonDimensions } from "./polygon/polygon";
import { applyBoxDimensions } from "./box/box";
import { applyBlueprintDimensions } from "./blueprint/blueprint";
import { applyIfcDimensions } from "./ifc/ifc";
import { addFramesToGraph, removeFramesFromGraph } from "./frame/frame.utils";
import {
  addGuardRailsToGraph,
  removeGuardRailsFromGraph
} from "./guardRail/guardRail.utils";
import { Box3, Vector3 } from "three";
import { ComponentType } from "world/core/Standard/Standard.types";
import { nanoid } from "nanoid";
import {
  Box,
  Ground,
  Polygon,
  Roof,
  StandardPosition
} from "shared/interfaces/firestore";
import { genId } from "math/generators";

/** Initial state */
const initialWorldState = {
  worldCameraMode: "orthographic",
  worldToolMode: null,
  worldRenderMode: WORLD_RENDER_MODE.BOX,
  worldSelectedIds: [],
  worldSelectedObjects: [],
  worldLookAt: [""],
  worldMeasurementsHidden: false
};

const createWorldSlice: StateCreator<Store, [], [], WorldHandlerSlice> = (
  set,
  get
) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialWorldState));

  return {
    ...initialWorldState,
    worldActions: {
      setWorldCameraMode: (worldCameraMode) => {
        set(() => ({ worldCameraMode }));
      },
      setWorldLookAt: (ids: string[]) => {
        set(() => ({ worldLookAt: ids }));
      },
      setWorldToolMode: (worldToolMode) => {
        set(() => ({ worldToolMode }));
      },
      setWorldRenderMode: (worldRenderMode) => {
        set(() => ({ worldRenderMode }));
      },
      setWorldSelectedIds: (worldSelectedIds) => {
        set(() => ({ worldSelectedIds }));
      },
      setWorldSelectedObjects: (worldSelectedObjects) => {
        set(() => ({
          worldSelectedObjects,
          worldSelectedIds: worldSelectedObjects.map((o) => o.userData.id)
        }));
      },
      addWorldSelectedObjects: (addSelectedObjects) => {
        set((state) => {
          let copyWorldSelectedObjects = [...state.worldSelectedObjects];
          const copyWorldUuids = copyWorldSelectedObjects.map((o) => o.uuid);

          addSelectedObjects.forEach((addObject) => {
            if (copyWorldUuids.includes(addObject.uuid)) {
              copyWorldSelectedObjects = copyWorldSelectedObjects.filter(
                (o) => o.uuid !== addObject.uuid
              );
            } else {
              copyWorldSelectedObjects.push(addObject);
            }
          });

          return {
            worldSelectedObjects: copyWorldSelectedObjects,
            worldSelectedIds: copyWorldSelectedObjects.map((o) => o.userData.id)
          };
        });
      },
      addObjects: (objects: WorldObjects) => {
        set((state) => {
          const newGraph = state.graph.copy();
          if (objects.baseCollars) {
            addBaseCollarsToGraph({
              graph: newGraph,
              baseCollars: objects.baseCollars
            });
          }
          if (objects.standards) {
            addStandardsToGraph({
              graph: newGraph,
              standards: objects.standards
            });
          }
          if (objects.ledgers) {
            addLedgersToGraph({
              graph: newGraph,
              ledgers: objects.ledgers
            });
          }
          if (objects.basePlates) {
            addBasePlatesToGraph({
              graph: newGraph,
              basePlates: objects.basePlates
            });
          }
          if (objects.planks) {
            addPlanksToGraph({
              graph: newGraph,
              planks: objects.planks
            });
          }

          return {
            graph: newGraph,
            ...(objects.polygons && {
              polygons: [...state.polygons, ...objects.polygons]
            }),
            ...(objects.roofs && {
              roofs: [...state.roofs, ...objects.roofs]
            }),
            ...(objects.grounds && {
              grounds: [...state.grounds, ...objects.grounds]
            }),
            ...(objects.measurements && {
              measurements: [...state.measurements, ...objects.measurements]
            }),
            ...(objects.ledgers && {
              ledgers: [...state.ledgers, ...objects.ledgers]
            })
          };
        });
      },
      getWorldObjects: (): WorldObjects => {
        const store = get();
        return {
          diagonalBraces: store.diagonalBraces,
          anchors: store.anchors,
          baseCollars: store.baseCollars,
          baseBoards: store.baseBoards,
          basePlates: store.basePlates,
          beamSpigots: store.beamSpigots,
          standards: store.standards,
          ledgers: store.ledgers,
          grounds: store.grounds,
          polygons: store.polygons,
          roofs: store.roofs,
          planks: store.planks,
          toeBoards: store.toeBoards,
          consoles: store.consoles,
          stairways: store.stairways,
          boxes: store.boxes,
          stairwayInnerGuardRails: store.stairwayInnerGuardRails,
          stairwayGuardRails: store.stairwayGuardRails,
          measurements: store.measurements,
          frames: store.frames,
          guardRails: store.guardRails
        };
      },
      removeObjects: (ids: string[]) => {
        set((state) => {
          const newGraph = state.graph.copy();

          removeLedgersFromGraph({
            graph: newGraph,
            ledgers: state.ledgers.filter((ledger) => ids.includes(ledger.id))
          });
          removeStandardsFromGraph({
            graph: newGraph,
            standards: state.standards.filter((standard) =>
              ids.includes(standard.id)
            )
          });
          removeFramesFromGraph({
            graph: newGraph,
            frames: state.frames.filter((frame) => ids.includes(frame.id))
          });
          removeGuardRailsFromGraph({
            graph: newGraph,
            guardRails: state.guardRails.filter((guardRail) =>
              ids.includes(guardRail.id)
            )
          });
          removeBaseCollarsFromGraph({
            graph: newGraph,
            baseCollars: state.baseCollars.filter((baseCollar) =>
              ids.includes(baseCollar.id)
            )
          });
          removeBasePlatesFromGraph({
            graph: newGraph,
            basePlates: state.basePlates.filter((basePlate) =>
              ids.includes(basePlate.id)
            )
          });
          removeBaseBoardsFromGraph({
            graph: newGraph,
            baseBoards: state.baseBoards.filter((baseBoard) =>
              ids.includes(baseBoard.id)
            )
          });
          removePlanksFromGraph({
            graph: newGraph,
            planks: state.planks.filter((plank) => ids.includes(plank.id))
          });

          removeConsolesFromGraph({
            graph: newGraph,
            consoles: state.consoles.filter((console) =>
              ids.includes(console.id)
            )
          });
          removeToeBoardsFromGraph({
            graph: newGraph,
            toeBoards: state.toeBoards.filter((toeBoard) =>
              ids.includes(toeBoard.id)
            )
          });
          removeStairwaysFromGraph({
            graph: newGraph,
            stairways: state.stairways.filter((stairway) =>
              ids.includes(stairway.id)
            )
          });
          removeStairwayInnerGuardRailsFromGraph({
            graph: newGraph,
            stairwayInnerGuardRails: state.stairwayInnerGuardRails.filter(
              (stairwayInnerGuardRail) =>
                ids.includes(stairwayInnerGuardRail.id)
            )
          });
          removeStairwayGuardRailsFromGraph({
            graph: newGraph,
            stairwayGuardRails: state.stairwayGuardRails.filter(
              (stairwayGuardRail) => ids.includes(stairwayGuardRail.id)
            )
          });
          removeDiagonalBracesFromGraph({
            graph: newGraph,
            diagonalBraces: state.diagonalBraces.filter((diagonalBrace) =>
              ids.includes(diagonalBrace.id)
            )
          });
          removeAnchorsFromGraph({
            graph: newGraph,
            anchors: state.anchors.filter((anchor) => ids.includes(anchor.id))
          });
          removeBeamSpigotsFromGraph({
            graph: newGraph,
            beamSpigots: state.beamSpigots.filter((beamSpigot) =>
              ids.includes(beamSpigot.id)
            )
          });
          const filteredBoxIds = state.boxes
            .filter((box) => ids.includes(box.id))
            .map((b) => b.id);

          if (filteredBoxIds.length === 0) {
            state.boxes.forEach((box) => {
              if (newGraph.hasNode(box.id)) {
                const neighbor = newGraph.undirectedNeighbors(box.id);
                if (neighbor.length === 0) {
                  filteredBoxIds.push(box.id);
                }
              }
            });
          }
          const componentsToRemove = removeBoxComponentEdges({
            graph: newGraph,
            boxIds: filteredBoxIds
          });
          const componentsGraphData = getComponentsGraphData({
            standards: state.standards.filter((standard) =>
              componentsToRemove.includes(standard.id)
            ),
            ledgers: state.ledgers.filter((ledger) =>
              componentsToRemove.includes(ledger.id)
            ),
            basePlates: state.basePlates.filter((basePlate) =>
              componentsToRemove.includes(basePlate.id)
            ),
            baseCollars: state.baseCollars.filter((baseCollar) =>
              componentsToRemove.includes(baseCollar.id)
            ),
            baseBoards: state.baseBoards.filter((baseBoard) =>
              componentsToRemove.includes(baseBoard.id)
            ),
            planks: state.planks.filter((plank) =>
              componentsToRemove.includes(plank.id)
            ),
            toeBoards: state.toeBoards.filter((toeBoard) =>
              componentsToRemove.includes(toeBoard.id)
            ),
            beamSpigots: state.beamSpigots.filter((beamSpigot) =>
              componentsToRemove.includes(beamSpigot.id)
            ),
            consoles: state.consoles.filter((console) =>
              componentsToRemove.includes(console.id)
            ),
            stairways: state.stairways.filter((stairway) =>
              componentsToRemove.includes(stairway.id)
            ),
            stairwayInnerGuardRails: state.stairwayInnerGuardRails.filter(
              (stairwayInnerGuardRail) =>
                componentsToRemove.includes(stairwayInnerGuardRail.id)
            ),
            stairwayGuardRails: state.stairwayGuardRails.filter(
              (stairwayGuardRail) =>
                componentsToRemove.includes(stairwayGuardRail.id)
            ),
            diagonalBraces: state.diagonalBraces.filter((diagonalBrace) =>
              componentsToRemove.includes(diagonalBrace.id)
            ),
            anchors: state.anchors.filter((anchor) =>
              componentsToRemove.includes(anchor.id)
            ),
            frames: state.frames.filter((frame) =>
              componentsToRemove.includes(frame.id)
            ),
            guardRails: state.guardRails.filter((guardRail) =>
              componentsToRemove.includes(guardRail.id)
            )
          });

          const removeComponentIds = ids.concat(
            removeComponentsFromGraph({
              graph: newGraph,
              components: Object.values(componentsGraphData).flat()
            })
          );

          return {
            graph: newGraph,
            baseCollars: state.baseCollars.filter(
              (baseCollar) => !removeComponentIds.includes(baseCollar.id)
            ),
            standards: state.standards.filter(
              (standard) => !removeComponentIds.includes(standard.id)
            ),
            ledgers: state.ledgers.filter(
              (ledger) => !removeComponentIds.includes(ledger.id)
            ),
            grounds: state.grounds.filter(
              (ground) => !removeComponentIds.includes(ground.id)
            ),
            baseBoards: state.baseBoards.filter(
              (baseBoard) => !removeComponentIds.includes(baseBoard.id)
            ),
            basePlates: state.basePlates.filter(
              (basePlate) => !removeComponentIds.includes(basePlate.id)
            ),
            polygons: state.polygons.filter(
              (polygon) => !removeComponentIds.includes(polygon.id)
            ),
            roofs: state.roofs.filter(
              (roof) => !removeComponentIds.includes(roof.roofId)
            ),
            planks: state.planks.filter(
              (plank) => !removeComponentIds.includes(plank.id)
            ),
            consoles: state.consoles.filter(
              (console) => !removeComponentIds.includes(console.id)
            ),
            toeBoards: state.toeBoards.filter(
              (toeBoard) => !removeComponentIds.includes(toeBoard.id)
            ),
            stairways: state.stairways.filter(
              (stairway) => !removeComponentIds.includes(stairway.id)
            ),
            stairwayInnerGuardRails: state.stairwayInnerGuardRails.filter(
              (stairwayInnerGuardRail) =>
                !removeComponentIds.includes(stairwayInnerGuardRail.id)
            ),
            stairwayGuardRails: state.stairwayGuardRails.filter(
              (stairwayGuardRail) =>
                !removeComponentIds.includes(stairwayGuardRail.id)
            ),
            diagonalBraces: state.diagonalBraces.filter(
              (diagonaBrace) => !removeComponentIds.includes(diagonaBrace.id)
            ),
            frames: state.frames.filter(
              (frame) => !removeComponentIds.includes(frame.id)
            ),
            guardRails: state.guardRails.filter(
              (guardRail) => !removeComponentIds.includes(guardRail.id)
            ),
            anchors: state.anchors.filter(
              (anchor) => !removeComponentIds.includes(anchor.id)
            ),
            boxes: state.boxes.filter(
              (box) => !filteredBoxIds.includes(box.id)
            ),
            measurements: state.measurements.filter(
              (measurement) => !ids.includes(measurement.id)
            ),
            beamSpigots: state.beamSpigots.filter(
              (beamSpigot) => !ids.includes(beamSpigot.id)
            )
          };
        });
      },
      setObjects: (objects: WorldObjects) => {
        set((state) => {
          const newGraph = new Graph({ type: "undirected" });
          let boxComponents: BoxComponents = {
            beamSpigots: objects.beamSpigots ?? [],
            ledgers: objects.ledgers ?? [],
            baseBoards: objects.baseBoards ?? [],
            baseCollars: objects.baseCollars ?? [],
            basePlates: objects.basePlates ?? [],
            standards: objects.standards ?? [],
            planks: objects.planks ?? [],
            toeBoards: objects.toeBoards ?? [],
            consoles: objects.consoles ?? [],
            stairways: objects.stairways ?? [],
            stairwayInnerGuardRails: objects.stairwayInnerGuardRails ?? [],
            stairwayGuardRails: objects.stairwayGuardRails ?? [],
            diagonalBraces: objects.diagonalBraces ?? [],
            anchors: objects.anchors ?? [],
            frames: objects.frames ?? [],
            guardRails: objects.guardRails ?? []
          };

          if (objects.baseCollars) {
            addBaseCollarsToGraph({
              graph: newGraph,
              baseCollars: objects.baseCollars
            });
          }
          if (objects.beamSpigots) {
            addBeamSpigotsToGraph({
              graph: newGraph,
              beamSpigots: objects.beamSpigots
            });
          }
          if (objects.standards) {
            addStandardsToGraph({
              graph: newGraph,
              standards: objects.standards
            });
          }
          if (objects.frames) {
            addFramesToGraph({
              graph: newGraph,
              frames: objects.frames
            });
          }
          if (objects.guardRails) {
            addGuardRailsToGraph({
              graph: newGraph,
              guardRails: objects.guardRails
            });
          }
          if (objects.ledgers) {
            addLedgersToGraph({
              graph: newGraph,
              ledgers: objects.ledgers
            });
          }
          if (objects.basePlates) {
            addBasePlatesToGraph({
              graph: newGraph,
              basePlates: objects.basePlates
            });
          }
          if (objects.planks) {
            addPlanksToGraph({
              graph: newGraph,
              planks: objects.planks
            });
          }
          if (objects.consoles) {
            addConsolesToGraph({
              graph: newGraph,
              consoles: objects.consoles
            });
          }
          if (objects.stairways) {
            addStairwaysToGraph({
              graph: newGraph,
              stairways: objects.stairways
            });
          }
          if (objects.stairwayGuardRails) {
            addStairwayGuardRailsToGraph({
              graph: newGraph,
              stairwayGuardRails: objects.stairwayGuardRails
            });
          }
          if (objects.stairwayInnerGuardRails) {
            addStairwayInnerGuardRailsToGraph({
              graph: newGraph,
              stairwayInnerGuardRails: objects.stairwayInnerGuardRails
            });
          }
          if (objects.toeBoards) {
            addToeBoardsToGraph({
              graph: newGraph,
              toeBoards: objects.toeBoards
            });
          }
          if (objects.diagonalBraces) {
            addDiagonalBracesToGraph({
              graph: newGraph,
              diagonalBraces: objects.diagonalBraces
            });
          }
          if (objects.baseBoards) {
            addBaseBoardsToGraph({
              graph: newGraph,
              baseBoards: objects.baseBoards
            });
          }

          return {
            graph: newGraph,
            ...boxComponents,
            ...(objects.boxes && { boxes: objects.boxes }),
            ...(objects.grounds && { grounds: objects.grounds }),
            ...(objects.polygons && { polygons: objects.polygons }),
            ...(objects.roofs && { roofs: objects.roofs }),
            ...(objects.measurements && { measurements: objects.measurements })
          };
        });
      },
      clearObjects: () => {
        set(() => {
          return {
            graph: new Graph({ type: "undirected" }),
            baseCollars: [],
            baseBoards: [],
            basePlates: [],
            standards: [],
            ledgers: [],
            grounds: [],
            polygons: [],
            beamSpigots: [],
            stairwayGuardRails: [],
            stairwayInnerGuardRails: [],
            diagonalBraces: [],
            roofs: [],
            planks: [],
            toeBoards: [],
            consoles: [],
            stairways: [],
            measurements: [],
            frames: []
          };
        });
      },
      setDimensionsToObjects: (dimensions) => {
        set((state) => {
          return {
            grounds: applyGroundDimensions(dimensions),
            polygons: applyPolygonDimensions(dimensions),
            blueprints: applyBlueprintDimensions(dimensions),
            ifcModels: applyIfcDimensions(dimensions),
            ...applyBoxDimensions(dimensions)
          };
        });
      },
      toggleHidden: (ids, hide) => {
        set((state) => {
          return {
            polygons: state.polygons.map((polygon) => {
              if (ids.includes(polygon.id)) {
                return { ...polygon, hide };
              }
              return polygon;
            }),
            boxes: state.boxes.map((box) => {
              if (ids.includes(box.id)) {
                return { ...box, hide };
              }
              return box;
            }),
            grounds: state.grounds.map((ground) => {
              if (ids.includes(ground.id)) {
                return { ...ground, hide };
              }
              return ground;
            }),
            measurements: state.measurements.map((measurement) => {
              if (ids.includes(measurement.id)) {
                return { ...measurement, hide };
              }
              return measurement;
            }),
            blueprints: state.blueprints.map((blueprint) => {
              if (ids.includes(blueprint.id)) {
                return { ...blueprint, hide };
              }
              return blueprint;
            }),
            ifcModels: state.ifcModels.map((ifcModel) => {
              if (ids.includes(ifcModel.id)) {
                return { ...ifcModel, hide };
              }
              return ifcModel;
            }),
            anchors: state.anchors.map((anchor) => {
              if (ids.includes(anchor.id)) {
                return { ...anchor, hide };
              }
              return anchor;
            }),
            baseBoards: state.baseBoards.map((baseBoard) => {
              if (ids.includes(baseBoard.id)) {
                return { ...baseBoard, hide };
              }
              return baseBoard;
            }),
            baseCollars: state.baseCollars.map((baseCollar) => {
              if (ids.includes(baseCollar.id)) {
                return { ...baseCollar, hide };
              }
              return baseCollar;
            }),
            basePlates: state.basePlates.map((basePlate) => {
              if (ids.includes(basePlate.id)) {
                return { ...basePlate, hide };
              }
              return basePlate;
            }),
            beamSpigots: state.beamSpigots.map((beamSpigot) => {
              if (ids.includes(beamSpigot.id)) {
                return { ...beamSpigot, hide };
              }
              return beamSpigot;
            }),
            consoles: state.consoles.map((console) => {
              if (ids.includes(console.id)) {
                return { ...console, hide };
              }
              return console;
            }),
            diagonalBraces: state.diagonalBraces.map((diagonalBrace) => {
              if (ids.includes(diagonalBrace.id)) {
                return { ...diagonalBrace, hide };
              }
              return diagonalBrace;
            }),
            ledgers: state.ledgers.map((ledger) => {
              if (ids.includes(ledger.id)) {
                return { ...ledger, hide };
              }
              return ledger;
            }),
            planks: state.planks.map((plank) => {
              if (ids.includes(plank.id)) {
                return { ...plank, hide };
              }
              return plank;
            }),
            standards: state.standards.map((standard) => {
              if (ids.includes(standard.id)) {
                return { ...standard, hide };
              }
              return standard;
            }),
            frames: state.frames.map((frame) => {
              if (ids.includes(frame.id)) {
                return { ...frame, hide };
              }
              return frame;
            }),
            stairways: state.stairways.map((stairway) => {
              if (ids.includes(stairway.id)) {
                return { ...stairway, hide };
              }
              return stairway;
            }),
            stairwayGuardRails: state.stairwayGuardRails.map(
              (stairwayGuardRail) => {
                if (ids.includes(stairwayGuardRail.id)) {
                  return { ...stairwayGuardRail, hide };
                }
                return stairwayGuardRail;
              }
            ),
            stairwayInnerGuardRails: state.stairwayInnerGuardRails.map(
              (stairwayInnerGuardRail) => {
                if (ids.includes(stairwayInnerGuardRail.id)) {
                  return { ...stairwayInnerGuardRail, hide };
                }
                return stairwayInnerGuardRail;
              }
            ),
            toeBoards: state.toeBoards.map((toeBoard) => {
              if (ids.includes(toeBoard.id)) {
                return { ...toeBoard, hide };
              }
              return toeBoard;
            }),
            guardRails: state.guardRails.map((guardRail) => {
              if (ids.includes(guardRail.id)) {
                return { ...guardRail, hide };
              }
              return guardRail;
            })
          };
        });
      },
      toggleMeasurementsHidden: (worldMeasurementsHidden) => {
        set(() => ({ worldMeasurementsHidden }));
      },
      copyPasteObjects: (objects, targetPoint) => {
        const allPositions = objects.map((x) => x.position.clone());
        const boundingBox = new Box3().setFromPoints(allPositions);
        const minMaxY = boundingBox.getSize(new Vector3()).y / 2;

        const yDelta = new Vector3(0, minMaxY, 0);

        const boxCenter = boundingBox.getCenter(new Vector3());

        const copyPolygons = objects.filter(
          (x) => x.userData.type === ComponentType.POLYGON
        );
        const copyGrounds = objects.filter((x) => x.userData.type === "ground");
        const copyBayBoxes = objects.filter(
          (x) => x.userData.type === ComponentType.BOX
        );

        const newPolygons: Polygon[] = [];
        const newRoofs: Roof[] = [];
        const newGrounds: Ground[] = [];
        const newBayBoxes: Box[] = [];

        copyPolygons.forEach((object) => {
          const boxDelta = object.position.clone().sub(boxCenter);
          const newPosition = targetPoint.clone().add(boxDelta).add(yDelta);

          const existingPolygon = get().polygons.find(
            (p) => p.id === object.userData.id
          );
          const existingRoof = get().roofs.find(
            (r) => r.polygonId === object.userData.id
          );

          if (existingPolygon) {
            const newPolygonId = nanoid();
            newPolygons.push({
              ...existingPolygon,
              id: newPolygonId,
              position: newPosition.toArray()
            });

            if (existingRoof) {
              newRoofs.push({
                ...existingRoof,
                polygonId: newPolygonId
              });
            }
          }
        });

        copyGrounds.forEach((object) => {
          const boxDelta = object.position.clone().sub(boxCenter);
          const newPosition = targetPoint.clone().add(boxDelta).add(yDelta);

          const existingGround = get().grounds.find(
            (g) => g.id === object.userData.id
          );

          if (existingGround) {
            const newGroundId = nanoid();
            newGrounds.push({
              ...existingGround,
              id: newGroundId,
              position: newPosition.toArray()
            });
          }
        });

        copyBayBoxes.forEach((object) => {
          const boxDelta = object.position.clone().sub(boxCenter);
          const newPosition = targetPoint.clone().add(boxDelta).add(yDelta);

          const existingBox = get().boxes.find(
            (b) => b.id === object.userData.id
          );

          if (existingBox) {
            const newPosDelta = newPosition
              .clone()
              .sub(new Vector3(...existingBox.position));

            newBayBoxes.push({
              ...existingBox,
              id: genId(),
              position: newPosition.toArray(),
              ...(existingBox.options && {
                options: {
                  ...existingBox.options,
                  ...(existingBox.options.standardPositions && {
                    standardPositions:
                      existingBox.options.standardPositions.map((sp) => ({
                        position: new Vector3(...sp.position)
                          .add(newPosDelta)
                          .toArray()
                      })) as [
                        StandardPosition,
                        StandardPosition,
                        StandardPosition,
                        StandardPosition
                      ]
                  })
                }
              })
            });
          }
        });
        const newGraph = get().graph.copy();
        const { addedComponents, deletedComponentIds } = addBoxesToGraph({
          graph: newGraph,
          boxes: newBayBoxes
        });
        const finalBoxComponents = deleteAndAddComponents({
          deleteIds: deletedComponentIds,
          addComponents: addedComponents,
          oldComponents: getBoxComponentsFromState()
        });

        set((state) => ({
          polygons: [...state.polygons, ...newPolygons],
          roofs: [...state.roofs, ...newRoofs],
          grounds: [...state.grounds, ...newGrounds],
          boxes: [...state.boxes, ...newBayBoxes],
          graph: newGraph,
          ...finalBoxComponents
        }));
      }
    }
  };
};

export default createWorldSlice;
