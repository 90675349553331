import { sliceResetFns } from "store/store";
import { StateCreator } from "zustand";
import { ToolsBoxSlice } from "./box.types";
import { SCAFFOLD_PLANKTYPE, SCAFFOLD_SUPPLIER } from "shared/enums/scaffold";
import { degToRad } from "three/src/math/MathUtils";

/** Initial state */
const initialToolsBoxState = {
  toolsBoxHeight: 10,
  toolsBoxWidth: 0.73,
  toolsBoxDepth: 2.57,
  toolsBoxConsoleWidth: 0.39,
  toolsBoxFallProtectionHeight: 1,
  toolsBoxFallProtectionNet: false,
  toolsBoxTopVariant: null,
  toolsBoxGuardRails: [true, false, true, true] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsBoxConsoles: [false, false, false, false] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsBoxDiagonals: [false, false, false, false] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsBoxAnchors: [false, false, false, false] as [
    boolean,
    boolean,
    boolean,
    boolean
  ],
  toolsBoxDiagonalPattern: null,
  toolsBoxFrames: [10, 8, 6, 4, 2],
  toolsBoxPlatforms: [10, 8, 6, 4, 2],
  toolsBoxPlankMaterial: SCAFFOLD_PLANKTYPE.STEEL,
  toolsBoxDistanceToWall: 0.3,
  toolsBoxDistanceToWallEnabled: true,
  toolsBoxSupplier: SCAFFOLD_SUPPLIER.LAYHER,
  toolsBoxRotationY: null,
  toolsBoxMaxBoxes: 50,
  toolsBoxRotationStep: degToRad(15),
  toolsBoxCornerMode: null
};

const createToolsBoxSlice: StateCreator<
  ToolsBoxSlice,
  [],
  [],
  ToolsBoxSlice
> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialToolsBoxState));

  /** Return state */
  return {
    ...initialToolsBoxState,
    toolsBoxActions: {
      setToolsBoxHeight: (height) => {
        set({ toolsBoxHeight: height });
      },
      setToolsBoxDepth: (depth) => {
        set({ toolsBoxDepth: depth });
      },
      setToolsBoxWidth: (width) => {
        set({ toolsBoxWidth: width });
      },
      setToolsBoxGuardRails: (options) => {
        set({ toolsBoxGuardRails: options });
      },
      setToolsBoxFrames: (frames) => {
        set({ toolsBoxFrames: frames });
      },
      setToolsBoxPlatforms: (platforms) => {
        set({ toolsBoxPlatforms: platforms });
      },
      setToolsBoxPlankMaterial: (material) => {
        set({ toolsBoxPlankMaterial: material });
      },
      setToolsBoxConsoles: (options) => {
        set({ toolsBoxConsoles: options });
      },
      setToolsBoxConsoleWidth: (width) => {
        set({ toolsBoxConsoleWidth: width });
      },
      setToolsBoxFallProtectionHeight: (height) => {
        set({ toolsBoxFallProtectionHeight: height });
      },
      setToolsBoxFallProtectionNet: (net) => {
        set({ toolsBoxFallProtectionNet: net });
      },
      setToolsBoxTopVariant: (topVariant) => {
        set({ toolsBoxTopVariant: topVariant });
      },
      setToolsBoxRotationY: (rotation) => {
        set({ toolsBoxRotationY: rotation });
      },
      setToolsBoxDistanceToWall: (distance) => {
        set({ toolsBoxDistanceToWall: distance });
      },
      setToolsBoxSupplier: (supplier) => {
        set({ toolsBoxSupplier: supplier });
      },
      setToolsBoxDistanceToWallEnabled: (distanceToWallEnabled) => {
        set({ toolsBoxDistanceToWallEnabled: distanceToWallEnabled });
      },
      setToolsBoxDiagonalPattern: (pattern) => {
        set({ toolsBoxDiagonalPattern: pattern });
      },
      setToolsBoxDiagonals: (options) => {
        set({ toolsBoxDiagonals: options });
      },
      setToolsBoxAnchors: (options) => {
        set({ toolsBoxAnchors: options });
      },
      setToolsBoxMaxBoxes: (maxBoxes) => {
        set({ toolsBoxMaxBoxes: maxBoxes });
      },
      setToolsBoxRotationStep: (rotationStep) => {
        set({ toolsBoxRotationStep: rotationStep });
      },
      setToolsBoxCornerMode: (cornerMode) => {
        set({ toolsBoxCornerMode: cornerMode });
      }
    }
  };
};

export default createToolsBoxSlice;
