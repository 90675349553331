import Graph from "graphology";
import { bfsFromNode } from "graphology-traversal/bfs";
import { half, round } from "math";
import { genId } from "math/generators";
import { BOX_TYPE } from "shared/enums/world";
import { Box, BoxComponentIds } from "shared/interfaces/firestore";
import { getComponentsFromIds } from "store/selectors/world";
import useStoreWithUndo from "store/store";
import { getScaffoldClass } from "suppliers/scaffold";
import { ReplacedComponent } from "suppliers/scaffold/scaffold.interface";
import { Vector3, Vector3Tuple } from "three";
import { Anchor } from "world/core/Anchor/Anchor.types";
import { getGraphData as getAnchorGraphData } from "world/core/Anchor/Anchor.utils";
import { BaseBoard } from "world/core/BaseBoard/BaseBoard.types";
import { getGraphData as getBaseBoardGraphData } from "world/core/BaseBoard/BaseBoard.utils";
import { BaseCollar } from "world/core/BaseCollar/BaseCollar.types";
import { getGraphData as getBaseCollarGraphData } from "world/core/BaseCollar/BaseCollar.utils";
import { BasePlate } from "world/core/BasePlate/BasePlate.types";
import { getGraphData as getBasePlateGraphData } from "world/core/BasePlate/BasePlate.utils";
import { BeamSpigot } from "world/core/BeamSpigot/BeamSpigot.types";
import { getGraphData as getBeamSpigotGraphData } from "world/core/BeamSpigot/BeamSpigot.utils";
import { Console } from "world/core/Console/Console.types";
import {
  getGraphData as getConsoleGraphData,
  getGraphSplitData as getConsoleSplitGraphData
} from "world/core/Console/Console.utils";
import { DiagonalBrace } from "world/core/DiagonalBrace/DiagonalBrace.types";
import { getGraphData as getDiagonalBraceGraphData } from "world/core/DiagonalBrace/DiagonalBrace.utils";
import { Frame } from "world/core/Frame/Frame.types";
import { getGraphData as getFrameGraphData } from "world/core/Frame/Frame.utils";
import { GuardRail } from "world/core/GuardRail/GuardRail.types";
import { getGraphData as getGuardRailGraphData } from "world/core/GuardRail/GuardRail.utils";
import { Ledger } from "world/core/Ledger/Ledger.types";
import {
  getGraphData as getLedgerGraphData,
  getGraphSplitData as getLedgerSplitGraphData
} from "world/core/Ledger/Ledger.utils";
import { Plank } from "world/core/Plank/Plank.types";
import { getGraphData as getPlankGraphData } from "world/core/Plank/Plank.utils";
import { Stairway } from "world/core/Stairway/Stairway.types";
import { getGraphData as getStairwayGraphData } from "world/core/Stairway/Stairway.utils";
import { StairwayGuardRail } from "world/core/StairwayGuardRail/StairwayGuardRail.types";
import { getGraphData as getStairwayGuardRailGraphData } from "world/core/StairwayGuardRail/StairwayGuardRail.utils";
import { StairwayInnerGuardRail } from "world/core/StairwayInnerGuardRail/StairwayInnerGuardRail.types";
import { getGraphData as getStairwayInnerGuardRailGraphData } from "world/core/StairwayInnerGuardRail/StairwayInnerGuardRail.utils";
import {
  ComponentType,
  GraphData,
  Standard
} from "world/core/Standard/Standard.types";
import {
  getGraphData as getStandardGraphData,
  getGraphSplitData as getStandardSplitGraphData
} from "world/core/Standard/Standard.utils";
import { ToeBoard } from "world/core/ToeBoard/ToeBoard.types";
import { getGraphData as getToeBoardGraphData } from "world/core/ToeBoard/ToeBoard.utils";
import {
  addComponentsToGraph,
  getBoxIdsConnectedToNode,
  isNodeComponentConnectedToBox,
  removeComponentsFromGraph,
  toVectorStringRepresentation
} from "../world.utils";
import { BoxComponents } from "./box.interface";

export const addBoxesToGraph = (props: {
  graph: Graph;
  boxes: Box[];
}): { addedComponents: BoxComponents; deletedComponentIds: string[] } => {
  const { graph, boxes } = props;
  const standards: Standard[] = [];
  const ledgers: Ledger[] = [];
  const basePlates: BasePlate[] = [];
  const baseCollars: BaseCollar[] = [];
  const baseBoards: BaseBoard[] = [];
  const planks: Plank[] = [];
  const toeBoards: ToeBoard[] = [];
  const consoles: Console[] = [];
  const stairways: Stairway[] = [];
  const beamSpigots: BeamSpigot[] = [];
  const stairwayInnerGuardRails: StairwayInnerGuardRail[] = [];
  const stairwayGuardRails: StairwayGuardRail[] = [];
  const diagonalBraces: DiagonalBrace[] = [];
  const anchors: Anchor[] = [];
  const frames: Frame[] = [];
  const guardRails: GuardRail[] = [];
  const deletedComponentIdsSet = new Set<string>();

  const stateBoxes = useStoreWithUndo.getState().boxes;

  const allBoxes = stateBoxes.map((box) => {
    const updatedBox = boxes.find((b) => b.id === box.id);
    return updatedBox ? updatedBox : box;
  });
  boxes.forEach((box) => {
    if (graph.hasNode(box.id)) return;
    /** Take standard positions into consideration when placing the box.
     * Might need revision if init box handles this instead */
    const boxCenter = new Vector3(...box.position).add(
      new Vector3(0, half(box.height), 0)
    );
    const { components, replacedComponents } = generateBoxComponents({
      box,
      allBoxes,
      standardsToCheck: standards
    });
    const removeComponents = getComponentsFromIds(
      replacedComponents.map((component) => component.id),
      standards
    );
    const preDeleteComponentsGraphSnapShot = graph.copy();

    removeComponentsFromGraph({
      graph,
      components: Object.values(
        getComponentsGraphData(removeComponents)
      ).flat(),
      force: true
    }).forEach((id) => deletedComponentIdsSet.add(id));

    const componentsGraphData = getComponentsGraphData(components);
    const allComponentGraphData = Object.values(componentsGraphData).flat();

    const { addedComponentIds, existingComponents } = addComponentsToGraph({
      graph,
      components: Object.values({
        beamSpigots: componentsGraphData.beamSpigots,
        standards: componentsGraphData.standards,
        ledgers: componentsGraphData.ledgers,
        baseCollars: componentsGraphData.baseCollars,
        basePlates: componentsGraphData.basePlates,
        consoles: componentsGraphData.consoles,
        planks: componentsGraphData.planks,
        diagonaBraces: componentsGraphData.diagonalBraces,
        anchors: componentsGraphData.anchors,
        toeBoards: componentsGraphData.toeBoards,
        baseBoards: componentsGraphData.baseBoards,
        stairways: componentsGraphData.stairways,
        stairwayGuardRails: componentsGraphData.stairwayGuardRails,
        stairwayInnerGuardRails: componentsGraphData.stairwayInnerGuardRails,
        frames: componentsGraphData.frames,
        guardRails: componentsGraphData.guardRails
      }).flat()
    });

    const addedAndExistingComponents = allComponentGraphData.filter(
      (component) => addedComponentIds.includes(component.id)
    );
    addedAndExistingComponents.push(...existingComponents);

    graph.addNode(box.id, {
      type: "box",
      data: box,
      position: boxCenter.toArray()
    });
    updateComponentToBoxConnections({
      newComponents: components,
      replacedComponents,
      graph,
      preDeleteComponentsGraphSnapShot,
      nonStateStandardsToIncludeInCheck: standards
    });

    addBoxToComponentsNodeEdges({
      graph,
      boxIds: [box.id],
      components: addedAndExistingComponents
    });

    standards.push(
      ...components.standards.filter(
        (standard) =>
          addedComponentIds.includes(standard.id) &&
          !standards.map((s) => s.id).includes(standard.id)
      )
    );
    planks.push(
      ...components.planks.filter((plank) =>
        addedComponentIds.includes(plank.id)
      )
    );
    beamSpigots.push(
      ...components.beamSpigots.filter((beamSpigot) =>
        addedComponentIds.includes(beamSpigot.id)
      )
    );
    diagonalBraces.push(
      ...components.diagonalBraces.filter((diagonalBrace) =>
        addedComponentIds.includes(diagonalBrace.id)
      )
    );

    ledgers.push(
      ...components.ledgers.filter(
        (ledger) =>
          addedComponentIds.includes(ledger.id) &&
          !ledgers.map((l) => l.id).includes(ledger.id)
      )
    );

    basePlates.push(
      ...components.basePlates.filter(
        (basePlate) =>
          addedComponentIds.includes(basePlate.id) &&
          !basePlates.map((l) => l.id).includes(basePlate.id)
      )
    );
    baseCollars.push(
      ...components.baseCollars.filter(
        (baseCollar) =>
          addedComponentIds.includes(baseCollar.id) &&
          !baseCollars.map((i) => i.id).includes(baseCollar.id)
      )
    );
    baseBoards.push(
      ...components.baseBoards.filter(
        (baseBoard) =>
          addedComponentIds.includes(baseBoard.id) &&
          !baseBoards.map((i) => i.id).includes(baseBoard.id)
      )
    );
    toeBoards.push(
      ...components.toeBoards.filter(
        (toeBoard) =>
          addedComponentIds.includes(toeBoard.id) &&
          !toeBoards.map((i) => i.id).includes(toeBoard.id)
      )
    );
    guardRails.push(
      ...components.guardRails.filter(
        (guardRail) =>
          addedComponentIds.includes(guardRail.id) &&
          !guardRails.map((i) => i.id).includes(guardRail.id)
      )
    );
    consoles.push(
      ...components.consoles.filter(
        (console) =>
          addedComponentIds.includes(console.id) &&
          !consoles.map((i) => i.id).includes(console.id)
      )
    );
    stairways.push(
      ...components.stairways.filter(
        (stairway) =>
          addedComponentIds.includes(stairway.id) &&
          !stairways.map((i) => i.id).includes(stairway.id)
      )
    );
    stairwayInnerGuardRails.push(
      ...components.stairwayInnerGuardRails.filter(
        (stairwayInnerGuardRail) =>
          addedComponentIds.includes(stairwayInnerGuardRail.id) &&
          !stairwayInnerGuardRails
            .map((i) => i.id)
            .includes(stairwayInnerGuardRail.id)
      )
    );
    stairwayGuardRails.push(
      ...components.stairwayGuardRails.filter(
        (stairwayGuardRail) =>
          addedComponentIds.includes(stairwayGuardRail.id) &&
          !stairwayGuardRails.map((i) => i.id).includes(stairwayGuardRail.id)
      )
    );
    anchors.push(
      ...components.anchors.filter(
        (anchor) =>
          addedComponentIds.includes(anchor.id) &&
          !anchors.map((i) => i.id).includes(anchor.id)
      )
    );
    frames.push(
      ...components.frames.filter(
        (frame) =>
          addedComponentIds.includes(frame.id) &&
          !frames.map((i) => i.id).includes(frame.id)
      )
    );
  });

  const addedComponents = {
    beamSpigots,
    standards,
    ledgers,
    basePlates,
    baseCollars,
    baseBoards,
    planks,
    toeBoards,
    consoles,
    stairways,
    stairwayInnerGuardRails,
    stairwayGuardRails,
    diagonalBraces,
    anchors,
    frames,
    guardRails
  };

  return {
    addedComponents,
    deletedComponentIds: Array.from(deletedComponentIdsSet)
  };
};

export const getBoxComponentsFromState = (): BoxComponents => {
  const state = useStoreWithUndo.getState();
  return {
    standards: state.standards,
    ledgers: state.ledgers,
    baseCollars: state.baseCollars,
    baseBoards: state.baseBoards,
    basePlates: state.basePlates,
    planks: state.planks,
    toeBoards: state.toeBoards,
    consoles: state.consoles,
    stairways: state.stairways,
    stairwayInnerGuardRails: state.stairwayInnerGuardRails,
    stairwayGuardRails: state.stairwayGuardRails,
    diagonalBraces: state.diagonalBraces,
    anchors: state.anchors,
    beamSpigots: state.beamSpigots,
    frames: state.frames,
    guardRails: state.guardRails
  };
};

/** Updates component to box connections. Mutates the graph.
 * @param newComponents - BoxComponents
 * @param replacedComponents - ReplacedComponent[]
 * @param graph - Graph
 * @param preDeleteComponentsGraphSnapShot - Snapshot of graph before delete was done
 */
const updateComponentToBoxConnections = (props: {
  newComponents: BoxComponents;
  replacedComponents: ReplacedComponent[];
  preDeleteComponentsGraphSnapShot: Graph;
  graph: Graph;
  nonStateStandardsToIncludeInCheck?: Standard[];
}) => {
  const {
    newComponents,
    replacedComponents,
    graph,
    preDeleteComponentsGraphSnapShot,
    nonStateStandardsToIncludeInCheck
  } = props;

  const removeComponents = getComponentsFromIds(
    replacedComponents.map((component) => component.id),
    nonStateStandardsToIncludeInCheck
  );

  removeComponents.standards.forEach((s) => {
    const boxIds = getBoxIdsConnectedToNode(
      preDeleteComponentsGraphSnapShot,
      toVectorStringRepresentation(s.position)
    );
    const sameXZStandards = getSameXZPosStandards({
      x: s.position[0],
      z: s.position[2],
      standards: newComponents.standards
    });
    addBoxToComponentsNodeEdges({
      graph,
      boxIds,
      components: sameXZStandards
        .map((sxz) =>
          sxz.splits
            ? getStandardSplitGraphData(sxz)
            : getStandardGraphData(sxz)
        )
        .flat()
    });
  });

  removeComponents.ledgers.forEach((l) => {
    const boxIdsConnectedToOldLedger = getBoxIdsConnectedToNode(
      preDeleteComponentsGraphSnapShot,
      l.position.toString()
    );

    const replacedComponentData = replacedComponents.find(
      (rc) => rc.id === l.id
    );
    const replacedByLedger =
      replacedComponentData &&
      newComponents.ledgers.find(
        (ledger) => ledger.id === replacedComponentData.replacedById
      );

    if (replacedByLedger) {
      addBoxToComponentsNodeEdges({
        graph,
        boxIds: boxIdsConnectedToOldLedger,
        components: [
          replacedByLedger.splits
            ? getLedgerSplitGraphData(replacedByLedger)
            : getLedgerGraphData(replacedByLedger)
        ].flat()
      });
    }
  });
};

export const addBoxToComponentConnections = (
  boxComponents: BoxComponentIds,
  graph: Graph
) => {
  const components = getComponentsFromIds(boxComponents.componentIds);

  const box = useStoreWithUndo
    .getState()
    .boxes.find((box) => box.id === boxComponents.boxId);
  if (!box) return;

  graph.addNode(boxComponents.boxId, {
    type: "box",
    data: box,
    position: box.position
  });

  const componentsGraphData = Object.values(
    getComponentsGraphData(components)
  ).flat();

  addBoxToComponentsNodeEdges({
    graph,
    boxIds: [boxComponents.boxId],
    components: componentsGraphData
  });
};

export const getBoxesComponentsIds = () => {
  const { boxes, graph } = useStoreWithUndo.getState();
  const boxComponents: BoxComponentIds[] = [];

  boxes.forEach((box) => {
    const componentIds = getComponentsConnectedToBoxes({
      graph,
      boxIds: [box.id]
    });

    boxComponents.push({ boxId: box.id, componentIds });
  });

  return boxComponents;
};

export const getSameXZPosStandardEdges = (props: { x: number; z: number }) => {
  const { x, z } = props;
  const graphCopy = useStoreWithUndo.getState().graph.copy();
  const filteredStandards = graphCopy.filterEdges(
    (edge, attributes, source, target) => {
      if (
        attributes.type === "component" &&
        attributes.component &&
        attributes.component.type === ComponentType.STANDARD &&
        attributes.component.startPosition[0] === x &&
        attributes.component.startPosition[2] === z
      ) {
        return true;
      }
    }
  );
  return filteredStandards;
};

export const getSameXZPosStandards = (props: {
  x: number;
  z: number;
  standards?: Standard[];
}) => {
  const { x, z } = props;
  const roundedX = round(x, 2);
  const roundedZ = round(z, 2);

  const standards = props.standards ?? useStoreWithUndo.getState().standards;
  const filteredStandards = standards.filter(
    (standard) =>
      round(standard.position[0], 2) === roundedX &&
      round(standard.position[2], 2) === roundedZ
  );
  return filteredStandards;
};

export const getSameStartPosAndRotLedgers = (props: {
  position: Vector3Tuple;
  rotation: Vector3Tuple;
  ledger?: Ledger[];
}) => {
  const { position, rotation } = props;
  const ledgers = props.ledger ?? useStoreWithUndo.getState().ledgers;
  const filteredLedgers = ledgers.filter(
    (ledger) =>
      round(ledger.position[0], 2) === round(position[0], 2) &&
      round(ledger.position[1], 2) === round(position[1], 2) &&
      round(ledger.position[2], 2) === round(position[2], 2) &&
      round(ledger.rotation[0], 2) === round(rotation[0], 2) &&
      round(ledger.rotation[1], 2) === round(rotation[1], 2) &&
      round(ledger.rotation[2], 2) === round(rotation[2], 2)
  );
  return filteredLedgers;
};

export const removeBoxComponentEdges = (props: {
  graph: Graph;
  boxIds: string[];
}) => {
  const { graph, boxIds } = props;
  const componentsToRemove: string[] = [];
  boxIds.forEach((id) => {
    if (!graph.hasNode(id)) return;

    graph.forEachEdge(id, (edge, attributes) => {
      const edgeData = attributes as {
        type: string;
        component: string[];
      };

      if (edgeData.type === "box") {
        componentsToRemove.push(...edgeData.component);
      }
      graph.dropEdge(edge);
    });

    // Drop node after all edges have been removed
    graph.dropNode(id);
  });

  return componentsToRemove;
};

export const getComponentsConnectedToBoxes = (props: {
  graph: Graph;
  boxIds: string[];
}) => {
  const { graph, boxIds } = props;
  const uniqueComponents = new Set<string>();

  boxIds.forEach((id) => {
    if (!graph.hasNode(id)) return;
    graph.forEachEdge(id, (edge, attributes) => {
      const edgeData = attributes as {
        type: string;
        component: string[];
      };

      if (edgeData.type === "box") {
        edgeData.component.forEach((component) =>
          uniqueComponents.add(component)
        );
      }
    });
  });

  return Array.from(uniqueComponents.values());
};

export const getComponentsOnlyConnectedToBoxes = (props: {
  graph: Graph;
  boxIds: string[];
  currentBoxComponents: BoxComponents;
}) => {
  const { graph, boxIds, currentBoxComponents } = props;
  const uniqueComponents = new Set<string>();

  boxIds.forEach((id) => {
    if (!graph.hasNode(id)) return;
    graph.forEachEdge(id, (edge, attributes) => {
      const edgeData = attributes as {
        type: string;
        component: string[];
      };

      if (edgeData.type === "box") {
        edgeData.component.forEach((component) =>
          uniqueComponents.add(component)
        );
      }
    });
  });

  const uniqueComponentsIds = Array.from(uniqueComponents.values());

  const uniqueComponentsGraphData = getComponentsGraphData({
    standards: currentBoxComponents.standards.filter((standard) =>
      uniqueComponentsIds.includes(standard.id)
    ),
    ledgers: currentBoxComponents.ledgers.filter((ledger) =>
      uniqueComponentsIds.includes(ledger.id)
    ),
    basePlates: currentBoxComponents.basePlates.filter((basePlate) =>
      uniqueComponentsIds.includes(basePlate.id)
    ),
    baseCollars: currentBoxComponents.baseCollars.filter((baseCollar) =>
      uniqueComponentsIds.includes(baseCollar.id)
    ),
    baseBoards: currentBoxComponents.baseBoards.filter((baseBoard) =>
      uniqueComponentsIds.includes(baseBoard.id)
    ),
    planks: currentBoxComponents.planks.filter((plank) =>
      uniqueComponentsIds.includes(plank.id)
    ),
    toeBoards: currentBoxComponents.toeBoards.filter((toeBoard) =>
      uniqueComponentsIds.includes(toeBoard.id)
    ),
    consoles: currentBoxComponents.consoles.filter((console) =>
      uniqueComponentsIds.includes(console.id)
    ),
    stairways: currentBoxComponents.stairways.filter((stairway) =>
      uniqueComponentsIds.includes(stairway.id)
    ),
    beamSpigots: currentBoxComponents.beamSpigots.filter((beamSpigot) =>
      uniqueComponentsIds.includes(beamSpigot.id)
    ),
    stairwayInnerGuardRails:
      currentBoxComponents.stairwayInnerGuardRails.filter(
        (stairwayInnerGuardRail) =>
          uniqueComponentsIds.includes(stairwayInnerGuardRail.id)
      ),
    stairwayGuardRails: currentBoxComponents.stairwayGuardRails.filter(
      (stairwayGuardRail) => uniqueComponentsIds.includes(stairwayGuardRail.id)
    ),
    diagonalBraces: currentBoxComponents.diagonalBraces.filter(
      (diagonalBrace) => uniqueComponentsIds.includes(diagonalBrace.id)
    ),
    anchors: currentBoxComponents.anchors.filter((anchor) =>
      uniqueComponentsIds.includes(anchor.id)
    ),
    frames: currentBoxComponents.frames.filter((frame) =>
      uniqueComponentsIds.includes(frame.id)
    ),
    guardRails: currentBoxComponents.guardRails.filter((guardRail) =>
      uniqueComponentsIds.includes(guardRail.id)
    )
  });

  Object.values(uniqueComponentsGraphData)
    .flat()
    .forEach((component) => {
      if (
        isNodeComponentConnectedToBox({
          graph,
          node: component.endPosition.toString(),
          excludeBoxIds: boxIds,
          componentId: component.id
        }) ||
        isNodeComponentConnectedToBox({
          graph,
          node: component.startPosition.toString(),
          excludeBoxIds: boxIds,
          componentId: component.id
        })
      ) {
        uniqueComponents.delete(component.id);
      }
    });

  return Array.from(uniqueComponents.values());
};

const addBoxToComponentsNodeEdges = (props: {
  graph: Graph;
  boxIds: string[];
  components: GraphData[];
}) => {
  const { graph, boxIds, components } = props;
  boxIds.forEach((boxId) =>
    components.forEach((component) => {
      const startPosString = toVectorStringRepresentation(
        component.startPosition
      );
      if (!graph.hasEdge(boxId, startPosString)) {
        graph.addEdge(boxId, startPosString, {
          type: "box",
          component: [component.id]
        });
      } else {
        const edge = graph.getEdgeAttributes(boxId, startPosString);
        if (edge) {
          const componentIds = edge.component as string[];
          if (!componentIds.includes(component.id)) {
            componentIds.push(component.id);
          }
        }
      }

      // if (!graph.hasEdge(box.id, component.endPosition)) {
      //   graph.addEdge(box.id, component.endPosition, {
      //     type: "box",
      //     componentId: component.id,
      //   });
      // }
    })
  );
};

export const getConnectedBoxesFromBox = (boxId: string) => {
  const { graph } = useStoreWithUndo.getState();
  const connectedBoxIds: string[] = [boxId];
  bfsFromNode(graph, boxId, (node, attr, depth) =>
    traverseNode(node, attr, depth, connectedBoxIds)
  );
};

const traverseNode = (
  node: string,
  attr: any,
  depth: number,
  connectedBoxIds: string[]
) => {
  if (attr.type !== "box") return;
  const { data } = attr as { type: string; data: Box };
  if (connectedBoxIds.includes(data.id)) return;
  connectedBoxIds.push(data.id);
};

export const getScaffoldGroups = (props?: { graph: Graph; boxes: Box[] }) => {
  const graph = props?.graph ?? useStoreWithUndo.getState().graph;
  const boxes = props?.boxes ?? useStoreWithUndo.getState().boxes;
  const processedBoxes = new Set<string>();
  const scaffoldGroups: { id: string; boxIds: string[] }[] = [];
  boxes.forEach((box) => {
    if (processedBoxes.has(box.id)) return;
    const connectedBoxes = [box.id];
    if (!graph.hasNode(box.id)) return;
    bfsFromNode(graph, box.id, (node, attr, depth) =>
      traverseNode(node, attr, depth, connectedBoxes)
    );
    scaffoldGroups.push({ id: genId(), boxIds: [...connectedBoxes] });
    connectedBoxes.forEach((id) => processedBoxes.add(id));
  });
  return scaffoldGroups;
};

export const getComponentsGraphData = (components: BoxComponents) => {
  const {
    standards,
    ledgers,
    basePlates,
    baseBoards,
    baseCollars,
    planks,
    toeBoards,
    consoles,
    stairways,
    stairwayInnerGuardRails,
    stairwayGuardRails,
    diagonalBraces,
    anchors,
    beamSpigots,
    frames,
    guardRails
  } = components;
  const standardsGraphData = standards.map((standard) => {
    if (standard.splits) return getStandardSplitGraphData(standard);
    return getStandardGraphData(standard);
  });
  const ledgersGraphData = ledgers.map((ledger) => {
    if (ledger.splits) return getLedgerSplitGraphData(ledger);
    return getLedgerGraphData(ledger);
  });
  const basePlatesGraphData = basePlates.map((basePlate) =>
    getBasePlateGraphData(basePlate)
  );
  const baseCollarsGraphData = baseCollars.map((baseCollar) =>
    getBaseCollarGraphData(baseCollar)
  );
  const baseBoardsGraphData = baseBoards.map((baseBoard) =>
    getBaseBoardGraphData(baseBoard)
  );
  const planksGraphData = planks.map((plank) => getPlankGraphData(plank));
  const beamSpigotsGraphData = beamSpigots.map((beamSpigot) =>
    getBeamSpigotGraphData(beamSpigot)
  );
  const toeBoardsGraphData = toeBoards.map((toeBoard) =>
    getToeBoardGraphData(toeBoard)
  );
  const consolesGraphData = consoles.map((console) => {
    if (console.splits) return getConsoleSplitGraphData(console);
    return getConsoleGraphData(console);
  });
  const stairwaysGraphData = stairways.map((stairway) =>
    getStairwayGraphData(stairway)
  );
  const stairwayInnerGuardRailsGraphData = stairwayInnerGuardRails.map(
    (stairwayInnerGuardRail) =>
      getStairwayInnerGuardRailGraphData(stairwayInnerGuardRail)
  );
  const diagonalBracesGraphData = diagonalBraces.map((diagonalBrace) =>
    getDiagonalBraceGraphData(diagonalBrace)
  );
  const anchorsGraphData = anchors.map((anchor) => getAnchorGraphData(anchor));

  const stairwayGuardRailsGraphData = stairwayGuardRails.map(
    (stairwayGuardRail) => getStairwayGuardRailGraphData(stairwayGuardRail)
  );

  const framesGraphData = frames.map((frame) => getFrameGraphData(frame));
  const guardrailsGraphData = guardRails.map((guardRail) =>
    getGuardRailGraphData(guardRail)
  );

  return {
    standards: standardsGraphData.flat(),
    ledgers: ledgersGraphData.flat(),
    consoles: consolesGraphData.flat(),
    basePlates: basePlatesGraphData,
    baseCollars: baseCollarsGraphData,
    baseBoards: baseBoardsGraphData,
    planks: planksGraphData,
    toeBoards: toeBoardsGraphData,
    stairways: stairwaysGraphData,
    stairwayInnerGuardRails: stairwayInnerGuardRailsGraphData,
    stairwayGuardRails: stairwayGuardRailsGraphData,
    diagonalBraces: diagonalBracesGraphData,
    anchors: anchorsGraphData,
    beamSpigots: beamSpigotsGraphData,
    frames: framesGraphData.flat(),
    guardRails: guardrailsGraphData.flat()
  };
};

export const setBoxesInGraph = (props: {
  graph: Graph;
  newBoxes: Box[];
  oldBoxes: Box[];
  currentBoxComponents: BoxComponents;
}) => {
  const { graph, newBoxes, oldBoxes, currentBoxComponents } = props;
  const removeComponentIds = new Set<string>();

  oldBoxes.forEach((box) => {
    const componentIdsToRemove = removeBoxComponentEdges({
      graph,
      boxIds: [box.id]
    });
    const componentsToRemoveGraphData = getComponentsGraphData({
      standards: currentBoxComponents.standards.filter((standard) =>
        componentIdsToRemove.includes(standard.id)
      ),
      beamSpigots: currentBoxComponents.beamSpigots.filter((beamSpigot) =>
        componentIdsToRemove.includes(beamSpigot.id)
      ),
      ledgers: currentBoxComponents.ledgers.filter((ledger) =>
        componentIdsToRemove.includes(ledger.id)
      ),
      basePlates: currentBoxComponents.basePlates.filter((basePlate) =>
        componentIdsToRemove.includes(basePlate.id)
      ),
      baseCollars: currentBoxComponents.baseCollars.filter((baseCollar) =>
        componentIdsToRemove.includes(baseCollar.id)
      ),
      baseBoards: currentBoxComponents.baseBoards.filter((baseBoard) =>
        componentIdsToRemove.includes(baseBoard.id)
      ),
      planks: currentBoxComponents.planks.filter((plank) =>
        componentIdsToRemove.includes(plank.id)
      ),
      toeBoards: currentBoxComponents.toeBoards.filter((toeBoard) =>
        componentIdsToRemove.includes(toeBoard.id)
      ),
      consoles: currentBoxComponents.consoles.filter((console) =>
        componentIdsToRemove.includes(console.id)
      ),
      stairways: currentBoxComponents.stairways.filter((stairway) =>
        componentIdsToRemove.includes(stairway.id)
      ),
      stairwayInnerGuardRails:
        currentBoxComponents.stairwayInnerGuardRails.filter(
          (stairwayInnerGuardRail) =>
            componentIdsToRemove.includes(stairwayInnerGuardRail.id)
        ),
      stairwayGuardRails: currentBoxComponents.stairwayGuardRails.filter(
        (stairwayGuardRail) =>
          componentIdsToRemove.includes(stairwayGuardRail.id)
      ),
      diagonalBraces: currentBoxComponents.diagonalBraces.filter(
        (diagonalBrace) => componentIdsToRemove.includes(diagonalBrace.id)
      ),
      anchors: currentBoxComponents.anchors.filter((anchor) =>
        componentIdsToRemove.includes(anchor.id)
      ),
      frames: currentBoxComponents.frames.filter((frame) =>
        componentIdsToRemove.includes(frame.id)
      ),
      guardRails: currentBoxComponents.guardRails.filter((guardRail) =>
        componentIdsToRemove.includes(guardRail.id)
      )
    });
    const removedComponents = removeComponentsFromGraph({
      graph: graph,
      components: Object.values(componentsToRemoveGraphData).flat()
    });
    removedComponents.forEach((id) => removeComponentIds.add(id));
  });

  const { addedComponents, deletedComponentIds } = addBoxesToGraph({
    graph,
    boxes: newBoxes
  });

  return {
    newComponents: addedComponents,
    deleteComponents: [
      ...Array.from(removeComponentIds),
      ...deletedComponentIds
    ]
  };
};

const generateBoxComponents = (props: {
  box: Box;
  allBoxes?: Box[];
  standardsToCheck?: Standard[];
}): {
  replacedComponents: ReplacedComponent[];
  components: BoxComponents;
} => {
  const { box, allBoxes, standardsToCheck } = props;
  /** Extract box properties */
  const { height, depth, width, rotation, options, position, supplier, id } =
    box;
  const supplierClass = getScaffoldClass(supplier);
  if (supplierClass) {
    if (box.type === BOX_TYPE.STAIR) {
      const components = supplierClass.generateStairBoxComponents({
        boxProps: {
          height,
          depth,
          width,
          rotation,
          options,
          position,
          id
        },
        allBoxes,
        standardsToCheck
      });
      return components;
    } else if (box.type === BOX_TYPE.BAY) {
      const components = supplierClass.generateBayBoxComponents({
        boxProps: {
          height,
          depth,
          width,
          rotation,
          options,
          position,
          id
        },
        allBoxes,
        standardsToCheck
      });
      return components;
    } else if (box.type === BOX_TYPE.PASSAGE) {
      const components = supplierClass.generatePassageBoxComponents(
        {
          height,
          depth,
          width,
          rotation,
          options,
          position,
          id
        },
        allBoxes
      );
      return components;
    }
  }

  return {
    components: {
      beamSpigots: [],
      baseBoards: [],
      baseCollars: [],
      basePlates: [],
      consoles: [],
      ledgers: [],
      planks: [],
      stairwayGuardRails: [],
      stairwayInnerGuardRails: [],
      stairways: [],
      standards: [],
      toeBoards: [],
      diagonalBraces: [],
      anchors: [],
      frames: [],
      guardRails: []
    },
    replacedComponents: []
  };
};

export const deleteAndAddComponents = (props: {
  deleteIds: string[];
  addComponents: BoxComponents;
  oldComponents: BoxComponents;
}): BoxComponents => {
  const { deleteIds, addComponents, oldComponents } = props;
  const filteredComponents = {
    standards: [
      ...oldComponents.standards.filter(
        (standard) => !deleteIds.includes(standard.id)
      ),
      ...addComponents.standards.filter(
        (standard) => !deleteIds.includes(standard.id)
      )
    ],
    beamSpigots: [
      ...oldComponents.beamSpigots.filter(
        (beamSpigot) => !deleteIds.includes(beamSpigot.id)
      ),
      ...addComponents.beamSpigots
    ],
    ledgers: [
      ...oldComponents.ledgers.filter(
        (ledger) => !deleteIds.includes(ledger.id)
      ),
      ...addComponents.ledgers
    ],
    basePlates: [
      ...oldComponents.basePlates.filter(
        (basePlate) => !deleteIds.includes(basePlate.id)
      ),
      ...addComponents.basePlates
    ],
    baseCollars: [
      ...oldComponents.baseCollars.filter(
        (baseCollar) => !deleteIds.includes(baseCollar.id)
      ),
      ...addComponents.baseCollars
    ],
    baseBoards: [
      ...oldComponents.baseBoards.filter(
        (baseBoard) => !deleteIds.includes(baseBoard.id)
      ),
      ...addComponents.baseBoards
    ],
    planks: [
      ...oldComponents.planks.filter((plank) => !deleteIds.includes(plank.id)),
      ...addComponents.planks
    ],
    toeBoards: [
      ...oldComponents.toeBoards.filter(
        (toeBoard) => !deleteIds.includes(toeBoard.id)
      ),
      ...addComponents.toeBoards
    ],
    consoles: [
      ...oldComponents.consoles.filter(
        (console) => !deleteIds.includes(console.id)
      ),
      ...addComponents.consoles
    ],
    stairways: [
      ...oldComponents.stairways.filter(
        (stairway) => !deleteIds.includes(stairway.id)
      ),
      ...addComponents.stairways
    ],
    stairwayInnerGuardRails: [
      ...oldComponents.stairwayInnerGuardRails.filter(
        (stairwayInnerGuardRail) =>
          !deleteIds.includes(stairwayInnerGuardRail.id)
      ),
      ...addComponents.stairwayInnerGuardRails
    ],
    stairwayGuardRails: [
      ...oldComponents.stairwayGuardRails.filter(
        (stairwayGuardRail) => !deleteIds.includes(stairwayGuardRail.id)
      ),
      ...addComponents.stairwayGuardRails
    ],
    diagonalBraces: [
      ...oldComponents.diagonalBraces.filter(
        (diagonalBrace) => !deleteIds.includes(diagonalBrace.id)
      ),
      ...addComponents.diagonalBraces
    ],
    anchors: [
      ...oldComponents.anchors.filter(
        (anchor) => !deleteIds.includes(anchor.id)
      ),
      ...addComponents.anchors
    ],
    frames: [
      ...oldComponents.frames.filter((frame) => !deleteIds.includes(frame.id)),
      ...addComponents.frames
    ],
    guardRails: [
      ...oldComponents.guardRails.filter(
        (guardRail) => !deleteIds.includes(guardRail.id)
      ),
      ...addComponents.guardRails
    ]
  };

  return filteredComponents;
};
