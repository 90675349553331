import { CoreProps } from "world/generic/types";
import StandardLockProps from "world/core/StandardLock/StandardLock.types";
import { Vector3Tuple } from "three";
import { CoreComponentProps } from "world/World.types";

interface StandardProps extends CoreProps {
  length?: number;
  noSpigot?: boolean;
  lockVariant?: StandardLockProps["variant"];
  selected?: boolean;
  hovered?: boolean;
}

export default StandardProps;

export interface Standard extends CoreComponentProps {
  length: number;
  noSpigot?: boolean;
  splits?: number[];
  endPosition?: Vector3Tuple;
}

export interface GraphData {
  startPosition: Vector3Tuple;
  endPosition: Vector3Tuple;
  id: string;
  startGrounded?: boolean;
  endGrounded?: boolean;
  type: ComponentType;
  metadata?: {
    elementVector?: Vector3Tuple;
  };
}

export enum ComponentType {
  ANCHOR = "ANCHOR",
  FRAME = "FRAME",
  STANDARD = "STANDARD",
  BEAM_SPIGOT = "BEAM_SPIGOT",
  LEDGER = "LEDGER",
  BASE_PLATE = "BASE_PLATE",
  BASE_COLLAR = "BASE_COLLAR",
  TOE_BOARD = "TOE_BOARD",
  BASE_BOARD = "BASE_BOARD",
  BOX = "BOX",
  WORLD_PLANE = "WORLD_PLANE",
  PLANK = "PLANK",
  POLYGON = "POLYGON",
  CONSOLE = "CONSOLE",
  STAIRWAY = "STAIRWAY",
  STAIRWAY_INNER_GUARD_RAIL = "STAIRWAY_INNER_GUARD_RAIL",
  STAIRWAY_GUARD_RAIL = "STAIRWAY_GUARD_RAIL",
  DIAGONAL_BRACE = "DIAGONAL_BRACE",
  STANDARD_LOCK = "STANDARD_LOCK",
  ROOF = "ROOF",
  GUARD_RAIL = "GUARD_RAIL"
}

export interface ComponentAttributes {
  id: string;
  material: string;
  variant: string;
}
