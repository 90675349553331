import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component, {
  BayBoxProps,
  LANGUAGE_CODE,
  LockRange,
  PassageBoxProps,
  ReplacedComponent,
  StairBoxProps,
  Supplier
} from "suppliers/scaffold/scaffold.interface";
import {
  componentsAnchors,
  componentsBaseplates,
  componentsConsoles,
  componentsDecks,
  componentsFrames,
  componentsGuardRails,
  componentsOLedgers,
  componentsPassageFrames,
  componentsSingleGuardRails,
  componentsStairs,
  componentsStairwayGuardrails,
  componentsTopFrames,
  componentsULedgers
} from "./components";
import {
  componentsDiagonalBraces,
  componentsStairwayGuardrailsWideInner,
  componentsToeBoards
} from "../allroundModul/components";
import { calculateSegmentLengthCombinations } from "math/optimizers";
import {
  generateOptimalPlankConfiguration,
  getValidFrames
} from "../allroundModul/utils/utils";
import { getOrderedStandardYPosition } from "pages/ScaffoldPage/deps/ToolManager/tools/BoxTool/BoxTool.utils";
import { plus } from "math";
import {
  calcFrameLengthPartitions,
  getFrameRailTop
} from "suppliers/scaffold/scaffold.utils";
import { Euler, Vector3 } from "three";
import {
  BaseBoard,
  Box,
  BoxFrame,
  GuardRail,
  Ledger
} from "shared/interfaces/firestore";
import { DEFAULT_FALL_PROTECTION_HEIGHT } from "suppliers/scaffold/constants";
import { halfPi } from "math/constants";
import { Frame, FRAME_VARIANT } from "world/core/Frame/Frame.types";
import { BoxComponents } from "store/world/box/box.interface";
import {
  generateBaseComponents,
  generateConsoleComponents,
  generateDiagonalBraces,
  generateFrameAnchors,
  generateFrameDeckPlanks,
  generateFrameLedgers,
  generateFrames,
  generateGuardRails,
  generatePassageComponents,
  generateStairComponents,
  generateToeBoards
} from "./utils/component";

class SpeedyScaf implements Supplier {
  private ledgerComponents: Component[] = [];

  constructor() {
    this.ledgerComponents = [...componentsULedgers, ...componentsOLedgers];
  }

  getLedgerAlternatives(props: { length: number }) {
    const { length } = props;

    return this.ledgerComponents.filter((ledger) => ledger.length === length);
  }

  isFrameSupplier(): boolean {
    return true;
  }

  getPlankMaterials(): SCAFFOLD_PLANKTYPE[] {
    const plankTypes = componentsDecks.map(
      (component) => component.material
    ) as SCAFFOLD_PLANKTYPE[];

    const uniquePlankTypes = new Set(plankTypes);

    return [...uniquePlankTypes];
  }

  getBayBoxLengths(): number[] {
    const oLedgerLenghts = componentsOLedgers.map(
      (component) => component.length
    );
    const uLedgerLenghts = componentsULedgers.map(
      (component) => component.length
    );

    const uniqueLengths = new Set([...oLedgerLenghts, ...uLedgerLenghts]);
    return [...uniqueLengths];
  }
  getBayBoxWidths(): number[] {
    const frameWidths = componentsFrames.map((component) => component.width);

    const uniqueWidths = new Set(frameWidths);
    return [...uniqueWidths];
  }

  getPassageBaseBoxWidths(): number[] {
    const frameWidths = componentsPassageFrames.map(
      (component) => component.width
    );

    const uniqueWidths = new Set(frameWidths);
    return [...uniqueWidths];
  }

  getPassagebaseBoxHeights(): number[] {
    const frameHeights = componentsPassageFrames.map(
      (component) => component.length
    );

    const uniqueHeights = new Set(frameHeights);
    return [...uniqueHeights];
  }

  getStandardLengths(bayWidth?: number) {
    const filteredFrames =
      bayWidth === undefined
        ? componentsFrames
        : componentsFrames.filter((f) => f.width === bayWidth);

    const uniqueLengths = new Set(filteredFrames.map((frame) => frame.length));
    return [...uniqueLengths];
  }

  getComponents(): Component[] {
    return [
      ...componentsDecks,
      ...componentsFrames,
      ...componentsPassageFrames,
      ...componentsTopFrames,
      ...componentsULedgers,
      ...componentsOLedgers,
      ...componentsDiagonalBraces,
      ...componentsToeBoards,
      ...componentsStairs,
      ...componentsConsoles,
      ...componentsBaseplates,
      ...componentsGuardRails,
      ...componentsSingleGuardRails,
      //   ...componentsBaseCollars,
      ...componentsStairwayGuardrails,
      ...componentsStairwayGuardrailsWideInner,
      ...componentsAnchors
    ];
  }
  generateBayBoxComponents(props: {
    boxProps: BayBoxProps;
    allBoxes?: Box[];
    skipStandardColumnComponents?: boolean;
    diagonalFrames?: BoxFrame[];
  }) {
    const { boxProps, skipStandardColumnComponents, diagonalFrames } = props;
    /** Extract box properties */
    const { height, depth, width, rotation, options, position, stair, id } =
      boxProps;

    const ledgers: Ledger[] = [];
    const frames: Frame[] = [];
    const baseBoards: BaseBoard[] = [];
    const basePlates: BaseBoard[] = [];
    const guardRails: GuardRail[] = [];
    const replacedStandards: ReplacedComponent[] = [];

    /** Fallprotection height */
    const fallProtectionHeight =
      options?.fallProtectionHeight ?? DEFAULT_FALL_PROTECTION_HEIGHT;

    const standardPositions = options?.standardPositions
      ? ([
          new Vector3().fromArray(options?.standardPositions[2].position),
          new Vector3().fromArray(options?.standardPositions[3].position),
          new Vector3().fromArray(options?.standardPositions[1].position),
          new Vector3().fromArray(options?.standardPositions[0].position)
        ] as [Vector3, Vector3, Vector3, Vector3])
      : ([
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position)
        ] as [Vector3, Vector3, Vector3, Vector3]);

    /** Ray standard positions */
    const rayHitsYPosition = getOrderedStandardYPosition({
      standardPositions: options?.standardPositions
    });

    /** Guard rails or not
     * F, R, B, L
     */
    const guardRailsOptions = options?.guardRails ?? [true, true, true, true];
    let framesRailTop = getFrameRailTop(guardRailsOptions);

    const diagonalBraceOptions = options?.diagonals ?? [
      false,
      false,
      false,
      false
    ];

    const anchorOptions = options?.anchors ?? [false, false, false, false];

    const frameLevels = getValidFrames({
      frames: options?.frames,
      height,
      boxPosition: position,
      standardLengths: this.getStandardLengths()
    });

    const framePositions = [
      {
        left: standardPositions[0],
        right: standardPositions[1]
      },
      {
        left: standardPositions[3],
        right: standardPositions[2]
      }
    ];

    const frameLengths = framePositions.map((pos, index) => {
      const topYHeight = pos.left.y > pos.right.y ? pos.left.y : pos.right.y;
      return {
        positions: {
          left: pos.left.clone().setY(plus(topYHeight, 0.1)),
          right: pos.right.clone().setY(plus(topYHeight, 0.1))
        },
        frameLength: calcFrameLengthPartitions({
          partitions: this.getStandardLengths(width),
          fallProtectionHeight: framesRailTop[index]
            ? Math.max(fallProtectionHeight, 2)
            : undefined,
          deckLevels: frameLevels.map((frame) => frame.height),
          startHeight: topYHeight,
          topVariant: options?.topVariant === FRAME_VARIANT.TOP_INTERMEDIATE
        })
      };
    });

    if (!skipStandardColumnComponents) {
      const { frames: generatedFrames } = generateFrames({
        frameLengths,
        frameWidth: width,
        boxRotation: rotation,
        topVariant: options?.topVariant === FRAME_VARIANT.TOP_INTERMEDIATE,
        guardRailsOptions,
        standardPositions
      });
      frames.push(...generatedFrames);

      /** Creation of:
       * - Base collars
       * - Base boards
       * - Base plates
       */
      const baseComponents = generateBaseComponents({
        frameLengths,
        boxOptions: options,
        rayHitsYPosition,
        boxRotation: rotation
      });
      baseBoards.push(...baseComponents.baseBoards);
      basePlates.push(...baseComponents.basePlates);
    }
    /** Creation of longitudinal and transverse frame ledgers
     * - Builds from the back -> front
     */

    const { ledgers: frameLedgers, replacedLedgers } = generateFrameLedgers({
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      frameLevels: frameLevels,
      framesToSplit: frames,
      boxId: id
    });
    ledgers.push(...frameLedgers);

    /** Longitudinal Guard rails */

    const guardRailComponents = generateGuardRails({
      guardRailsOptions,
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      fallProtectionHeight,
      frameLevels,
      framesToSplit: frames,
      topVariant: options?.topVariant === FRAME_VARIANT.TOP_INTERMEDIATE
    });
    ledgers.push(...guardRailComponents.ledgers);
    guardRails.push(...guardRailComponents.guardRails);

    /** Planks */
    const frameDeckPlanks = generateFrameDeckPlanks({
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      frameLevels,
      stair,
      framesToSplit: frames
    });

    /** Longitudinal and transverse toe boards */
    const toeBoards = generateToeBoards({
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      frameLevels,
      guardRailsOptions
    });

    /** Diagonal braces */
    const diagonalBraces = generateDiagonalBraces({
      standardPositions,
      frameLevels: diagonalFrames ?? frameLevels,
      depth,
      width,
      boxRotation: rotation,
      framesToSplit: frames,
      diagonalBraceOptions,
      diagonalPattern: options?.diagonalPattern
    });

    /** Consoles */
    const { consoles, planks: consolePlanks } = generateConsoleComponents({
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      frameLevels,
      consoleOptions: options?.consoles,
      consoleWidth: options?.consoleWidth
    });

    /** Anchors */
    const anchors = generateFrameAnchors({
      standardPositions,
      frameLevels,
      boxRotation: rotation,
      framesToSplit: frames,
      anchorOptions
    });

    const components: BoxComponents = {
      baseBoards,
      baseCollars: [],
      basePlates,
      consoles,
      ledgers,
      planks: [...frameDeckPlanks, ...consolePlanks],
      stairwayGuardRails: [],
      stairwayInnerGuardRails: [],
      stairways: [],
      beamSpigots: [],
      standards: [],
      frames,
      toeBoards,
      diagonalBraces,
      anchors,
      guardRails
    };
    return {
      components,
      replacedComponents: [...replacedStandards, ...replacedLedgers]
    };
  }

  generatePassageBoxComponents(boxProps: PassageBoxProps, allBoxes?: Box[]) {
    const { height, depth, width, rotation, options, position } = boxProps;

    const standardPositions = options?.standardPositions
      ? ([
          new Vector3().fromArray(options?.standardPositions[2].position), //FR
          new Vector3().fromArray(options?.standardPositions[3].position), // FL
          new Vector3().fromArray(options?.standardPositions[1].position), //BL
          new Vector3().fromArray(options?.standardPositions[0].position) // BR
        ] as [Vector3, Vector3, Vector3, Vector3])
      : ([
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position)
        ] as [Vector3, Vector3, Vector3, Vector3]);

    /** Fallprotection height */
    const fallProtectionHeight =
      options?.fallProtectionHeight ?? DEFAULT_FALL_PROTECTION_HEIGHT;

    /** Ray standard positions */
    const rayHitsYPosition = getOrderedStandardYPosition({
      standardPositions: options?.standardPositions
    });

    /** Guard rails or not
     * F, R, B, L
     */
    const guardRailsOptions = options?.guardRails ?? [true, true, true, true];
    let framesRailTop = getFrameRailTop(guardRailsOptions);

    /** passage Base height  */
    const topBaseHeight = plus(position[1], options?.baseHeight ?? 0);

    const baseHeight = options?.baseHeight ?? 0;
    const baseWidth = options?.baseWidth ?? width;

    const directionVector = new Vector3(0, 0, 1)
      .applyEuler(new Euler().fromArray(rotation))
      .applyAxisAngle(new Vector3(0, 1, 0), halfPi); // perpendicular to the box to get direction of width

    const scalarVector = directionVector.multiplyScalar(width);

    const topFRStandardPosition = standardPositions[1]
      .clone()
      .add(scalarVector);
    const topBRStandardPosition = standardPositions[2]
      .clone()
      .add(scalarVector);

    const topStandard = [
      { railIdx: 0, pos: topFRStandardPosition.clone() },
      { railIdx: 3, pos: topBRStandardPosition.clone() }
    ] as { railIdx: number; pos: Vector3 }[];

    const topBayComponents = this.generateBayBoxComponents({
      boxProps: {
        ...boxProps,
        options: {
          ...boxProps.options,
          standardPositions: [
            // Screw up order of standard positions again
            { position: topBRStandardPosition.toArray() },
            { position: standardPositions[2].toArray() },
            { position: topFRStandardPosition.toArray() },
            { position: standardPositions[1].toArray() }
          ]
        }
      },
      allBoxes,
      skipStandardColumnComponents: true,
      diagonalFrames: options?.frames?.filter(
        (frame) => frame.height > plus(topBaseHeight, 2)
      )
    });

    const framePositions = [
      { left: standardPositions[0], right: standardPositions[1] },
      {
        left: standardPositions[3],
        right: standardPositions[2]
      }
    ];

    const frameLengths = framePositions.map((pos) => {
      return {
        positions: {
          left: pos.left.clone().setY(plus(pos.left.y, 0.1)),
          right: pos.right.clone().setY(plus(pos.right.y, 0.1))
        },
        frameLength: calcFrameLengthPartitions({
          partitions: this.getPassagebaseBoxHeights(),
          deckLevels: [baseHeight]
        })
      };
    });

    const topFramePositions = [
      { right: standardPositions[1], left: topFRStandardPosition },
      {
        right: standardPositions[2],
        left: topBRStandardPosition
      }
    ];

    const frameLevels = getValidFrames({
      frames: options?.frames,
      height,
      boxPosition: position,
      standardLengths: this.getStandardLengths()
    });

    const topFrameLengths = topFramePositions.map((pos, index) => {
      return {
        positions: {
          left: pos.left.clone().setY(plus(pos.left.y, baseHeight + 0.1)),
          right: pos.right.clone().setY(plus(pos.right.y, baseHeight + 0.1))
        },
        frameLength: calcFrameLengthPartitions({
          partitions: this.getStandardLengths(width),
          deckLevels: frameLevels.map((f) => f.height),
          startHeight: baseHeight,
          fallProtectionHeight: framesRailTop[index]
            ? Math.max(fallProtectionHeight, 2)
            : undefined,
          topVariant: options?.topVariant === FRAME_VARIANT.TOP_INTERMEDIATE
        })
      };
    });

    const topFrames = generateFrames({
      frameLengths: topFrameLengths,
      frameWidth: width,
      boxRotation: rotation,
      topVariant: options?.topVariant === FRAME_VARIANT.TOP_INTERMEDIATE,
      guardRailsOptions,
      standardPositions: [
        topFRStandardPosition,
        standardPositions[1],
        standardPositions[2],
        topBRStandardPosition
      ]
    });

    /** Passage components */
    const passageComponents = generatePassageComponents({
      standardPositions: standardPositions,
      frameLengths,
      topOuterStandardPositions: [topStandard[0].pos, topStandard[1].pos],
      boxOptions: options,
      rayHitsYPosition,
      boxRotation: rotation,
      depth,
      baseWidth,
      boxPosition: position
    });

    const components = {
      ...topBayComponents.components,
      basePlates: passageComponents.basePlates,
      ledgers: [
        ...topBayComponents.components.ledgers,
        ...passageComponents.ledgers
      ],
      frames: [...passageComponents.frames, ...topFrames.frames]
    };

    return {
      components,
      replacedComponents: []
    };
  }
  generateStairBoxComponents(props: {
    boxProps: StairBoxProps;
    allBoxes?: Box[];
  }) {
    const { boxProps, allBoxes } = props;
    const { height, depth, rotation, options, position } = boxProps;
    const {
      components: boxComponents,
      replacedComponents: replacedBoxComponents
    } = this.generateBayBoxComponents({
      boxProps: {
        ...boxProps,
        stair: true
      },
      allBoxes
    });

    const standardPositions = options?.standardPositions
      ? ([
          new Vector3().fromArray(options?.standardPositions[2].position),
          new Vector3().fromArray(options?.standardPositions[3].position),
          new Vector3().fromArray(options?.standardPositions[1].position),
          new Vector3().fromArray(options?.standardPositions[0].position)
        ] as [Vector3, Vector3, Vector3, Vector3])
      : ([
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position)
        ] as [Vector3, Vector3, Vector3, Vector3]);

    const frameLevels = getValidFrames({
      frames: options?.frames,
      height,
      boxPosition: position,
      standardLengths: this.getStandardLengths()
    });

    /** Stairs */
    const { stairwayInnerGuardRails, stairways, stairwayGuardRails } =
      generateStairComponents({
        standardPositions,
        boxRotation: rotation,
        depth,
        frameLevels,
        stairwayInnerGuardRail: options?.stairwayInnerGuardRail,
        stairwayGuardRail: options?.stairwayGuardRail
      });

    const components = {
      ...boxComponents,
      stairwayGuardRails,
      stairwayInnerGuardRails,
      stairways
    };

    return {
      components,
      replacedComponents: replacedBoxComponents
    };
  }

  getStairBoxLengths(): number[] {
    return componentsStairs.map((component) => component.length);
  }

  getStairBoxWidths(): number[] {
    const allWidths = this.getBayBoxWidths();

    const stairMinWidth = Math.min(
      ...componentsStairs.map((component) => component.width, 0)
    );
    return allWidths.filter((width) => width >= stairMinWidth);
  }

  getAvailableLockAnchorRanges(): LockRange[] {
    return [{ min: 0, max: 360 }];
  }

  getPlankConfigurations = (props: {
    length: number;
    width: number;
    plankType?: SCAFFOLD_PLANKTYPE;
  }) => {
    const { length, width, plankType } = props;
    if (!plankType) return [];
    const decks = componentsDecks.filter((deck) => deck.material === plankType);

    const correctLengthDecks = decks.filter((deck) => deck.length === length);

    if (correctLengthDecks.length === 0) {
      return [];
    }

    const uniqueWidths = [
      ...new Set(correctLengthDecks.map((deck) => deck.width))
    ];

    const plankConfigurations = calculateSegmentLengthCombinations({
      lengthSegments: uniqueWidths,
      targetLength: width
    });

    return plankConfigurations;
  };

  getOptimalPlankConfiguration = (props: {
    length: number;
    width: number;
    plankType?: SCAFFOLD_PLANKTYPE;
  }) => {
    return generateOptimalPlankConfiguration(props);
  };

  getComponentLabel = (id: string, languageCode: LANGUAGE_CODE) => {
    const component = this.getComponents().find(
      (component) => component.article_id === id
    );
    if (!component) return "";
    switch (languageCode) {
      case LANGUAGE_CODE.SE:
        return component.label_se;
      case LANGUAGE_CODE.NO:
        return component.label_no;
      case LANGUAGE_CODE.EN:
      default:
        return component.label_en;
    }
  };
  getComponentWeight = (id: string) => {
    const component = this.getComponents().find(
      (component) => component.article_id === id
    );
    if (!component) return 0;
    return component.weight;
  };
  getConsoleWidths = () => {
    const widthSet = new Set(
      componentsConsoles.map((component) => component.width)
    );
    return Array.from(widthSet.values());
  };
}

export default SpeedyScaf;
