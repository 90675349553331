import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import Component from "suppliers/scaffold/scaffold.interface";
import { LEDGER_VARIANT } from "world/manager/LedgerManager/LedgerManager.enums";

export const componentsLedgers: Component[] = [
  {
    width: 0,
    article_id: "114613",
    length: 0.25,
    material: "",
    label_se: "Horisontalbalk UH 25 Plus",
    label_no: "Horisontal bjelke UH 25 Plus",
    label_en: "Horizontal beam UH 25 Plus",
    weight: 1.43
  },
  {
    width: 0,
    article_id: "114595",
    length: 0.5,
    material: "",
    label_se: "Horisontalbalk UH 50 Plus",
    label_no: "Horisontal bjelke UH 50 Plus",
    label_en: "Horizontal beam UH 50 Plus",
    weight: 2.08
  },
  {
    width: 0,
    article_id: "114629",
    length: 0.75,
    material: "",
    label_se: "Horisontalbalk UH 75 Plus",
    label_no: "Horisontal bjelke UH 75 Plus",
    label_en: "Horizontal beam UH 75 Plus",
    weight: 2.54
  },
  {
    width: 0,
    article_id: "114632",
    length: 1,
    material: "",
    label_se: "Horisontalbalk UH 100 Plus",
    label_no: "Horisontal bjelke UH 100 Plus",
    label_en: "Horizontal beam UH 100 Plus",
    weight: 4.47
  },
  {
    width: 0,
    article_id: "114638",
    length: 1.25,
    material: "",
    label_se: "Horisontalbalk UH 125 Plus",
    label_no: "Horisontal bjelke UH 125 Plus",
    label_en: "Horizontal beam UH 125 Plus",
    weight: 5.44
  },
  {
    width: 0,
    article_id: "114641",
    length: 1.5,
    material: "",
    label_se: "Horisontalbalk UH 150 Plus",
    label_no: "Horisontal bjelke UH 150 Plus",
    label_en: "Horizontal beam UH 150 Plus",
    weight: 4.72
  },
  {
    width: 0,
    article_id: "117032",
    length: 1.75,
    material: "",
    label_se: "Horisontalbalk UH 175 Plus",
    label_no: "Horisontal bjelke UH 175 Plus",
    label_en: "Horizontal beam UH 175 Plus",
    weight: 5.39
  },
  {
    width: 0,
    article_id: "114645",
    length: 2,
    material: "",
    label_se: "Horisontalbalk UH 200 Plus",
    label_no: "Horisontal bjelke UH 200 Plus",
    label_en: "Horizontal beam UH 200 Plus",
    weight: 6.05
  },
  {
    width: 0,
    article_id: "116356",
    length: 2.25,
    material: "",
    label_se: "Horisontalbalk UH 225 Plus",
    label_no: "Horisontal bjelke UH 225 Plus",
    label_en: "Horizontal beam UH 225 Plus",
    weight: 6.71
  },
  {
    width: 0,
    article_id: "114648",
    length: 2.5,
    material: "",
    label_se: "Horisontalbalk UH 250 Plus",
    label_no: "Horisontal bjelke UH 250 Plus",
    label_en: "Horizontal beam UH 250 Plus",
    weight: 7.37
  },
  {
    width: 0,
    article_id: "114651",
    length: 3,
    material: "",
    label_se: "Horisontalbalk UH 300 Plus",
    label_no: "Horisontal bjelke UH 300 Plus",
    label_en: "Horizontal beam UH 300 Plus",
    weight: 8.69
  },
  {
    width: 0,
    article_id: "114654",
    length: 4,
    material: "",
    label_se: "Horisontalbalk UH 400 Plus",
    label_no: "Horisontal bjelke UH 400 Plus",
    label_en: "Horizontal beam UH 400 Plus",
    weight: 11.3
  },
  {
    width: 0,
    article_id: "131995",
    length: 0.25,
    material: "",
    label_se: "Horisontalbalk UH-2 25",
    label_no: "Horisontal bjelke UH-2 25",
    label_en: "Horizontal beam UH-2 25",
    weight: 1.4
  },
  {
    width: 0,
    article_id: "133900",
    length: 0.5,
    material: "",
    label_se: "Horisontalbalk UH-2 33",
    label_no: "Horisontal bjelke UH-2 33",
    label_en: "Horizontal beam UH-2 33",
    weight: 1.59
  },
  {
    width: 0,
    article_id: "131998",
    length: 0.5,
    material: "",
    label_se: "Horisontalbalk UH-2 50",
    label_no: "Horisontal bjelke UH-2 50",
    label_en: "Horizontal beam UH-2 50",
    weight: 2.03
  },
  {
    width: 0,
    article_id: "133903",
    length: 0.67,
    material: "",
    label_se: "Horisontalbalk UH-2 67",
    label_no: "Horisontal bjelke UH-2 67",
    label_en: "Horizontal beam UH-2 67",
    weight: 2.48
  },
  {
    width: 0,
    article_id: "132213",
    length: 0.75,
    material: "",
    label_se: "Horisontalbalk UH-2 75",
    label_no: "Horisontal bjelke UH-2 75",
    label_en: "Horizontal beam UH-2 75",
    weight: 2.69
  },
  {
    width: 0,
    article_id: "132004",
    length: 1,
    material: "",
    label_se: "Horisontalbalk UH-2 100",
    label_no: "Horisontal bjelke UH-2 100",
    label_en: "Horizontal beam UH-2 100",
    weight: 3.79
  },
  {
    width: 0,
    article_id: "132007",
    length: 1.25,
    material: "",
    label_se: "Horisontalbalk UH-2 125",
    label_no: "Horisontal bjelke UH-2 125",
    label_en: "Horizontal beam UH-2 125",
    weight: 4.58
  },
  {
    width: 0,
    article_id: "132010",
    length: 1.5,
    material: "",
    label_se: "Horisontalbalk UH-2 150",
    label_no: "Horisontal bjelke UH-2 150",
    label_en: "Horizontal beam UH-2 150",
    weight: 4.68
  },
  {
    width: 0,
    article_id: "132013",
    length: 1.75,
    material: "",
    label_se: "Horisontalbalk UH-2 175",
    label_no: "Horisontal bjelke UH-2 175",
    label_en: "Horizontal beam UH-2 175",
    weight: 5.34
  },
  {
    width: 0,
    article_id: "132016",
    length: 2,
    material: "",
    label_se: "Horisontalbalk UH-2 200",
    label_no: "Horisontal bjelke UH-2 200",
    label_en: "Horizontal beam UH-2 200",
    weight: 6
  },
  {
    width: 0,
    article_id: "132019",
    length: 2.25,
    material: "",
    label_se: "Horisontalbalk UH-2 225",
    label_no: "Horisontal bjelke UH-2 225",
    label_en: "Horizontal beam UH-2 225",
    weight: 6.66
  },
  {
    width: 0,
    article_id: "132025",
    length: 2.5,
    material: "",
    label_se: "Horisontalbalk UH-2 250",
    label_no: "Horisontal bjelke UH-2 250",
    label_en: "Horizontal beam UH-2 250",
    weight: 7.32
  },
  {
    width: 0,
    article_id: "132022",
    length: 3,
    material: "",
    label_se: "Horisontalbalk UH-2 300",
    label_no: "Horisontal bjelke UH-2 300",
    label_en: "Horizontal beam UH-2 300",
    weight: 8.65
  },
  {
    width: 0,
    article_id: "404780",
    length: 0.25,
    material: "",
    label_se: "Horisontalbalk UH 25",
    label_no: "Horisontal bjelke UH 25",
    label_en: "Horizontal beam UH 25",
    weight: 1.39
  },
  {
    width: 0,
    article_id: "404779",
    length: 0.5,
    material: "",
    label_se: "Horisontalbalk UH 50",
    label_no: "Horisontal bjelke UH 50",
    label_en: "Horizontal beam UH 50",
    weight: 2.04
  },
  {
    width: 0,
    article_id: "400017",
    length: 0.75,
    material: "",
    label_se: "Horisontalbalk UH 75",
    label_no: "Horisontal bjelke UH 75",
    label_en: "Horizontal beam UH 75",
    weight: 2.71
  },
  {
    width: 0,
    article_id: "401159",
    length: 1,
    material: "",
    label_se: "Horisontalbalk UH 100",
    label_no: "Horisontal bjelke UH 100",
    label_en: "Horizontal beam UH 100",
    weight: 3.37
  },
  {
    width: 0,
    article_id: "410347",
    length: 1.25,
    material: "",
    label_se: "Horisontalbalk UH 125",
    label_no: "Horisontal bjelke UH 125",
    label_en: "Horizontal beam UH 125",
    weight: 4.02
  },
  {
    width: 0,
    article_id: "400021",
    length: 1.5,
    material: "",
    label_se: "Horisontalbalk UH 150",
    label_no: "Horisontal bjelke UH 150",
    label_en: "Horizontal beam UH 150",
    weight: 4.69
  },
  {
    width: 0,
    article_id: "400023",
    length: 2,
    material: "",
    label_se: "Horisontalbalk UH 200",
    label_no: "Horisontal bjelke UH 200",
    label_en: "Horizontal beam UH 200",
    weight: 6.02
  },
  {
    width: 0,
    article_id: "400025",
    length: 2.5,
    material: "",
    label_se: "Horisontalbalk UH 250",
    label_no: "Horisontal bjelke UH 250",
    label_en: "Horizontal beam UH 250",
    weight: 7.34
  },
  {
    width: 0,
    article_id: "400027",
    length: 3,
    material: "",
    label_se: "Horisontalbalk UH 300",
    label_no: "Horisontal bjelke UH 300",
    label_en: "Horizontal beam UH 300",
    weight: 8.67
  },
  {
    width: 0,
    article_id: "400029",
    length: 4,
    material: "",
    label_se: "Horisontalbalk UH 400",
    label_no: "Horisontal bjelke UH 400",
    label_en: "Horizontal beam UH 400",
    weight: 11.3
  },
  {
    width: 0,
    article_id: "114681",
    length: 1.5,
    material: "",
    label_se: "Horisontalbalk UHV 150 Plus",
    label_no: "Horisontal bjelke UHV 150 Plus",
    label_en: "Horizontal beam UHV 150 Plus",
    weight: 11,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    article_id: "114687",
    length: 2,
    material: "",
    label_se: "Horisontalbalk UHV 200 Plus",
    label_no: "Horisontal bjelke UHV 200 Plus",
    label_en: "Horizontal beam UHV 200 Plus",
    weight: 14.9,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    article_id: "114691",
    length: 2.5,
    material: "",
    label_se: "Horisontalbalk UHV 250 Plus",
    label_no: "Horisontal bjelke UHV 250 Plus",
    label_en: "Horizontal beam UHV 250 Plus",
    weight: 18.1,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    article_id: "114695",
    length: 3,
    material: "",
    label_se: "Horisontalbalk UHV 300 Plus",
    label_no: "Horisontal bjelke UHV 300 Plus",
    label_en: "Horizontal beam UHV 300 Plus",
    weight: 21.8,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    article_id: "409107",
    length: 1.5,
    material: "",
    label_se: "Horisontalbalk förstärkt UHV 150",
    label_no: "Horisontal bjelke forsterket UHV 150",
    label_en: "Horizontal beam reinforced UHV 150",
    weight: 10.9,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    article_id: "409108",
    length: 2,
    material: "",
    label_se: "Horisontalbalk förstärkt UHV 200",
    label_no: "Horisontal bjelke forsterket UHV 200",
    label_en: "Horizontal beam reinforced UHV 200",
    weight: 14.8,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    article_id: "409109",
    length: 2.5,
    material: "",
    label_se: "Horisontalbalk förstärkt UHV 250",
    label_no: "Horisontal bjelke forsterket UHV 250",
    label_en: "Horizontal beam reinforced UHV 250",
    weight: 18,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    article_id: "409110",
    length: 3,
    material: "",
    label_se: "Horisontalbalk förstärkt UHV 300",
    label_no: "Horisontal bjelke forsterket UHV 300",
    label_en: "Horizontal beam reinforced UHV 300",
    weight: 21.8,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    article_id: "114699",
    length: 2.5,
    material: "",
    label_se: "Horisontalbalk UHV-L 250 Plus",
    label_no: "Horisontal bjelke UHV-L 250 Plus",
    label_en: "Horizontal beam UHV-L 250 Plus",
    weight: 12.7,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    article_id: "114718",
    length: 3,
    material: "",
    label_se: "Horisontalbalk UHV-L 300 Plus",
    label_no: "Horisontal bjelke UHV-L 300 Plus",
    label_en: "Horizontal beam UHV-L 300 Plus",
    weight: 15.3,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    article_id: "410807",
    length: 2.5,
    material: "",
    label_se: "Horisontalbalk förstärkt UHV-L 250",
    label_no: "Horisontal bjelke forsterket UHV-L 250",
    label_en: "Horizontal beam reinforced UHV-L 250",
    weight: 12.6,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  },
  {
    width: 0,
    article_id: "410815",
    length: 3,
    material: "",
    label_se: "Horisontalbalk förstärkt UHV-L 300",
    label_no: "Horisontal bjelke forsterket UHV-L 300",
    label_en: "Horizontal beam reinforced UHV-L 300",
    weight: 15.3,
    variant: LEDGER_VARIANT.U_BRIDGING_LEDGER
  }
];

export const componentsToeBoards: Component[] = [
  {
    article_id: "110213",
    weight: 0.927,
    label_se: "Fotlist stål UPY 50",
    label_en: "Footboard steel UPY 50",
    width: 0.15,
    material: "",
    length: 0.5,
    label_no: "Fotbrett stål UPY 50"
  },
  {
    article_id: "110526",
    weight: 1.44,
    label_se: "Fotlist stål UPY 75",
    label_en: "Footboard steel UPY 75",
    width: 0.15,
    material: "",
    length: 0.75,
    label_no: "Fotbrett stål UPY 75"
  },
  {
    article_id: "110073",
    weight: 1.96,
    label_se: "Fotlist stål UPY 100",
    label_en: "Footboard steel UPY 100",
    width: 0.15,
    material: "",
    length: 1,
    label_no: "Fotbrett stål UPY 100"
  },
  {
    article_id: "110160",
    weight: 3,
    label_se: "Fotlist stål UPY 150",
    label_en: "Footboard steel UPY 150",
    width: 0.15,
    material: "",
    length: 1.5,
    label_no: "Fotbrett stål UPY 150"
  },
  {
    article_id: "110176",
    weight: 4.03,
    label_se: "Fotlist stål UPY 200",
    label_en: "Footboard steel UPY 200",
    width: 0.15,
    material: "",
    length: 2,
    label_no: "Fotbrett stål UPY 200"
  },
  {
    article_id: "110208",
    weight: 5.06,
    label_se: "Fotlist stål UPY 250",
    label_en: "Footboard steel UPY 250",
    width: 0.15,
    material: "",
    length: 2.5,
    label_no: "Fotbrett stål UPY 250"
  },
  {
    article_id: "110211",
    weight: 6.1,
    label_se: "Fotlist stål UPY 300",
    label_en: "Footboard steel UPY 300",
    width: 0.15,
    material: "",
    length: 3,
    label_no: "Fotbrett stål UPY 300"
  }
];

export const componentsDecks: Component[] = [
  {
    width: 0.25,
    label_en: "Steel deck UDI 25 x 50",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "104029",
    label_se: "Stålplan UDI 25 x 50",
    label_no: "Stålplan UDI 25 x 50",
    weight: 4.09,
    length: 0.5
  },
  {
    width: 0.25,
    label_en: "Steel deck UDI 25 x 75",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "105925",
    label_se: "Stålplan UDI 25 x 75",
    label_no: "Stålplan UDI 25 x 75",
    weight: 5.52,
    length: 0.75
  },
  {
    width: 0.25,
    label_en: "Steel deck UDI 25 x 100",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "106092",
    label_se: "Stålplan UDI 25 x 100",
    label_no: "Stålplan UDI 25 x 100",
    weight: 6.95,
    length: 1
  },
  {
    width: 0.25,
    label_en: "Steel deck UDI 25 x 125",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "106880",
    label_se: "Stålplan UDI 25 x 125",
    label_no: "Stålplan UDI 25 x 125",
    weight: 8.38,
    length: 1.25
  },
  {
    width: 0.25,
    label_en: "Steel deck UDI 25 x 150",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "107002",
    label_se: "Stålplan UDI 25 x 150",
    label_no: "Stålplan UDI 25 x 150",
    weight: 9.79,
    length: 1.5
  },
  {
    width: 0.25,
    label_en: "Steel deck UDI 25 x 200",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "108380",
    label_se: "Stålplan UDI 25 x 200",
    label_no: "Stålplan UDI 25 x 200",
    weight: 12.7,
    length: 2
  },
  {
    width: 0.25,
    label_en: "Steel deck UDI 25 x 250",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "108540",
    label_se: "Stålplan UDI 25 x 250",
    label_no: "Stålplan UDI 25 x 250",
    weight: 15.5,
    length: 2.5
  },
  {
    width: 0.25,
    label_en: "Steel deck UDI 25 x 300",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "108689",
    label_se: "Stålplan UDI 25 x 300",
    label_no: "Stålplan UDI 25 x 300",
    weight: 18.4,
    length: 3
  },
  {
    width: 0.375,
    label_en: "Steel deck UDI 37,5 x 50",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "111685",
    label_se: "Stålplan UDI 37,5 x 50",
    label_no: "Stålplan UDI 37,5 x 50",
    weight: 5.11,
    length: 0.5
  },
  {
    width: 0.375,
    label_en: "Steel deck UDI 37,5 x 75",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "111687",
    label_se: "Stålplan UDI 37,5 x 75",
    label_no: "Stålplan UDI 37,5 x 75",
    weight: 6.79,
    length: 0.75
  },
  {
    width: 0.375,
    label_en: "Steel deck UDI 37,5 x 100",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "111686",
    label_se: "Stålplan UDI 37,5 x 100",
    label_no: "Stålplan UDI 37,5 x 100",
    weight: 8.46,
    length: 1
  },
  {
    width: 0.375,
    label_en: "Steel deck UDI 37,5 x 125",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "111860",
    label_se: "Stålplan UDI 37,5 x 125",
    label_no: "Stålplan UDI 37,5 x 125",
    weight: 10.1,
    length: 1.25
  },
  {
    width: 0.375,
    label_en: "Steel deck UDI 37,5 x 150",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "111863",
    label_se: "Stålplan UDI 37,5 x 150",
    label_no: "Stålplan UDI 37,5 x 150",
    weight: 11.8,
    length: 1.5
  },
  {
    width: 0.375,
    label_en: "Steel deck UDI 37,5 x 200",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "111864",
    label_se: "Stålplan UDI 37,5 x 200",
    label_no: "Stålplan UDI 37,5 x 200",
    weight: 15.2,
    length: 2
  },
  {
    width: 0.375,
    label_en: "Steel deck UDI 37,5 x 250",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "111865",
    label_se: "Stålplan UDI 37,5 x 250",
    label_no: "Stålplan UDI 37,5 x 250",
    weight: 18.5,
    length: 2.5
  },
  {
    width: 0.375,
    label_en: "Steel deck UDI 37,5 x 300",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "111969",
    label_se: "Stålplan UDI 37,5 x 300",
    label_no: "Stålplan UDI 37,5 x 300",
    weight: 21.9,
    length: 3
  },
  {
    width: 0.25,
    label_en: "Steel deck UDG 25 x 50",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "124124",
    label_se: "Stålplan UDG 25 x 50",
    label_no: "Stålplan UDG 25 x 50",
    weight: 3.81,
    length: 0.5
  },
  {
    width: 0.25,
    label_en: "Steel deck UDG 25 x 75",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "124121",
    label_se: "Stålplan UDG 25 x 75",
    label_no: "Stålplan UDG 25 x 75",
    weight: 5.19,
    length: 0.75
  },
  {
    width: 0.25,
    label_en: "Steel deck UDG 25 x 100",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "124118",
    label_se: "Stålplan UDG 25 x 100",
    label_no: "Stålplan UDG 25 x 100",
    weight: 6.55,
    length: 1
  },
  {
    width: 0.25,
    label_en: "Steel deck UDG 25 x 125",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "124115",
    label_se: "Stålplan UDG 25 x 125",
    label_no: "Stålplan UDG 25 x 125",
    weight: 7.93,
    length: 1.25
  },
  {
    width: 0.25,
    label_en: "Steel deck UDG 25 x 150",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "124112",
    label_se: "Stålplan UDG 25 x 150",
    label_no: "Stålplan UDG 25 x 150",
    weight: 9.32,
    length: 1.5
  },
  {
    width: 0.25,
    label_en: "Steel deck UDG 25 x 200",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "124109",
    label_se: "Stålplan UDG 25 x 200",
    label_no: "Stålplan UDG 25 x 200",
    weight: 12.2,
    length: 2
  },
  {
    width: 0.25,
    label_en: "Steel deck UDG 25 x 250",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "123771",
    label_se: "Stålplan UDG 25 x 250",
    label_no: "Stålplan UDG 25 x 250",
    weight: 14.9,
    length: 2.5
  },
  {
    width: 0.25,
    label_en: "Steel deck UDG 25 x 300",
    material: SCAFFOLD_PLANKTYPE.STEEL,
    article_id: "124915",
    label_se: "Stålplan UDG 25 x 300",
    label_no: "Stålplan UDG 25 x 300",
    weight: 17.7,
    length: 3
  }
];

export const componentsStandards: Component[] = [
  {
    article_id: "102859",
    label_en: "Standard UVR 50",
    length: 0.5,
    weight: 3.08,
    label_se: "Spira UVR 50",
    material: "",
    label_no: "Spire UVR 50",
    width: 0
  },
  {
    article_id: "101306",
    label_en: "Standard UVR 100",
    length: 1,
    weight: 5.38,
    label_se: "Spira UVR 100",
    material: "",
    label_no: "Spire UVR 100",
    width: 0
  },
  {
    article_id: "102860",
    label_en: "Standard UVR 150",
    length: 1.5,
    weight: 7.69,
    label_se: "Spira UVR 150",
    material: "",
    label_no: "Spire UVR 150",
    width: 0
  },
  {
    article_id: "100009",
    label_en: "Standard UVR 200",
    length: 2,
    weight: 9.99,
    label_se: "Spira UVR 200",
    material: "",
    label_no: "Spire UVR 200",
    width: 0
  },
  {
    article_id: "100012",
    label_en: "Standard UVR 300",
    length: 3,
    weight: 14.7,
    label_se: "Spira UVR 300",
    material: "",
    label_no: "Spire UVR 300",
    width: 0
  },
  {
    article_id: "100013",
    label_en: "Standard UVR 400",
    length: 4,
    weight: 19.2,
    label_se: "Spira UVR 400",
    material: "",
    label_no: "Spire UVR 400",
    width: 0
  },
  {
    article_id: "132219",
    label_en: "Standard UVR-2 50",
    length: 0.5,
    weight: 2.48,
    label_se: "Spira UVR-2 50",
    material: "",
    label_no: "Spire UVR-2 50",
    width: 0
  },
  {
    article_id: "132224",
    label_en: "Standard UVR-2 100",
    length: 1,
    weight: 4.34,
    label_se: "Spira UVR-2 100",
    material: "",
    label_no: "Spire UVR-2 100",
    width: 0
  },
  {
    article_id: "132229",
    label_en: "Standard UVR-2 150",
    length: 1.5,
    weight: 6.18,
    label_se: "Spira UVR-2 150",
    material: "",
    label_no: "Spire UVR-2 150",
    width: 0
  },
  {
    article_id: "132234",
    label_en: "Standard UVR-2 200",
    length: 2,
    weight: 8.03,
    label_se: "Spira UVR-2 200",
    material: "",
    label_no: "Spire UVR-2 200",
    width: 0
  },
  {
    article_id: "132239",
    label_en: "Standard UVR-2 300",
    length: 3,
    weight: 11.7,
    label_se: "Spira UVR-2 300",
    material: "",
    label_no: "Spire UVR-2 300",
    width: 0
  }
];

export const componentsDiagonalBraces: Component[] = [
  {
    label_se: "Rosettdiagonal UBK 75/200",
    label_en: "Rosette diagonal UBK 75/200",
    label_no: "Rosettdiagonal UBK 75/200",
    material: "",
    length: 0.75,
    weight: 6.78,
    width: 0,
    article_id: "124170"
  },
  {
    label_se: "Rosettdiagonal UBK 100/200",
    label_en: "Rosette diagonal UBK 100/200",
    label_no: "Rosettdiagonal UBK 100/200",
    material: "",
    length: 1,
    weight: 6.99,
    width: 0,
    article_id: "112926"
  },
  {
    label_se: "Rosettdiagonal UBK 125/100",
    label_en: "Rosette diagonal UBK 125/100",
    label_no: "Rosettdiagonal UBK 125/100",
    material: "",
    length: 1.25,
    weight: 5.22,
    width: 0,
    article_id: "115354"
  },
  {
    label_se: "Rosettdiagonal UBK 125/200",
    label_en: "Rosette diagonal UBK 125/200",
    label_no: "Rosettdiagonal UBK 125/200",
    material: "",
    length: 1.25,
    weight: 7.26,
    width: 0,
    article_id: "112765"
  },
  {
    label_se: "Rosettdiagonal UBK 150/100",
    label_en: "Rosette diagonal UBK 150/100",
    label_no: "Rosettdiagonal UBK 150/100",
    material: "",
    length: 1.5,
    weight: 5.71,
    width: 0,
    article_id: "100981"
  },
  {
    label_se: "Rosettdiagonal UBK 150/150",
    label_en: "Rosette diagonal UBK 150/150",
    label_no: "Rosettdiagonal UBK 150/150",
    material: "",
    length: 1.5,
    weight: 5.58,
    width: 0,
    article_id: "100973"
  },
  {
    label_se: "Rosettdiagonal UBK 150/200",
    label_en: "Rosette diagonal UBK 150/200",
    label_no: "Rosettdiagonal UBK 150/200",
    material: "",
    length: 1.5,
    weight: 7.6,
    width: 0,
    article_id: "100572"
  },
  {
    label_se: "Rosettdiagonal UBK 200/100",
    label_en: "Rosette diagonal UBK 200/100",
    label_no: "Rosettdiagonal UBK 200/100",
    material: "",
    length: 2,
    weight: 6.79,
    width: 0,
    article_id: "100985"
  },
  {
    label_se: "Rosettdiagonal UBK 200/150",
    label_en: "Rosette diagonal UBK 200/150",
    label_no: "Rosettdiagonal UBK 200/150",
    material: "",
    length: 2,
    weight: 7.51,
    width: 0,
    article_id: "106630"
  },
  {
    label_se: "Rosettdiagonal UBK 200/200",
    label_en: "Rosette diagonal UBK 200/200",
    label_no: "Rosettdiagonal UBK 200/200",
    material: "",
    length: 2,
    weight: 8.39,
    width: 0,
    article_id: "100573"
  },
  {
    label_se: "Rosettdiagonal UBK 250/100",
    label_en: "Rosette diagonal UBK 250/100",
    label_no: "Rosettdiagonal UBK 250/100",
    material: "",
    length: 2.5,
    weight: 7.94,
    width: 0,
    article_id: "100989"
  },
  {
    label_se: "Rosettdiagonal UBK 250/150",
    label_en: "Rosette diagonal UBK 250/150",
    label_no: "Rosettdiagonal UBK 250/150",
    material: "",
    length: 2.5,
    weight: 8.54,
    width: 0,
    article_id: "106624"
  },
  {
    label_se: "Rosettdiagonal UBK 250/200",
    label_en: "Rosette diagonal UBK 250/200",
    label_no: "Rosettdiagonal UBK 250/200",
    material: "",
    length: 2.5,
    weight: 9.31,
    width: 0,
    article_id: "100574"
  },
  {
    label_se: "Rosettdiagonal UBK 300/100",
    label_en: "Rosette diagonal UBK 300/100",
    label_no: "Rosettdiagonal UBK 300/100",
    material: "",
    length: 3,
    weight: 9.13,
    width: 0,
    article_id: "100993"
  },
  {
    label_se: "Rosettdiagonal UBK 300/200",
    label_en: "Rosette diagonal UBK 300/200",
    label_no: "Rosettdiagonal UBK 300/200",
    material: "",
    length: 3,
    weight: 10.3,
    width: 0,
    article_id: "100575"
  },
  {
    label_se: "Rosettdiagonal UBK-2 75/200",
    label_en: "Rosette diagonal UBK-2 75/200",
    label_no: "Rosettdiagonal UBK-2 75/200",
    material: "",
    length: 0.75,
    weight: 4.96,
    width: 0,
    article_id: "133418"
  },
  {
    label_se: "Rosettdiagonal UBK-2 100/200",
    label_en: "Rosette diagonal UBK-2 100/200",
    label_no: "Rosettdiagonal UBK-2 100/200",
    material: "",
    length: 1,
    weight: 5.11,
    width: 0,
    article_id: "133421"
  },
  {
    label_se: "Rosettdiagonal UBK-2 125/100",
    label_en: "Rosette diagonal UBK-2 125/100",
    label_no: "Rosettdiagonal UBK-2 125/100",
    material: "",
    length: 1.25,
    weight: 3.88,
    width: 0,
    article_id: "133424"
  },
  {
    label_se: "Rosettdiagonal UBK-2 125/200",
    label_en: "Rosette diagonal UBK-2 125/200",
    label_no: "Rosettdiagonal UBK-2 125/200",
    material: "",
    length: 1.25,
    weight: 5.33,
    width: 0,
    article_id: "133427"
  },
  {
    label_se: "Rosettdiagonal UBK-2 150/100",
    label_en: "Rosette diagonal UBK-2 150/100",
    label_no: "Rosettdiagonal UBK-2 150/100",
    material: "",
    length: 1.5,
    weight: 4.22,
    width: 0,
    article_id: "133430"
  },
  {
    label_se: "Rosettdiagonal UBK-2 150/150",
    label_en: "Rosette diagonal UBK-2 150/150",
    label_no: "Rosettdiagonal UBK-2 150/150",
    material: "",
    length: 1.5,
    weight: 4.85,
    width: 0,
    article_id: "133433"
  },
  {
    label_se: "Rosettdiagonal UBK-2 150/200",
    label_en: "Rosette diagonal UBK-2 150/200",
    label_no: "Rosettdiagonal UBK-2 150/200",
    material: "",
    length: 1.5,
    weight: 5.53,
    width: 0,
    article_id: "133463"
  },
  {
    label_se: "Rosettdiagonal UBK-2 200/100",
    label_en: "Rosette diagonal UBK-2 200/100",
    label_no: "Rosettdiagonal UBK-2 200/100",
    material: "",
    length: 2,
    weight: 4.97,
    width: 0,
    article_id: "133439"
  },
  {
    label_se: "Rosettdiagonal UBK-2 200/150",
    label_en: "Rosette diagonal UBK-2 200/150",
    label_no: "Rosettdiagonal UBK-2 200/150",
    material: "",
    length: 2,
    weight: 5.47,
    width: 0,
    article_id: "133442"
  },
  {
    label_se: "Rosettdiagonal UBK-2 200/200",
    label_en: "Rosette diagonal UBK-2 200/200",
    label_no: "Rosettdiagonal UBK-2 200/200",
    material: "",
    length: 2,
    weight: 6.08,
    width: 0,
    article_id: "133445"
  },
  {
    label_se: "Rosettdiagonal UBK-2 250/100",
    label_en: "Rosette diagonal UBK-2 250/100",
    label_no: "Rosettdiagonal UBK-2 250/100",
    material: "",
    length: 2.5,
    weight: 5.77,
    width: 0,
    article_id: "133448"
  },
  {
    label_se: "Rosettdiagonal UBK-2 250/150",
    label_en: "Rosette diagonal UBK-2 250/150",
    label_no: "Rosettdiagonal UBK-2 250/150",
    material: "",
    length: 2.5,
    weight: 6.19,
    width: 0,
    article_id: "133451"
  },
  {
    label_se: "Rosettdiagonal UBK-2 250/200",
    label_en: "Rosette diagonal UBK-2 250/200",
    label_no: "Rosettdiagonal UBK-2 250/200",
    material: "",
    length: 2.5,
    weight: 6.72,
    width: 0,
    article_id: "133454"
  },
  {
    label_se: "Rosettdiagonal UBK-2 300/100",
    label_en: "Rosette diagonal UBK-2 300/100",
    label_no: "Rosettdiagonal UBK-2 300/100",
    material: "",
    length: 3,
    weight: 6.6,
    width: 0,
    article_id: "133457"
  },
  {
    label_se: "Rosettdiagonal UBK-2 300/150",
    label_en: "Rosette diagonal UBK-2 300/150",
    label_no: "Rosettdiagonal UBK-2 300/150",
    material: "",
    length: 3,
    weight: 6.96,
    width: 0,
    article_id: "133460"
  },
  {
    label_se: "Rosettdiagonal UBK-2 300/200",
    label_en: "Rosette diagonal UBK-2 300/200",
    label_no: "Rosettdiagonal UBK-2 300/200",
    material: "",
    length: 3,
    weight: 7.42,
    width: 0,
    article_id: "133463"
  }
];

export const componentsStairs: Component[] = [
  {
    article_id: "111117",
    label_en: "Platform stairway UAS 75 x 250/200",
    label_no: "Alu-trapp UAS 75 x 250/200",
    label_se: "Alu-trappa UAS 75 x 250/200",
    length: 2.5,
    material: "",
    weight: 28,
    width: 0.75
  },
  {
    article_id: "111124",
    label_en: "Platform stairway UAS 75 x 300/200",
    label_no: "Alu-trapp UAS 75 x 300/200",
    label_se: "Alu-trappa UAS 75 x 300/200",
    length: 3,
    material: "",
    weight: 32.9,
    width: 0.75
  },
  {
    article_id: "113228",
    label_en: "Platform stairway UAS 75 x 75/50",
    label_no: "Alu-trapp UAS 75 x 75/50",
    label_se: "Alu-trappa UAS 75 x 75/50",
    length: 0.75,
    material: "",
    weight: 10.1,
    width: 0.75
  },
  {
    article_id: "111087",
    label_en: "Platform stairway UAS 75 x 150/50 T",
    label_no: "Alu-trapp UAS 75 x 150/50 T",
    label_se: "Alu-trappa UAS 75 x 150/50 T",
    length: 1.5,
    material: "",
    weight: 17.5,
    width: 0.75
  },
  {
    article_id: "111095",
    label_en: "Platform stairway UAS 75 x 150/50 S",
    label_no: "Alu-trapp UAS 75 x 150/50 S",
    label_se: "Alu-trappa UAS 75 x 150/50 S",
    length: 1.5,
    material: "",
    weight: 17.5,
    width: 0.75
  },
  {
    article_id: "111103",
    label_en: "Platform stairway UAS 75 x 150/100 S",
    label_no: "Alu-trapp UAS 75 x 150/100 S",
    label_se: "Alu-trappa UAS 75 x 150/100 S",
    length: 1.5,
    material: "",
    weight: 17.9,
    width: 0.75
  },
  {
    article_id: "114536",
    label_en: "Platform stairway UAS 75 x 150/100 S",
    label_no: "Alu-trapp UAS 75 x 150/100 S",
    label_se: "Alu-trappa UAS 75 x 150/100 S",
    length: 1.5,
    material: "",
    weight: 17.9,
    width: 0.75
  }
];

export const componentsStairwayGuardrails: Component[] = [
  {
    article_id: "100742",
    label_en: "Stairway guardrail UAG",
    label_no: "Trapperekkverk UAG",
    label_se: "Trappräcke UAG",
    length: 2.5,
    material: "",
    weight: 10,
    width: 0
  }
];

export const componentsStairwayGuardrailsWideInner: Component[] = [
  {
    article_id: "100830",
    label_en: "Stairway inner UAH",
    label_no: "Trapperekkverk UAH",
    label_se: "Trappräcke UAH",
    length: 2.5,
    material: "",
    weight: 4.97,
    width: 0
  }
];

export const componentsConsoles: Component[] = [
  {
    article_id: "115959",
    label_en: "Console UC 25",
    label_no: "Konsol UC 25",
    label_se: "Konsol UC 25",
    length: 0,
    material: "",
    weight: 1.15,
    width: 0.25
  },
  {
    article_id: "115962",
    label_en: "Console UC 37,5",
    label_no: "Konsol UC 37,5",
    label_se: "Konsol UC 37,5",
    length: 0,
    material: "",
    weight: 1.48,
    width: 0.375
  },
  {
    article_id: "110483",
    label_en: "Console UCM 50-2",
    label_no: "Konsol UCM 50-2",
    label_se: "Konsol UCM 50-2",
    length: 0,
    material: "",
    weight: 4.48,
    width: 0.5
  },
  {
    article_id: "111128",
    label_en: "Console UCM 75-2",
    label_no: "Konsol UCM 75-2",
    label_se: "Konsol UCM 75-2",
    length: 0,
    material: "",
    weight: 5.72,
    width: 0.75
  },
  {
    article_id: "112676",
    label_en: "Console UCM 50 with pin",
    label_no: "Konsol UCM 50 med tapp",
    label_se: "Konsol UCM 50 med tapp",
    length: 0,
    material: "",
    weight: 5.27,
    width: 0.5
  },
  {
    article_id: "112678",
    label_en: "Console UCM 75 with pin",
    label_no: "Konsol UCM 75 med tapp",
    label_se: "Konsol UCM 75 med tapp",
    length: 0,
    material: "",
    weight: 6.51,
    width: 0.75
  },
  {
    article_id: "112690",
    label_en: "Console UCM 50 with half rosette",
    label_no: "Konsol UCM 50 med halv rosett",
    label_se: "Konsol UCM 50 med halv rosett",
    length: 0,
    material: "",
    weight: 4.38,
    width: 0.5
  },
  {
    article_id: "112693",
    label_en: "Console UCM 75 with half rosette",
    label_no: "Konsol UCM 75 med halv rosett",
    label_se: "Konsol UCM 75 med halv rosett",
    length: 0,
    material: "",
    weight: 5.62,
    width: 0.75
  }
];

export const componentsBaseCollars: Component[] = [
  {
    article_id: "100014",
    label_en: "Base collar UVB 24, galv",
    label_no: "Bunnskrue UVB 24, galv",
    label_se: "Bottenspira UVB 24, galv",
    length: 0.47,
    material: "",
    weight: 2.47,
    width: 0
  },
  {
    article_id: "117194",
    label_en: "Base collar UVB 49, galv",
    label_no: "Bunnskrue UVB 49, galv",
    label_se: "Bottenspira UVB 49, galv",
    length: 0.72,
    material: "",
    weight: 3.98,
    width: 0
  }
];

export const componentsAnchors: Component[] = [
  {
    article_id: "100088",
    label_en: "Wall tie UWT 45",
    label_no: "Rörforankring UWT 45",
    label_se: "Förankringsrör UWT 45",
    length: 0,
    material: "",
    weight: 1,
    width: 0
  },
  {
    article_id: "100091",
    label_en: "Wall tie UWT 110",
    label_no: "Rörforankring UWT 110",
    label_se: "Förankringsrör UWT 110",
    length: 0,
    material: "",
    weight: 4.68,
    width: 0
  },
  {
    article_id: "100093",
    label_en: "Wall tie UWT 140",
    label_no: "Rörforankring UWT 140",
    label_se: "Förankringsrör UWT 140",
    length: 0,
    material: "",
    weight: 5.87,
    width: 0
  },
  {
    article_id: "102951",
    label_en: "Wall tie UWT 170",
    label_no: "Rörforankring UWT 170",
    label_se: "Förankringsrör UWT 170",
    length: 0,
    material: "",
    weight: 7.06,
    width: 0
  },
  {
    article_id: "102954",
    label_en: "Wall tie UWT 220",
    label_no: "Rörforankring UWT 220",
    label_se: "Förankringsrör UWT 220",
    length: 0,
    material: "",
    weight: 9.05,
    width: 0
  },
  {
    article_id: "102957",
    label_en: "Wall tie UWT 270",
    label_no: "Rörforankring UWT 270",
    label_se: "Förankringsrör UWT 270",
    length: 0,
    material: "",
    weight: 11,
    width: 0
  }
];

export const componentsBaseplates: Component[] = [
  {
    article_id: "116762",
    label_en: "Base plate UJB 38-36/17, galv",
    label_no: "Bunnskrue UJB 38-36/17, galv",
    label_se: "Bottenskruv UJB 38-36/17, galv",
    length: 0.275,
    material: "",
    weight: 2.87,
    width: 0
  },
  {
    article_id: "100411",
    label_en: "Base plate UJB 38-50/30, galv",
    label_no: "Bunnskrue UJB 38-50/30, galv",
    label_se: "Bottenskruv UJB 38-50/30, galv",
    length: 0.505,
    material: "",
    weight: 3.42,
    width: 0
  },
  {
    article_id: "100242",
    label_en: "Base plate UJB 38-80/55, galv",
    label_no: "Bunnskrue UJB 38-80/55, galv",
    label_se: "Bottenskruv UJB 38-80/55, galv",
    length: 0.805,
    material: "",
    weight: 4.61,
    width: 0
  },
  {
    article_id: "100159",
    label_en: "Base plate steerable  UJS 38-80/50",
    label_no: "Bunnskrue styrbar UJS 38-80/50",
    label_se: "Bottenskruv ledbar UJS 38-80/50",
    length: 0.823,
    material: "",
    weight: 4.94,
    width: 0
  }
];
