import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import { componentsDecks } from "../components";
import { calculateOptimalSegmentLengthCombination } from "math/optimizers";

export const generateOptimalPlankConfiguration = (props: {
  length: number;
  width: number;
  plankType?: SCAFFOLD_PLANKTYPE;
}) => {
  const { length, width, plankType } = props;
  if (!plankType) return [];
  const decks = componentsDecks.filter((deck) => deck.material === plankType);

  const correctLengthDecks = decks.filter((deck) => deck.length === length);

  if (correctLengthDecks.length === 0) {
    return [];
  }

  const uniqueWidths = [
    ...new Set(correctLengthDecks.map((deck) => deck.width))
  ];

  return calculateOptimalSegmentLengthCombination({
    lengthSegments: uniqueWidths,
    targetLength: width
  });
};
