import { halfPi } from "math/constants";
import { minus, plus } from "math";
import {
  Anchor,
  Box,
  BoxFrame,
  DiagonalBrace,
  Ledger,
  Plank,
  Standard,
  ToeBoard,
  Console,
  Stairway,
  StairwayGuardRail,
  StairwayInnerGuardRail,
  BaseBoard,
  BasePlate,
  BeamSpigot
} from "shared/interfaces/firestore";
import useStoreWithUndo from "store/store";
import {
  getSameStartPosAndRotLedgers,
  getSameXZPosStandards
} from "store/world/box/box.utils";
import {
  getBoxesConnectedToNode,
  isComponentPartOfOtherBox,
  toVectorStringRepresentation
} from "store/world/world.utils";
import { ReplacedComponent } from "suppliers/scaffold/scaffold.interface";
import { Vector3, Vector3Tuple } from "three";
import {
  componentsAnchors,
  componentsBaseplates,
  componentsConsoles,
  componentsDecks,
  componentsDiagonalBraces,
  componentsGuardRails,
  componentsOLedgers,
  componentsStairs,
  componentsStairwayGuardrails,
  componentsStairwayGuardrailsWideInner,
  componentsStandards,
  componentsToeBoards,
  componentsULedgers
} from "../components";
import { genId } from "math/generators";
import {
  SCAFFOLD_DIAGONAL_PATTERN,
  SCAFFOLD_PLANKTYPE,
  SCAFFOLD_SUPPLIER
} from "shared/enums/scaffold";
import {
  getEndPointFromStartPoint,
  isPointOnLineLimited,
  roundVector
} from "math/vectors";
import {
  BASE_BOARD_HEIGHT,
  BASE_BOARD_LENGTH,
  DEFAULT_FALL_PROTECTION_HEIGHT,
  DEFAULT_STAIR_HEIGHT,
  TOE_BOARD_OFFSET
} from "suppliers/scaffold/constants";
import { generateOptimalPlankConfiguration } from "./utils";
import {
  calcCenterPositionsAlongLine,
  getBoxStandardTopHeightFromXZPosition,
  splitLedgers,
  splitStandards
} from "suppliers/scaffold/scaffold.utils";
import { half } from "math";
import { GuardRail } from "world/core/GuardRail/GuardRail.types";
import { LEDGER_VARIANT } from "world/manager/LedgerManager/LedgerManager.enums";

export const generateStandards = (props: {
  standardLengths: {
    pos: Vector3;
    standardLength: {
      partitions: number[];
      remainingLength: number;
    };
  }[];
  boxRotation: Box["rotation"];
  boxId: string;
  allBoxes?: Box[];
  standardsToCheck?: Standard[];
}) => {
  const { standardLengths, boxRotation, boxId, allBoxes, standardsToCheck } =
    props;

  /** Creation of standards */
  const standards: Standard[] = [];
  const replacedStandards: ReplacedComponent[] = [];
  const standardRotation = [
    boxRotation[0],
    boxRotation[1],
    plus(boxRotation[2], halfPi)
  ] as Vector3Tuple;

  standardLengths.forEach(({ pos, standardLength }) => {
    const sameXZStateStandards = getSameXZPosStandards({ x: pos.x, z: pos.z });

    const sameXZStandardsToCheck = getSameXZPosStandards({
      x: pos.x,
      z: pos.z,
      standards: standardsToCheck
    });
    const oldStandardSplits: number[] = [];

    /** Guard against standards already existing in state */
    if (sameXZStateStandards.length > 0) {
      const { boxes, graph } = useStoreWithUndo.getState();
      const connectedBoxes = getBoxesConnectedToNode({
        graph,
        pos: toVectorStringRepresentation(sameXZStateStandards[0].position),
        boxes: allBoxes ?? boxes,
        excludeBoxIds: [boxId]
      });

      const currStandardsLength = standardLength.partitions.reduce(
        (a, b) => a + b
      );

      const maxConnectedBoxesTopHeight = Math.max(
        ...connectedBoxes.map((box) =>
          getBoxStandardTopHeightFromXZPosition({
            box,
            xzPosition: [pos.x, pos.z]
          })
        )
      );

      if (
        connectedBoxes.length > 0 &&
        maxConnectedBoxesTopHeight > currStandardsLength
      ) {
        /** Here we should makes sure that state is updated correctly and that graph is as well */
        standards.push(
          ...sameXZStateStandards.map((standard) => ({
            ...standard,
            id: genId()
          }))
        );
        replacedStandards.push(
          ...sameXZStateStandards.map((standard) => ({
            id: standard.id,
            replacedById: ""
          }))
        );
      } else {
        /** Remove previous standards but keep their partitions, add new standards with old and new partitions.
         *  Add new standards to previous boxes */
        sameXZStateStandards.forEach((standard) => {
          standard.splits?.forEach((split) => {
            oldStandardSplits.push(plus(split, standard.position[1]));
          });
        });

        replacedStandards.push(
          ...sameXZStateStandards.map((standard) => ({
            id: standard.id,
            replacedById: ""
          }))
        );
      }
    }
    /** Guard agains standards being generated by drag action but not yet in state */
    if (sameXZStandardsToCheck.length > 0) {
      /** Remove previous standards but keep their partitions, add new standards with old and new partitions.
       *  Add new standards to previous boxes */
      sameXZStandardsToCheck.forEach((standard) => {
        standard.splits?.forEach((split) => {
          oldStandardSplits.push(plus(split, standard.position[1]));
        });
      });

      replacedStandards.push(
        ...sameXZStandardsToCheck.map((standard) => ({
          id: standard.id,
          replacedById: ""
        }))
      );
    }

    let currentYPos = plus(pos.y, standardLength.remainingLength);

    standardLength.partitions.forEach((length, idx) => {
      const matchingStandard = componentsStandards.find(
        (standard) => standard.length === length
      );
      if (matchingStandard) {
        const isLastStandard = idx === standardLength.partitions.length - 1;
        const standard: Standard = {
          id: genId(),
          position: [pos.x, currentYPos, pos.z],
          length,
          rotation: standardRotation,
          noSpigot: isLastStandard,
          supplier: SCAFFOLD_SUPPLIER.HAKI,
          componentId: matchingStandard.article_id
        };

        const standardStart = pos.clone().setY(currentYPos);
        const standardEnd = pos.clone().setY(plus(currentYPos, length));
        oldStandardSplits.forEach((splitHeight) => {
          const splitPos = pos.clone().setY(splitHeight);

          const isPrevSplitOnStandard = isPointOnLineLimited({
            pointA: standardStart,
            pointB: standardEnd,
            pointToCheck: splitPos
          });

          if (isPrevSplitOnStandard) {
            const distance = standardStart.distanceTo(splitPos);
            if (standard.splits) {
              if (!standard.splits.includes(distance)) {
                standard.splits.push(distance);
              }
            } else {
              standard.splits = [distance];
            }
          }
        });

        standards.push(standard);
      }
      currentYPos = plus(currentYPos, length);
    });
  });
  return { standards, replacedStandards };
};

export const generateFrameLedgers = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frames: BoxFrame[];
  standardsToSplit: Standard[];
  skipBottomLedgers?: boolean;
  boxId: string;
}) => {
  const {
    standardPositions,
    boxRotation,
    depth,
    width,
    frames,
    standardsToSplit,
    skipBottomLedgers,
    boxId
  } = props;

  /** Creation of longitudinal ledgers
   * - Builds from the back -> front
   */
  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const topYPos = Math.max(BR.y, BL.y, FR.y, FL.y);

  const ledgers: Ledger[] = [];
  const replacedLedgers: ReplacedComponent[] = [];

  const bayLengthDirectionRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;

  const uLedger = componentsULedgers.find((ledger) => ledger.length === width);
  const oLedger = componentsOLedgers.find((ledger) => ledger.length === depth);

  const ledgersData = [
    {
      start: BR,
      end: FR,
      rotation: bayLengthDirectionRotation,
      component: oLedger,
      variant: LEDGER_VARIANT.LBL_LEDGER
    },
    {
      start: BL,
      end: FL,
      rotation: bayLengthDirectionRotation,
      component: oLedger,
      variant: LEDGER_VARIANT.LBL_LEDGER
    },
    { start: FR, end: FL, rotation: boxRotation, component: uLedger },
    { start: BR, end: BL, rotation: boxRotation, component: uLedger }
  ];

  ledgersData.forEach(({ start, end, rotation, component, variant }) => {
    if (!component) return;
    const startPosition = start.toArray();
    const endPosition = end.toArray();
    let oldLedgerSplits: number[] = [];

    const ledgerLevels = [topYPos + 0.4, ...frames.map(({ height }) => height)];
    if (skipBottomLedgers) {
      ledgerLevels.shift();
    }

    ledgerLevels.forEach((height) => {
      const yPos = height;
      startPosition[1] = yPos;
      endPosition[1] = yPos;
      const newLedgerId = genId();
      oldLedgerSplits = [];
      const sameStartPosAndRotLedgers = getSameStartPosAndRotLedgers({
        position: startPosition,
        rotation
      });
      if (sameStartPosAndRotLedgers.length > 0) {
        const { graph } = useStoreWithUndo.getState();
        const sameStartPosLedger = sameStartPosAndRotLedgers[0];
        const isLedgerConnectedToOtherBox = isComponentPartOfOtherBox({
          graph,
          component: sameStartPosLedger,
          excludeBoxIds: [boxId]
        });

        /** Keep previous ledger  */
        if (
          sameStartPosLedger.length > component.length &&
          isLedgerConnectedToOtherBox
        ) {
          // important that we clone the ledger to avoid mutation
          ledgers.push({
            ...sameStartPosLedger,
            ...(sameStartPosLedger.splits && {
              splits: [...sameStartPosLedger.splits]
            }),
            id: genId()
          });
          replacedLedgers.push({
            id: sameStartPosLedger.id,
            replacedById: sameStartPosLedger.id
          });
          return;
          /** Remove previous ledger but keep their splits */
        } else {
          if (sameStartPosLedger.splits)
            oldLedgerSplits.push(...sameStartPosLedger.splits);
          replacedLedgers.push({
            id: sameStartPosLedger.id,
            replacedById: newLedgerId
          });
        }
      }
      ledgers.push({
        id: newLedgerId,
        position: [...startPosition],
        endPosition: [...endPosition],
        length: component.length,
        splits: oldLedgerSplits,
        rotation,
        supplier: SCAFFOLD_SUPPLIER.HAKI,
        componentId: component.article_id,
        ...(variant && { variant })
      });
    });
  });

  /** Split standards with end and start positions*/
  splitStandards({
    standardsToSplit,
    positions: ledgers
      .map((ledger) => [ledger.position, ledger.endPosition ?? [0, 0, 0]])
      .flat()
  });

  return { ledgers, replacedLedgers };
};

export const generateGuardRails = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frames: BoxFrame[];
  fallProtectionHeight: number;
  guardRailsOptions: [boolean, boolean, boolean, boolean];
  standardsToSplit: Standard[];
}) => {
  const {
    boxRotation,
    depth,
    width,
    frames,
    fallProtectionHeight,
    guardRailsOptions,
    standardPositions,
    standardsToSplit
  } = props;

  /** Creation of longitudinal ledgers
   * - Builds from the back -> front
   */
  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const guardRails: GuardRail[] = [];
  const ledgers: Ledger[] = [];

  /** Longitudinal Guard rails */
  const longitudinalGuardRailRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;

  const shortGuardRail = componentsGuardRails.find(
    (ledger) => ledger.length === width
  );
  const longGuardRail = componentsGuardRails.find(
    (ledger) => ledger.length === depth
  );
  const shortLedger = componentsOLedgers.find(
    (ledger) => ledger.length === width
  );
  const longLedger = componentsOLedgers.find(
    (ledger) => ledger.length === depth
  );

  const guardRailData = [
    ...(guardRailsOptions[1]
      ? [
          {
            start: BR,
            end: FR,
            component: longGuardRail,
            ledgerComponent: longLedger,
            rotation: longitudinalGuardRailRotation
          }
        ]
      : []),
    ...(guardRailsOptions[3]
      ? [
          {
            start: BL,
            end: FL,
            component: longGuardRail,
            ledgerComponent: longLedger,
            rotation: longitudinalGuardRailRotation
          }
        ]
      : []),
    ...(guardRailsOptions[0]
      ? [
          {
            start: FR,
            end: FL,
            component: shortGuardRail,
            ledgerComponent: shortLedger,
            rotation: boxRotation
          }
        ]
      : []),
    ...(guardRailsOptions[2]
      ? [
          {
            start: BR,
            end: BL,
            component: shortGuardRail,
            ledgerComponent: shortLedger,
            rotation: boxRotation
          }
        ]
      : [])
  ];

  guardRailData.forEach(
    ({ start, end, rotation, component, ledgerComponent }) => {
      if (!component) return;
      frames.forEach(({ platform }, idx) => {
        if (!platform) return;
        const topStartPosition = start.toArray();
        const topEndPosition = end.toArray();
        const bottomStartPosition = start.toArray();
        const bottomEndPosition = end.toArray();

        if (idx === 0) {
          let guardRailHeight = fallProtectionHeight;

          /** Add top ledger first then regular guard rails */
          if (fallProtectionHeight % 1 !== 0 && ledgerComponent) {
            const yPos = plus(platform, guardRailHeight);
            topStartPosition[1] = yPos;
            topEndPosition[1] = yPos;
            const guardRailLedger: Ledger = {
              id: genId(),
              position: [...topStartPosition],
              endPosition: [...topEndPosition],
              length: ledgerComponent.length,
              rotation,
              supplier: SCAFFOLD_SUPPLIER.HAKI,
              componentId: ledgerComponent.article_id
            };
            ledgers.push(guardRailLedger);
            guardRailHeight = minus(guardRailHeight, 0.5);
          }
          for (let i = guardRailHeight; i > 0; i -= 1) {
            const yPos = plus(platform, i);
            const bottomYPos = minus(yPos, 0.5);
            topStartPosition[1] = yPos;
            topEndPosition[1] = yPos;
            bottomStartPosition[1] = bottomYPos;
            bottomEndPosition[1] = bottomYPos;

            const guardRail: GuardRail = {
              id: genId(),
              position: [...topStartPosition],
              top: {
                pos: [...topStartPosition],
                endPos: [...topEndPosition],
                splits: []
              },
              bottom: {
                pos: [...bottomStartPosition],
                endPos: [...bottomEndPosition],
                splits: []
              },
              length: component.length,
              rotation,
              supplier: SCAFFOLD_SUPPLIER.HAKI,
              componentId: component.article_id,
              ...(component.variant && { variant: component.variant })
            };
            guardRails.push(guardRail);
          }
        } else {
          const yPos = plus(platform, DEFAULT_FALL_PROTECTION_HEIGHT);
          const bottomYPos = minus(yPos, 0.5);
          topStartPosition[1] = yPos;
          topEndPosition[1] = yPos;
          bottomStartPosition[1] = bottomYPos;
          bottomEndPosition[1] = bottomYPos;

          const guardRail: GuardRail = {
            id: genId(),
            position: topStartPosition,
            top: {
              pos: topStartPosition,
              endPos: topEndPosition,
              splits: []
            },
            bottom: {
              pos: bottomStartPosition,
              endPos: bottomEndPosition,
              splits: []
            },
            length: component.length,
            rotation,
            supplier: SCAFFOLD_SUPPLIER.HAKI,
            componentId: component.article_id,
            ...(component.variant && { variant: component.variant })
          };
          guardRails.push(guardRail);
        }
      });
    }
  );

  /** Split standards with end and start positions*/
  splitStandards({
    standardsToSplit,
    positions: [
      ...guardRails
        .map((guardRail) => [guardRail.top.pos, guardRail.top.endPos])
        .flat(),
      ...ledgers
        .map((ledger) => [ledger.position, ledger.endPosition ?? [0, 0, 0]])
        .flat()
    ]
  });
  return { guardRails, ledgers };
};

export const generateToeBoards = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frames: BoxFrame[];
  guardRailsOptions: [boolean, boolean, boolean, boolean];
}) => {
  const {
    boxRotation,
    depth,
    width,
    guardRailsOptions,
    standardPositions,
    frames
  } = props;

  const toeBoards: ToeBoard[] = [];

  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];

  const longitudinalRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;
  const transverseRotation = boxRotation;

  const longitudinalToeBoard = componentsToeBoards.find(
    (component) => component.length === depth
  );
  const transverseToeBoard = componentsToeBoards.find(
    (component) => component.length === width
  );

  const toeBoardData = [
    ...(guardRailsOptions[1]
      ? [
          {
            position: BR,
            rotation: longitudinalRotation,
            component: longitudinalToeBoard
          }
        ]
      : []),
    ...(guardRailsOptions[3]
      ? [
          {
            position: BL,
            rotation: longitudinalRotation,
            component: longitudinalToeBoard
          }
        ]
      : []),
    ...(guardRailsOptions[0]
      ? [
          {
            position: FR,
            rotation: transverseRotation,
            component: transverseToeBoard
          }
        ]
      : []),
    ...(guardRailsOptions[2]
      ? [
          {
            position: BR,
            rotation: transverseRotation,
            component: transverseToeBoard
          }
        ]
      : [])
  ];

  toeBoardData.forEach(({ position, rotation, component }) => {
    if (!component) return;
    const pos = position.clone();
    frames.forEach(({ platform }) => {
      if (!platform) return;
      const toeBoard: ToeBoard = {
        id: genId(),
        position: pos.setY(plus(platform, TOE_BOARD_OFFSET)).toArray(),
        width: component.width,
        length: component.length,
        rotation,
        supplier: SCAFFOLD_SUPPLIER.HAKI,
        componentId: component.article_id
      };

      toeBoards.push(toeBoard);
    });
  });

  return toeBoards;
};

export const generateDiagonalBraces = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frames: BoxFrame[];
  diagonalBraceOptions: [boolean, boolean, boolean, boolean];
  standardsToSplit: Standard[];
  diagonalPattern?: SCAFFOLD_DIAGONAL_PATTERN;
}) => {
  const {
    boxRotation,
    depth,
    width,
    frames,
    diagonalPattern,
    diagonalBraceOptions,
    standardPositions,
    standardsToSplit
  } = props;

  /** Creation of longitudinal ledgers
   * - Builds from the back -> front
   */
  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const diagonalBraces: DiagonalBrace[] = [];
  if (!diagonalPattern) return diagonalBraces;

  const longDiagonalRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;
  const shortDiagonalRotation = boxRotation;

  const shortDiagonalBrace = componentsDiagonalBraces.find(
    (diagonaBrace) => diagonaBrace.length === width
  );
  const longDiagonalBrace = componentsDiagonalBraces.find(
    (ledger) => ledger.length === depth
  );

  const diagonalBraceData = [
    ...(diagonalBraceOptions[1]
      ? [
          {
            start: BR,
            end: FR,
            rotation: longDiagonalRotation,
            component: longDiagonalBrace
          }
        ]
      : []),
    ...(diagonalBraceOptions[3]
      ? [
          {
            start: BL,
            end: FL,
            rotation: longDiagonalRotation,
            component: longDiagonalBrace
          }
        ]
      : []),
    ...(diagonalBraceOptions[0]
      ? [
          {
            start: FR,
            end: FL,
            rotation: shortDiagonalRotation,
            component: shortDiagonalBrace
          }
        ]
      : []),
    ...(diagonalBraceOptions[2]
      ? [
          {
            start: BR,
            end: BL,
            rotation: shortDiagonalRotation,
            component: shortDiagonalBrace
          }
        ]
      : [])
  ];

  diagonalBraceData.forEach(({ start, end, rotation, component }) => {
    frames.forEach(({ height }, idx) => {
      if (!component) return;
      if (height < plus(2, standardPositions[0].y, 0.4)) return;

      const startPosition = start.clone().setY(height);
      const endPosition = end.clone().setY(height);

      switch (diagonalPattern) {
        case SCAFFOLD_DIAGONAL_PATTERN.CROSSDIAGONAL:
          diagonalBraces.push(
            generateDiagonalBrace({
              startPosition,
              endPosition,
              length: component.length,
              articleId: component.article_id,
              rotation,
              flipped: true
            })
          );
          diagonalBraces.push(
            generateDiagonalBrace({
              startPosition,
              endPosition,
              length: component.length,
              articleId: component.article_id,
              rotation
            })
          );
          break;
        case SCAFFOLD_DIAGONAL_PATTERN.EVERYOTHER:
          diagonalBraces.push(
            generateDiagonalBrace({
              startPosition,
              endPosition,
              length: component.length,
              articleId: component.article_id,
              rotation,
              flipped: idx % 2 === 0
            })
          );
          break;

        case SCAFFOLD_DIAGONAL_PATTERN.EVERYOTHERREVERSE:
          diagonalBraces.push(
            generateDiagonalBrace({
              startPosition,
              endPosition,
              length: component.length,
              articleId: component.article_id,
              rotation,
              flipped: idx % 2 !== 0
            })
          );
          break;
        case SCAFFOLD_DIAGONAL_PATTERN.INWARDDIAGONAL:
          diagonalBraces.push(
            generateDiagonalBrace({
              startPosition,
              endPosition,
              length: component.length,
              articleId: component.article_id,
              rotation,
              flipped: true
            })
          );
          break;
        case SCAFFOLD_DIAGONAL_PATTERN.OUTWARDDIAGONAL:
        default:
          diagonalBraces.push(
            generateDiagonalBrace({
              startPosition,
              endPosition,
              length: component.length,
              articleId: component.article_id,
              rotation
            })
          );
      }
    });
  });

  /** Split standards with end and start positions*/
  splitStandards({
    standardsToSplit,
    positions: diagonalBraces
      .map((diagonalBrace) => [
        diagonalBrace.position,
        diagonalBrace.endPosition ?? [0, 0, 0]
      ])
      .flat()
  });

  return diagonalBraces;
};

export const generateDiagonalBrace = (props: {
  startPosition: Vector3;
  endPosition: Vector3;
  flipped?: boolean;
  length: number;
  rotation: Box["rotation"];
  articleId: string;
}) => {
  const { length, rotation, articleId, flipped } = props;

  let endPosition = flipped
    ? props.startPosition.clone()
    : props.endPosition.clone();
  let startPosition = flipped
    ? props.endPosition.clone()
    : props.startPosition.clone();
  startPosition.setY(minus(startPosition.y, 2));

  const diagonalBraceAngle = Math.atan(2 / length);

  const diagonalBraceRotation = [
    rotation[0],
    flipped ? plus(rotation[1], Math.PI) : rotation[1],
    plus(rotation[2], diagonalBraceAngle)
  ];

  const diagonalBrace: DiagonalBrace = {
    id: genId(),
    position: startPosition.toArray(),
    endPosition: endPosition.toArray(),
    length: startPosition.distanceTo(endPosition),
    rotation: diagonalBraceRotation as Vector3Tuple,
    supplier: SCAFFOLD_SUPPLIER.HAKI,
    componentId: articleId
  };

  return diagonalBrace;
};

const ANCHORS_HEIGTH_OFFSET = -0.4;
export const generateFrameAnchors = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  anchorOptions: [boolean, boolean, boolean, boolean];
  boxRotation: Box["rotation"];
  frames: BoxFrame[];
  standardsToSplit: Standard[];
}) => {
  const {
    standardPositions,
    boxRotation,
    frames,
    standardsToSplit,
    anchorOptions
  } = props;

  /** Creation of longitudinal ledgers
   * - Builds from the back -> front
   */
  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const anchors: Anchor[] = [];

  const anchorSides: {
    left: Vector3;
    right: Vector3;
    rotation: Vector3Tuple;
  }[] = [
    ...(anchorOptions[1]
      ? [
          {
            left: FR,
            right: BR,
            rotation: [
              boxRotation[0],
              plus(boxRotation[1], Math.PI),
              boxRotation[2]
            ] as Vector3Tuple
          }
        ]
      : []),
    ...(anchorOptions[3]
      ? [
          {
            left: FL,
            right: BL,
            rotation: boxRotation
          }
        ]
      : []),
    ...(anchorOptions[0]
      ? [
          {
            left: FL,
            right: FR,
            rotation: [
              boxRotation[0],
              minus(boxRotation[1], halfPi),
              boxRotation[2]
            ] as Vector3Tuple
          }
        ]
      : []),
    ...(anchorOptions[2]
      ? [
          {
            left: BL,
            right: BR,
            rotation: [
              boxRotation[0],
              plus(boxRotation[1], halfPi),
              boxRotation[2]
            ] as Vector3Tuple
          }
        ]
      : [])
  ];

  const matchingAnchor = componentsAnchors[0];
  if (matchingAnchor) {
    anchorSides.forEach(({ left, right, rotation }) => {
      frames.forEach(({ height }) => {
        const leftStartPosition = [
          left.x,
          height + ANCHORS_HEIGTH_OFFSET,
          left.z
        ] as Vector3Tuple;
        const leftEndPosition = getEndPointFromStartPoint({
          startPosition: leftStartPosition,
          length: matchingAnchor.length,
          rotation
        });

        const rightStartPosition = [
          right.x,
          height + ANCHORS_HEIGTH_OFFSET,
          right.z
        ] as Vector3Tuple;
        const rightEndPosition = getEndPointFromStartPoint({
          startPosition: rightStartPosition,
          length: matchingAnchor.length,
          rotation
        });

        const leftAnchor: Anchor = {
          id: genId(),
          position: leftStartPosition,
          endPosition: leftEndPosition.toArray(),
          length: matchingAnchor.length,
          rotation: rotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.HAKI,
          componentId: matchingAnchor.article_id,
          grounded: true
        };
        const rightAnchor: Anchor = {
          id: genId(),
          position: rightStartPosition,
          endPosition: rightEndPosition.toArray(),
          length: matchingAnchor.length,
          rotation: rotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.HAKI,
          componentId: matchingAnchor.article_id,
          grounded: true
        };
        anchors.push(leftAnchor, rightAnchor);
      });
    });
  }

  /** Split standards with end and start positions*/
  splitStandards({
    standardsToSplit,
    positions: anchors
      .map((anchor) => [anchor.position, anchor.endPosition ?? [0, 0, 0]])
      .flat()
  });

  return anchors;
};

export const generateConsoleComponents = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frames: BoxFrame[];
  consoleWidth?: number;
  consoleOptions?: [boolean, boolean, boolean, boolean];
}) => {
  const {
    boxRotation,
    depth,
    width,
    frames,
    standardPositions,
    consoleWidth,
    consoleOptions
  } = props;

  const consoles: Console[] = [];
  const planks: Plank[] = [];

  /** Consoles or not */
  const [FC, RC, BC, LC] = consoleOptions ?? [false, false, false, false];

  if (!consoleWidth) return { consoles, planks };

  const consoleMatch = componentsConsoles.find(
    (component) => component.width === consoleWidth
  );

  if (!consoleMatch) return { consoles, planks };

  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const bayLengthDir = FR.clone()
    .setY(0)
    .sub(BR.clone().setY(0))
    .normalize()
    .multiplyScalar(consoleWidth);

  const bayWidthDir = BR.clone()
    .setY(0)
    .sub(BL.clone().setY(0))
    .normalize()
    .multiplyScalar(consoleWidth);

  const consoleSides: {
    left: Vector3;
    right: Vector3;
    rotation: Vector3Tuple;
    dirVector: Vector3;
    length: number;
  }[] = [
    ...(FC
      ? [
          {
            left: FL,
            right: FR,
            rotation: [
              boxRotation[0],
              minus(boxRotation[1], halfPi),
              boxRotation[2]
            ] as Vector3Tuple,
            dirVector: bayLengthDir.clone(),
            length: width
          }
        ]
      : []),
    ...(BC
      ? [
          {
            left: BR,
            right: BL,
            rotation: [
              boxRotation[0],
              plus(boxRotation[1], halfPi),
              boxRotation[2]
            ] as Vector3Tuple,
            dirVector: bayLengthDir.clone().multiplyScalar(-1),
            length: width
          }
        ]
      : []),
    ...(LC
      ? [
          {
            left: BL,
            right: FL,
            rotation: boxRotation,
            dirVector: bayWidthDir.clone().multiplyScalar(-1),
            length: depth
          }
        ]
      : []),
    ...(RC
      ? [
          {
            left: FR,
            right: BR,
            rotation: [
              boxRotation[0],
              plus(boxRotation[1], Math.PI),
              boxRotation[2]
            ] as Vector3Tuple,
            dirVector: bayWidthDir.clone(),
            length: depth
          }
        ]
      : [])
  ];

  consoleSides.forEach(({ left, right, rotation, dirVector, length }) => {
    frames.forEach(({ height, platform, plankingMaterial }) => {
      if (!platform) return;

      /** Consoles */
      [left, right].forEach((pos) => {
        const console: Console = {
          id: genId(),
          position: pos.clone().setY(height).toArray(),
          length: consoleMatch.width,
          endPosition: getEndPointFromStartPoint({
            startPosition: [pos.x, height, pos.z],
            length: consoleMatch.width,
            rotation
          }).toArray(),
          rotation: rotation as Vector3Tuple,
          supplier: SCAFFOLD_SUPPLIER.HAKI,
          componentId: consoleMatch.article_id
        };
        consoles.push(console);
      });

      /** Console plank */
      const consolePlankRotation = [
        rotation[0],
        minus(rotation[1], halfPi),
        rotation[2]
      ] as Vector3Tuple;
      const optimalPlankConfiguration = generateOptimalPlankConfiguration({
        length,
        width: consoleWidth,
        plankType: plankingMaterial
      });
      const consolePlankPositions = calcCenterPositionsAlongLine({
        start: left.clone().setY(0),
        end: left.clone().setY(0).add(dirVector),
        partitions: optimalPlankConfiguration,
        spacing: 0.01
      });
      consolePlankPositions.forEach((plankPosition, idx) => {
        const matchingPlank = componentsDecks.find(
          (component) =>
            component.length === length &&
            component.width === optimalPlankConfiguration[idx] &&
            component.material === plankingMaterial
        );
        if (matchingPlank) {
          const plank: Plank = {
            id: genId(),
            position: plankPosition.clone().setY(height).toArray(),
            length: length,
            endPosition: getEndPointFromStartPoint({
              startPosition: [plankPosition.x, height, plankPosition.z],
              length,
              rotation: consolePlankRotation
            }).toArray(),
            width: matchingPlank.width,
            rotation: consolePlankRotation,
            supplier: SCAFFOLD_SUPPLIER.HAKI,
            componentId: matchingPlank.article_id
          };
          planks.push(plank);
        }
      });
    });
  });

  // splitConsoles({
  //   consolesToSplit: consoles,
  //   positions: planks
  //     .map((plank) => [plank.position, plank.endPosition ?? [0, 0, 0]])
  //     .flat()
  // });

  return { consoles, planks };
};

export const generateStairComponents = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  frames: BoxFrame[];
  stairwayGuardRail?: boolean;
  stairwayInnerGuardRail?: boolean;
}) => {
  const {
    boxRotation,
    depth,
    frames,
    standardPositions,
    stairwayGuardRail,
    stairwayInnerGuardRail
  } = props;

  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FL = standardPositions[0];

  const mathingStairway = componentsStairs.find(
    (component) => component.length === depth
  );
  const stairWidth = mathingStairway ? mathingStairway.width : 0;

  /** Stairs */
  const dir = BR.clone().setY(0).sub(BL.clone().setY(0)).normalize();
  const stairways: Stairway[] = [];
  const stairwayGuardRails: StairwayGuardRail[] = [];
  const stairwayInnerGuardRails: StairwayInnerGuardRail[] = [];
  const bayLengthDir = FL.clone().setY(0).sub(BL.clone().setY(0)).normalize();

  const startVector = BR.clone()
    .setY(0)
    .sub(
      BR.clone()
        .setY(0)
        .sub(BL.clone().setY(0))
        .normalize()
        .multiplyScalar(stairWidth)
    );

  const stairPosition = calcCenterPositionsAlongLine({
    start: startVector,
    end: BR.clone().setY(0),
    partitions: [stairWidth],
    spacing: 0.01
  });

  const matchingStairwayInnerGuardRail =
    componentsStairwayGuardrailsWideInner.find(
      (component) => component.length === depth
    );
  const matchingStairwayGuardRail = componentsStairwayGuardrails.find(
    (component) => component.length === depth
  );

  if (!mathingStairway)
    return { stairways, stairwayInnerGuardRails, stairwayGuardRails };

  frames.forEach(({ height }) => {
    stairPosition.forEach((stairPosition) => {
      if (height < DEFAULT_STAIR_HEIGHT) return;
      const stairway: Stairway = {
        id: genId(),
        position: roundVector(
          stairPosition.clone().setY(height - DEFAULT_STAIR_HEIGHT)
        ).toArray(),
        length: depth,
        height: DEFAULT_STAIR_HEIGHT,
        width: mathingStairway.width,
        rotation: boxRotation,
        supplier: SCAFFOLD_SUPPLIER.HAKI,
        componentId: mathingStairway.article_id
      };
      stairways.push(stairway);

      if (matchingStairwayGuardRail && stairwayGuardRail) {
        const stairwayGuardRail: StairwayGuardRail = {
          id: genId(),
          position: roundVector(
            stairPosition
              .clone()
              .setY(height - DEFAULT_STAIR_HEIGHT + 0.5)
              .sub(dir.clone().multiplyScalar(half(mathingStairway.width)))
          ).toArray(),
          length: depth,
          height: DEFAULT_STAIR_HEIGHT,
          rotation: boxRotation,
          supplier: SCAFFOLD_SUPPLIER.HAKI,
          componentId: matchingStairwayGuardRail.article_id
        };
        stairwayGuardRails.push(stairwayGuardRail);
      }

      if (matchingStairwayInnerGuardRail && stairwayInnerGuardRail) {
        const stairwayInnerGuardRail: StairwayInnerGuardRail = {
          id: genId(),
          position: roundVector(
            stairPosition
              .clone()
              .add(dir.clone().multiplyScalar(half(mathingStairway.width)))
              .add(bayLengthDir.clone().multiplyScalar(half(depth)))
              .setY(height - half(DEFAULT_STAIR_HEIGHT + 0.5))
          ).toArray(),
          length: 0.7,
          angle: 45,
          rotation: boxRotation,
          supplier: SCAFFOLD_SUPPLIER.HAKI,
          componentId: matchingStairwayInnerGuardRail.article_id
        };
        stairwayInnerGuardRails.push(stairwayInnerGuardRail);
      }
    });
  });
  return { stairways, stairwayInnerGuardRails, stairwayGuardRails };
};

export const generateBaseComponents = (props: {
  standardLengths: {
    pos: Vector3;
    standardLength: {
      partitions: number[];
      remainingLength: number;
    };
  }[];
  boxRotation: Box["rotation"];
  boxOptions: Box["options"];
  rayHitsYPosition: [number, number, number, number];
}) => {
  const { standardLengths, boxRotation, boxOptions, rayHitsYPosition } = props;

  const baseBoards: BaseBoard[] = [];
  const basePlates: BasePlate[] = [];

  standardLengths.forEach(({ standardLength, pos }, index) => {
    if (!boxOptions || !boxOptions.grounded) return;
    const grounded = boxOptions.grounded[index];

    if (!grounded || !grounded.isGrounded) return;

    const position = pos.clone().setY(rayHitsYPosition[index]);
    let latestStartPoint = pos
      .clone()
      .setY(plus(pos.y, standardLength.remainingLength));

    const basePlateRotation = [
      boxRotation[0],
      boxRotation[1],
      plus(boxRotation[2], halfPi)
    ] as Vector3Tuple;

    const baseBoardRotation = boxRotation;

    const baseBoard: BaseBoard = {
      id: genId(),
      position: position.toArray(),
      rotation: baseBoardRotation,
      length: BASE_BOARD_LENGTH,
      supplier: SCAFFOLD_SUPPLIER.HAKI,
      componentId: "baseBoard"
    };

    const matchingBasePlate = componentsBaseplates[0];
    if (matchingBasePlate) {
      const startPosition = position
        .clone()
        .setY(plus(position.y, BASE_BOARD_HEIGHT));
      const basePlate: BasePlate = {
        id: genId(),
        position: startPosition.toArray(),
        endPosition: latestStartPoint.clone().toArray(),
        rotation: basePlateRotation as Vector3Tuple,
        length: startPosition.clone().distanceTo(latestStartPoint),
        spindleNutHeight: startPosition.clone().distanceTo(latestStartPoint),
        supplier: SCAFFOLD_SUPPLIER.HAKI,
        componentId: matchingBasePlate.article_id,
        grounded: true
      };
      latestStartPoint = startPosition.clone();
      basePlates.push(basePlate);
    }

    baseBoards.push(baseBoard);
  });

  return {
    baseBoards,
    basePlates
  };
};

export const generateFrameDeckPlanks = (props: {
  standardPositions: [Vector3, Vector3, Vector3, Vector3];
  boxRotation: Box["rotation"];
  depth: Box["depth"];
  width: Box["width"];
  frames: BoxFrame[];
  stair?: boolean;
  ledgersToSplit: Ledger[];
}) => {
  const {
    boxRotation,
    depth,
    width,
    frames,
    standardPositions,
    stair,
    ledgersToSplit
  } = props;
  const plankSpacing = 0.01;

  const BR = standardPositions[2];
  const BL = standardPositions[3];
  const FR = standardPositions[1];
  const FL = standardPositions[0];

  const planks: Plank[] = [];

  const mathingStairway = componentsStairs.find(
    (component) => component.length === depth
  );
  const stairWidth = mathingStairway ? mathingStairway.width : 0;

  const plankRotation = [
    boxRotation[0],
    minus(boxRotation[1], halfPi),
    boxRotation[2]
  ] as Vector3Tuple;
  const startPlanksEndVector = stair
    ? BR.clone()
        .setY(0)
        .sub(
          BR.clone()
            .setY(0)
            .sub(BL.clone().setY(0))
            .normalize()
            .multiplyScalar(stairWidth)
        )
    : BR.clone().setY(0);

  const endPlanksEndVector = stair
    ? FR.clone()
        .setY(0)
        .sub(
          FR.clone()
            .setY(0)
            .sub(FL.clone().setY(0))
            .normalize()
            .multiplyScalar(stairWidth)
        )
    : FR.clone().setY(0);

  frames.forEach(({ platform, plankingMaterial }) => {
    if (!platform) return;

    const isPlankTransverse =
      plankingMaterial &&
      [
        SCAFFOLD_PLANKTYPE.DUCKBOARD,
        SCAFFOLD_PLANKTYPE.WOOD_DUCKBOARD,
        SCAFFOLD_PLANKTYPE.STEEL_DUCKBOARD,
        SCAFFOLD_PLANKTYPE.ALUMINIUM_DUCKBOARD,
        SCAFFOLD_PLANKTYPE.COMPOSITE_DUCKBOARD,
        SCAFFOLD_PLANKTYPE.WOOD_SOUTH_DUCKBOARD
      ].includes(plankingMaterial);

    const plankLength = isPlankTransverse ? width : depth;

    const optimalPlankConfiguration = generateOptimalPlankConfiguration({
      length: depth,
      width: minus(width, stair ? stairWidth : 0),
      plankType: plankingMaterial
    });

    const plankStartPositions = calcCenterPositionsAlongLine({
      start: isPlankTransverse ? BR.clone().setY(0) : BL.clone().setY(0),
      end: isPlankTransverse ? FR.clone().setY(0) : startPlanksEndVector,
      partitions: optimalPlankConfiguration,
      spacing: plankSpacing
    });
    const plankEndPositions = calcCenterPositionsAlongLine({
      start: FL.clone().setY(0),
      end: isPlankTransverse ? FL.clone().setY(0) : endPlanksEndVector,
      partitions: optimalPlankConfiguration,
      spacing: plankSpacing
    });

    plankStartPositions.forEach((plankPosition, idx) => {
      const plankStartPosition = plankPosition.clone().setY(platform);
      const plankEndPosition = plankEndPositions[idx].clone().setY(platform);

      const matchingPlank = componentsDecks.find(
        (component) =>
          component.length === plankLength &&
          component.width === optimalPlankConfiguration[idx] &&
          component.material === plankingMaterial
      );
      if (matchingPlank) {
        const plank: Plank = {
          id: genId(),
          position: plankStartPosition.toArray(),
          endPosition: plankEndPosition.toArray(),
          length: matchingPlank.length,
          width: matchingPlank.width,
          rotation: isPlankTransverse ? boxRotation : plankRotation,
          supplier: SCAFFOLD_SUPPLIER.HAKI,
          componentId: matchingPlank.article_id
        };
        planks.push(plank);
      }
    });
  });

  splitLedgers({
    ledgersToSplit,
    positions: planks
      .map((plank) => [plank.position, plank.endPosition ?? [0, 0, 0]])
      .flat()
  });

  return planks;
};

export const generatePassageComponents = (props: {
  baseStandardLengths: {
    pos: Vector3;
    standardLength: {
      partitions: number[];
      remainingLength: number;
    };
  }[];
  boxPosition: Box["position"];
  topOuterStandardPositions: [Vector3, Vector3];
  boxRotation: Box["rotation"];
  boxOptions: Box["options"];
  rayHitsYPosition: [number, number, number, number];
  baseWidth: number;
  depth: number;
}) => {
  const {
    baseStandardLengths,
    boxRotation,
    boxOptions,
    rayHitsYPosition,
    topOuterStandardPositions: topStandardPositions,
    baseWidth,
    depth,
    boxPosition
  } = props;

  const baseHeight = boxOptions?.baseHeight ?? 0;
  const standardPositions = baseStandardLengths.map((s) => s.pos.clone()) as [
    Vector3,
    Vector3,
    Vector3,
    Vector3
  ];

  const startHeight = boxPosition[1];

  const standards: Standard[] = [];
  const beamSpigots: BeamSpigot[] = [];
  /** Generate base passage. Standards, base plates, base boards, base collars, "riddare", ledgers (reinforced), guard rails */
  const { baseBoards, basePlates } = generateBaseComponents({
    standardLengths: baseStandardLengths,
    boxRotation,
    boxOptions,
    rayHitsYPosition
  });

  if (baseStandardLengths.length >= 4) {
    const outerStandardLengths = [
      baseStandardLengths[0],
      baseStandardLengths[3]
    ];
    const outerStandards = generateStandards({
      standardLengths: outerStandardLengths,
      boxRotation,
      boxId: ""
    });
    standards.push(...outerStandards.standards);
  }

  /** Generate reinforced base ledgers */
  const { ledgers, replacedLedgers } = generateFrameLedgers({
    standardPositions,
    boxRotation,
    depth,
    width: baseWidth,
    frames: [{ height: baseHeight + startHeight }],
    standardsToSplit: [],
    skipBottomLedgers: true,
    boxId: ""
  });

  /** Longitudinal Guard rails */
  const passageGuardRails = generateGuardRails({
    guardRailsOptions: [false, true, false, true],
    standardPositions,
    boxRotation,
    depth,
    width: baseWidth,
    fallProtectionHeight: 1,
    frames: [{ height: 1 + startHeight }],
    standardsToSplit: []
  });

  if (topStandardPositions.length >= 2) {
    const outerStandards = [
      topStandardPositions[0].clone().setY(boxPosition[1]),
      topStandardPositions[1].clone().setY(boxPosition[1])
    ] as [Vector3, Vector3];

    const beamSpigotsOuter = generateBeamSpigots({
      standardPositions: outerStandards,
      baseHeight: boxOptions?.baseHeight ?? 0,
      boxRotation,
      ledgersToSplit: ledgers
    });
    beamSpigots.push(...beamSpigotsOuter);
  }

  return {
    baseBoards,
    basePlates,
    standards,
    beamSpigots,
    ledgers: [...ledgers, ...passageGuardRails.ledgers],
    guardRails: passageGuardRails.guardRails
  };
};

export const generateBeamSpigots = (props: {
  standardPositions: [Vector3, Vector3];
  baseHeight: number;
  boxRotation: Box["rotation"];
  ledgersToSplit: Ledger[];
}) => {
  const { standardPositions, boxRotation, baseHeight } = props;

  const beamSpigotRotation = [
    boxRotation[0],
    boxRotation[1],
    plus(boxRotation[2], halfPi)
  ] as Vector3Tuple;

  const beamSpigots: BeamSpigot[] = [];

  standardPositions.forEach((pos) => {
    const beamSpigotPos = pos.clone().setY(pos.y + baseHeight);
    const beamSpigot: BeamSpigot = {
      id: genId(),
      position: beamSpigotPos.toArray(),
      length: 0.1,
      rotation: beamSpigotRotation,
      supplier: SCAFFOLD_SUPPLIER.HAKI,
      componentId: "beamSpigot"
    };
    beamSpigots.push(beamSpigot);
  });

  return beamSpigots;
};
