import { half, minus, plus, round, times } from "math";
import { Euler, Vector2, Vector2Tuple, Vector3, Vector3Tuple } from "three";
import { Ledger } from "world/core/Ledger/Ledger.types";
import { Console } from "world/core/Console/Console.types";
import { getEndPointFromStartPoint, isPointOnLineLimited } from "math/vectors";
import { Standard } from "world/core/Standard/Standard.types";
import { calculateSegmentLengthCombinations } from "math/optimizers";
import { Frame } from "world/core/Frame/Frame.types";
import { Box } from "shared/interfaces/firestore";

export const getStandardsRailTop = (
  guardRailOptions: [boolean, boolean, boolean, boolean]
) => {
  return [
    guardRailOptions[3] || guardRailOptions[0], // left or front
    guardRailOptions[1] || guardRailOptions[0], // right or front
    guardRailOptions[1] || guardRailOptions[2], // right or back
    guardRailOptions[3] || guardRailOptions[2] // left or back
  ] as [boolean, boolean, boolean, boolean];
};

export const getBoxStandardTopHeightFromXZPosition = (props: {
  xzPosition: Vector2Tuple;
  box: Box;
}) => {
  const { box } = props;
  const { options } = box;
  if (!options) return box.height;
  const { guardRails, fallProtectionHeight, standardPositions } = options;
  if (!standardPositions || !fallProtectionHeight || !guardRails)
    return box.height;
  const orderedStandardPositions = [
    standardPositions[2].position,
    standardPositions[3].position,
    standardPositions[1].position,
    standardPositions[0].position
  ] as [Vector3Tuple, Vector3Tuple, Vector3Tuple, Vector3Tuple];

  const guardRailTop = getStandardsRailTop(guardRails);
  const closestStandardPos = getClosestXZStandardPosition({
    xzPosition: new Vector2(...props.xzPosition),
    standardPositions: orderedStandardPositions
  });
  if (!closestStandardPos) return box.height;
  const closestStandardPosIdx =
    orderedStandardPositions.indexOf(closestStandardPos);
  const guardRailExtraHeight = guardRailTop[closestStandardPosIdx]
    ? fallProtectionHeight
    : 0;
  return plus(box.height, guardRailExtraHeight);
};

export const getClosestXZStandardPosition = (props: {
  xzPosition: Vector2;
  standardPositions: [Vector3Tuple, Vector3Tuple, Vector3Tuple, Vector3Tuple];
}) => {
  const { xzPosition, standardPositions } = props;
  const distances = standardPositions.map((standardPos) =>
    xzPosition.distanceTo(new Vector2(standardPos[0], standardPos[2]))
  );

  if (Math.min(...distances) > 0.01) return null;
  const closestStandardIdx = distances.indexOf(Math.min(...distances));
  return standardPositions[closestStandardIdx];
};

export const getFrameRailTop = (
  guardRailOptions: [boolean, boolean, boolean, boolean]
) => {
  return [
    guardRailOptions[3] || guardRailOptions[0] || guardRailOptions[1], // left, front, or right
    guardRailOptions[3] || guardRailOptions[2] || guardRailOptions[1] // left, back or right
  ] as [boolean, boolean];
};

export const calcCenterPositionsAlongLine = (props: {
  start: Vector3;
  end: Vector3;
  partitions: number[];
  spacing?: number;
}) => {
  const { start, end, partitions } = props;
  const spacing = props.spacing ?? 0;

  const direction = end.clone().sub(start);
  const directionLength = direction.length();
  const directionNorm = direction.clone().normalize();
  let totalPartitionLength = partitions.reduce(
    (acc, partition) => plus(acc, partition),
    0
  );
  totalPartitionLength = plus(
    totalPartitionLength,
    times(spacing, partitions.length - 1)
  );
  const deltaLengthAndPartitions = minus(directionLength, totalPartitionLength);

  const positions: Vector3[] = [];
  let accumulatedLength = 0;

  partitions.forEach((partition) => {
    let centerPos = plus(
      accumulatedLength,
      half(partition),
      half(deltaLengthAndPartitions)
    );

    positions.push(
      start.clone().add(directionNorm.clone().multiplyScalar(centerPos))
    );
    accumulatedLength = plus(accumulatedLength, partition, spacing);
  });

  return positions;
};

/**
 * Calculates the amount of paritions that can fill the distance.
 * The algorithm will use the largest partitions first.
 * @param length The distance to fill
 * @param partitions The partitions to use
 * @returns The partitions used and the remaining length
 */
export const calcLengthPartitions = (length: number, partitions: number[]) => {
  let remainingLength = length;
  const usedPartitions: number[] = [];
  const sortedPartitions = partitions.sort((a, b) => b - a);

  sortedPartitions.forEach((partition) => {
    while (remainingLength >= partition) {
      usedPartitions.push(partition);
      remainingLength = minus(remainingLength, partition);
    }
  });
  return {
    partitions: usedPartitions,
    remainingLength: remainingLength
  };
};

/**
 * Calculates the amount of paritions that can fill the distance.
 * The algorithm will use the largest partitions first.
 * @param length The distance to fill
 * @param partitions The partitions to use
 * @returns The partitions used and the remaining length
 */
export const calcLengthPartitionsAdvanced = (
  length: number,
  partitions: number[]
) => {
  const res = calculateSegmentLengthCombinations({
    lengthSegments: partitions,
    targetLength: length
  });

  const resAndLength = res.map((r) => ({
    combination: r,
    length: r.reduce((acc, val) => acc + val, 0)
  }));
  const optimalSolution = resAndLength.reduce((acc, val) =>
    Math.abs(val.length - length) < Math.abs(acc.length - length) ? val : acc
  );

  return {
    partitions: optimalSolution.combination,
    remainingLength: Math.abs(length - optimalSolution.length)
  };
};

/**
 * Calculates the amount of paritions that can fill the distance.
 * The algorithm will use the largest partitions first.
 * We must ensure that each deck level aligns with a generated frame
 * @param length The distance to fill
 * @param partitions The partitions to use
 * @returns The partitions used and the remaining length
 */
export const calcFrameLengthPartitions = (props: {
  partitions: number[];
  fallProtectionHeight?: number;
  deckLevels: number[];
  startHeight?: number;
  topVariant?: boolean;
}) => {
  const {
    partitions,
    fallProtectionHeight,
    deckLevels,
    startHeight,
    topVariant
  } = props;
  let currentHeight = startHeight ?? 0;
  let remainingLength = 0;

  const usedPartitions: number[] = [];
  const sortedPartitions = partitions.sort((a, b) => b - a);
  const halfMeterPartitionOptions = partitions.filter((p) => p % 0.5 === 0);
  const sortedDeckLevels = deckLevels
    .sort((a, b) => a - b)
    .map((d) => round(d, 2));

  sortedDeckLevels.forEach((deckLevel, idx) => {
    const patchDistance = round(deckLevel - currentHeight, 2);
    let remainingLegthToDeckLevel = 0;
    let partitions: number[] = [];
    // Simplified partition calc
    if (patchDistance > 4) {
      const calcPart = calcLengthPartitions(
        patchDistance,
        halfMeterPartitionOptions
      );
      partitions = calcPart.partitions;
      remainingLegthToDeckLevel = calcPart.remainingLength;
    } else {
      const calcAdvPart = calcLengthPartitionsAdvanced(
        patchDistance,
        sortedPartitions
      );
      partitions = calcAdvPart.partitions;
      remainingLegthToDeckLevel = calcAdvPart.remainingLength;
    }

    if (idx === 0) {
      usedPartitions.push(...partitions);
      currentHeight = deckLevel;
      remainingLength = remainingLegthToDeckLevel;

      if (idx === deckLevels.length - 1) {
        const fallProtectionPatch = calcLengthPartitions(
          fallProtectionHeight ?? 0,
          sortedPartitions
        );
        usedPartitions.push(...fallProtectionPatch.partitions);
      }
    } else if (idx === deckLevels.length - 1) {
      usedPartitions.push(...partitions);
      if (topVariant) {
        currentHeight = deckLevel;
        usedPartitions.push(1);
      } else {
        const fallProtectionPatch = calcLengthPartitions(
          fallProtectionHeight ?? 0,
          sortedPartitions
        );
        usedPartitions.push(...fallProtectionPatch.partitions);
        currentHeight = deckLevel;
      }
    } else {
      if (remainingLegthToDeckLevel !== 0) return;
      currentHeight = deckLevel;
      usedPartitions.push(...partitions);
    }
  });

  return {
    partitions: usedPartitions,
    remainingLength: remainingLength
  };
};

export const getBoxStandardPositions = (props: {
  position: Vector3Tuple;
  depth: number;
  width: number;
  rotation: Vector3Tuple;
  rayHitsYPosition: [number, number, number, number];
}) => {
  const { position, depth, width, rotation, rayHitsYPosition } = props;
  const boxCenter = new Vector3(...position);
  const boxRotation = new Euler(...rotation);

  const FL = boxCenter
    .clone()
    .setY(rayHitsYPosition[0])
    .add(new Vector3(half(width), 0, half(depth)));
  const FR = boxCenter
    .clone()
    .setY(rayHitsYPosition[1])
    .add(new Vector3(-half(width), 0, half(depth)));
  const BR = boxCenter
    .clone()
    .setY(rayHitsYPosition[2])
    .add(new Vector3(-half(width), 0, -half(depth)));
  const BL = boxCenter
    .clone()
    .setY(rayHitsYPosition[3])
    .add(new Vector3(half(width), 0, -half(depth)));
  const rotatedPoints = rotatePointsAroundCenter({
    points: [FL, FR, BR, BL],
    rotation: boxRotation,
    center: boxCenter
  });

  return {
    FL: rotatedPoints[0],
    FR: rotatedPoints[1],
    BR: rotatedPoints[2],
    BL: rotatedPoints[3]
  };
};

export const rotatePointsAroundCenter = (props: {
  points: Vector3[];
  rotation: Euler;
  center?: Vector3;
}) => {
  const { points, rotation, center } = props;
  const centerPoint = center || new Vector3(0, 0, 0);

  return points.map((point) => {
    const rotatedPoint = point.clone().sub(centerPoint);
    rotatedPoint.applyEuler(rotation);
    return rotatedPoint.add(centerPoint);
  });
};

export const rotateStandardPositions = (props: {
  standardPositions: Vector3[];
  oldRotation: Euler;
  newRotation: Euler;
  oldCenter: Vector3;
  newCenter: Vector3;
}) => {
  const { standardPositions, newRotation, oldRotation, oldCenter, newCenter } =
    props;

  const deltaRotation = new Euler(
    newRotation.x - oldRotation.x,
    newRotation.y - oldRotation.y,
    newRotation.z - oldRotation.z
  );

  const newStandardPositions = standardPositions.map((standardPosition) => {
    const dirVec = standardPosition.clone().sub(oldCenter);
    dirVec.applyEuler(deltaRotation);
    return newCenter.clone().add(dirVec);
  });

  return newStandardPositions;
};

export const roundVector = (vector: Vector3) => {
  const rounded = vector.toArray().map((pos) => round(pos, 2)) as Vector3Tuple;
  return new Vector3(...rounded);
};

export const splitStandards = (props: {
  standardsToSplit: Standard[];
  positions: Vector3Tuple[];
}) => {
  const { standardsToSplit, positions } = props;

  splitComponent({
    componentsToSplit: standardsToSplit,
    positions,
    componentDirectionY: true
  });
};

export const splitFrames = (props: {
  framesToSplit: Frame[];
  positions: Vector3Tuple[];
}) => {
  const { framesToSplit, positions } = props;

  splitFrameComponent({
    framesToSplit,
    positions
  });
};

const POSITION_VECTOR = new Vector3();

export const splitComponent = (props: {
  componentsToSplit: (Standard | Ledger | Console)[];
  positions: Vector3Tuple[];
  componentDirectionY?: boolean;
}) => {
  const { componentsToSplit, positions, componentDirectionY } = props;

  const componentsStartAndEndPositions = componentsToSplit.map((component) => ({
    start: new Vector3(...component.position),
    end: component.endPosition
      ? new Vector3(...component.endPosition)
      : componentDirectionY
        ? new Vector3(...component.position).setY(
            plus(component.position[1], component.length)
          )
        : getEndPointFromStartPoint({
            startPosition: component.position,
            length: component.length,
            rotation: component.rotation
          })
  }));

  positions.forEach((position) => {
    POSITION_VECTOR.set(...position);
    componentsToSplit.forEach((component, idx) => {
      const componentStart = componentsStartAndEndPositions[idx].start;
      const componentEnd = componentsStartAndEndPositions[idx].end;

      const isPointOnComponent = isPointOnLineLimited({
        pointA: componentStart,
        pointB: componentEnd,
        pointToCheck: POSITION_VECTOR
      });
      if (isPointOnComponent) {
        const distance = componentStart.distanceTo(POSITION_VECTOR);
        if (component.splits) {
          if (!component.splits.includes(distance)) {
            component.splits.push(distance);
          }
        } else {
          component.splits = [distance];
        }
      }
    });
  });
};

export const splitFrameComponent = (props: {
  framesToSplit: Frame[];
  positions: Vector3Tuple[];
}) => {
  const { framesToSplit, positions } = props;

  positions.forEach((position) => {
    POSITION_VECTOR.set(...position);
    framesToSplit.forEach((frame) => {
      const isPointOnLeftFrameStandard = isPointOnLineLimited({
        pointA: new Vector3(...frame.left.pos),
        pointB: new Vector3(...frame.left.endPos),
        pointToCheck: POSITION_VECTOR
      });
      const isPointOnRightFrameStandard = isPointOnLineLimited({
        pointA: new Vector3(...frame.right.pos),
        pointB: new Vector3(...frame.right.endPos),
        pointToCheck: POSITION_VECTOR
      });

      if (frame.top) {
        const isPointOnFrameTop = isPointOnLineLimited({
          pointA: new Vector3(...frame.top.pos),
          pointB: new Vector3(...frame.top.endPos),
          pointToCheck: POSITION_VECTOR
        });
        if (isPointOnLeftFrameStandard) {
          const distance = new Vector3(...frame.left.pos).distanceTo(
            POSITION_VECTOR
          );
          if (frame.left.splits) {
            if (!frame.left.splits.includes(distance)) {
              frame.left.splits.push(distance);
            }
          } else {
            frame.left.splits = [distance];
          }
        } else if (isPointOnRightFrameStandard) {
          const distance = new Vector3(...frame.right.pos).distanceTo(
            POSITION_VECTOR
          );
          if (frame.right.splits) {
            if (!frame.right.splits.includes(distance)) {
              frame.right.splits.push(distance);
            }
          } else {
            frame.right.splits = [distance];
          }
        } else if (isPointOnFrameTop) {
          const distance = new Vector3(...frame.top.pos).distanceTo(
            POSITION_VECTOR
          );
          if (frame.top.splits) {
            if (!frame.top.splits.includes(distance)) {
              frame.top.splits.push(distance);
            }
          } else {
            frame.top.splits = [distance];
          }
        }
      }
    });
  });
};

export const splitLedgers = (props: {
  ledgersToSplit: Ledger[];
  positions: Vector3Tuple[];
}) => {
  const { ledgersToSplit, positions } = props;
  splitComponent({
    componentsToSplit: ledgersToSplit,
    positions
  });
};

export const splitConsoles = (props: {
  consolesToSplit: Console[];
  positions: Vector3Tuple[];
}) => {
  const { consolesToSplit, positions } = props;

  splitComponent({
    componentsToSplit: consolesToSplit,
    positions
  });
};
