import Graph from "graphology";
import { Box } from "shared/interfaces/firestore";
import useStoreWithUndo from "store/store";
import { Object3D, Vector3Tuple } from "three";
import { GraphData } from "world/core/Standard/Standard.types";
import { BoxComponents } from "./box/box.interface";
import { round } from "lodash";
import { CoreComponentProps } from "world/World.types";

/**
 * Mutates the graph by adding a node to the graph
 * @param graph
 * @param pos
 * @param data
 * @returns mutated graph
 */
const addComponentNode = (props: {
  graph: Graph;
  posString: string;
  pos: Vector3Tuple;
  data: GraphData;
}) => {
  const { graph, posString, data, pos } = props;

  if (graph.hasNode(posString)) {
    graph.setNodeAttribute(posString, "component", [
      ...graph.getNodeAttributes(posString).component,
      data
    ]);

    return;
  }

  graph.addNode(posString, {
    component: [data],
    position: pos,
    type: "component"
  });
};

/**
 * Mutates the graph by removing a node from the graph
 * @param graph - graph to mutate
 * @param pos - position of the node to remove
 * @param data - data to remove from the node
 * @returns mutated graph
 */

const removeComponentNode = (props: {
  graph: Graph;
  pos: string;
  data: GraphData;
  force?: boolean;
}) => {
  const { graph, pos, data, force } = props;
  if (!graph.hasNode(pos)) return false;
  if (!force) {
    const isNodeBoxConnected = isNodeConnectedToBox(graph, pos);
    if (isNodeBoxConnected) {
      const boxNodeComponentIds = nodeToBoxConnectionComponentIds(graph, pos);
      if (boxNodeComponentIds.includes(data.id)) {
        return false;
      }
    }
  }
  const component = graph.getNodeAttribute(pos, "component") as GraphData[];
  const newComponentData = component.filter((c) => c.id !== data.id);

  // /** Merge in and outgoing edges if same component */
  // const uniqueComponentIds = Array.from(
  //   new Set(newComponentData.map((c) => c.id))
  // );

  // const nodeEdges = graph.edges(pos);
  // console.log(nodeEdges);
  // if (nodeEdges.length === 2 && uniqueComponentIds.length === 1) {
  //   const edgeComponents = nodeEdges.map(
  //     (edge) => graph.getEdgeAttribute(edge, "component") as GraphData
  //   );
  //   console.log("edgeComponents", edgeComponents);

  //   if (edgeComponents[0].id === edgeComponents[1].id) {
  //     nodeEdges.forEach((edge) => graph.dropEdge(edge));
  //     graph.addEdge(
  //       edgeComponents[0].startPosition,
  //       edgeComponents[0].endPosition,
  //       { component: edgeComponents[0], type: "component" }
  //     );
  //     console.log("node merged");
  //     graph.dropNode(pos);
  //     return;
  //   }
  // }

  if (component.length === 1 && component[0].id === data.id) {
    graph.dropNode(pos);
    return true;
  } else if (newComponentData.length === 0) {
    graph.dropNode(pos);
    return true;
  }

  graph.setNodeAttribute(pos, "component", newComponentData);
  return false;
};

const isNodeConnectedToBox = (
  graph: Graph,
  pos: string,
  excludeBoxIds?: string[]
) => {
  let boxConnectedPart = false;
  const excludedBoxIds = excludeBoxIds || [];

  if (!graph.hasNode(pos)) return false;
  graph.forEachEdge(pos, (edge, attributes, source) => {
    const edgeData = attributes as { id: string; type: string };
    if (edgeData.type === "box" && !excludedBoxIds.includes(source)) {
      boxConnectedPart = true;
    }
  });
  return boxConnectedPart;
};

export const getBoxIdsConnectedToNode = (graph: Graph, pos: string) => {
  const boxIds: string[] = [];
  if (!graph.hasNode(pos)) return boxIds;

  graph.forEachEdge(pos, (edge, attributes, source) => {
    const edgeData = attributes as { id: string; type: string };
    if (edgeData.type === "box" && !boxIds.includes(source)) {
      boxIds.push(source);
    }
  });
  return boxIds;
};

export const getBoxesConnectedToNode = (props: {
  graph: Graph;
  pos: string;
  boxes: Box[];
  excludeBoxIds?: string[];
}) => {
  const { graph, pos, boxes, excludeBoxIds } = props;
  const connectedBoxes: Box[] = [];
  if (!graph.hasNode(pos) || boxes.length <= 0) return connectedBoxes;

  graph.forEachEdge(pos, (edge, attributes, source) => {
    const edgeData = attributes as { type: string };
    if (
      edgeData.type !== "box" ||
      connectedBoxes.map((b) => b.id).includes(source) ||
      excludeBoxIds?.includes(source)
    )
      return;
    const connectedBox = boxes.find((box) => box.id === source);
    if (!connectedBox) return;
    connectedBoxes.push(connectedBox);
  });
  return connectedBoxes;
};

export const isComponentPartOfOtherBox = (props: {
  graph: Graph;
  component: CoreComponentProps;
  excludeBoxIds?: string[];
}) => {
  const { graph, excludeBoxIds, component } = props;
  const pos = toVectorStringRepresentation(component.position);

  const isNodeBoxConnected = isNodeConnectedToBox(graph, pos, excludeBoxIds);
  const nodeBoxConnectedComponentIds = nodeToBoxConnectionComponentIds(
    graph,
    pos,
    excludeBoxIds
  );

  return (
    isNodeBoxConnected && nodeBoxConnectedComponentIds.includes(component.id)
  );
};

export const isNodeComponentConnectedToBox = (props: {
  graph: Graph;
  node: string;
  componentId: string;
  excludeBoxIds?: string[];
}) => {
  const { graph, node, componentId, excludeBoxIds } = props;
  let boxConnectedPart = false;
  const excludedBoxIds = excludeBoxIds || [];

  if (!graph.hasNode(node)) return false;
  graph.forEachEdge(node, (edge, attributes, source) => {
    const edgeData = attributes as { component: string[]; type: string };

    if (
      edgeData.type === "box" &&
      !excludedBoxIds.includes(source) &&
      edgeData.component.includes(componentId)
    ) {
      boxConnectedPart = true;
    }
  });
  return boxConnectedPart;
};

export const nodeToBoxConnectionComponentIds = (
  graph: Graph,
  pos: string,
  excludeBoxIds?: string[]
) => {
  const matchedIds: string[] = [];
  if (!graph.hasNode(pos)) return matchedIds;
  const excludedBoxIds = excludeBoxIds || [];
  graph.forEachEdge(pos, (edge, attributes, source) => {
    const edgeData = attributes as { component: string[]; type: string };
    if (edgeData.type === "box" && !excludedBoxIds.includes(source)) {
      matchedIds.push(...edgeData.component);
    }
  });
  return matchedIds;
};

/**
 * Mutates the graph by removing an edge from the graph
 * @param graph - graph to mutate
 * @param startPos - start position of the edge to remove
 * @param endPos - end position of the edge to remove
 * @param data - data to remove from the edge
 * @returns mutated graph
 */
const removeComponentEdge = (props: {
  graph: Graph;
  startPos: string;
  endPos: string;
  data: GraphData;
  force?: boolean;
}) => {
  const { graph, startPos, endPos, data, force } = props;
  if (!force) {
    const isStartNodeBoxConnected = isNodeConnectedToBox(graph, startPos);
    const startNodeBoxConnectedComponentIds = nodeToBoxConnectionComponentIds(
      graph,
      startPos
    );

    const isEndNodeBoxConnected = isNodeConnectedToBox(graph, endPos);
    const endNodeBoxConnectedComponentIds = nodeToBoxConnectionComponentIds(
      graph,
      endPos
    );

    if (
      (isStartNodeBoxConnected &&
        startNodeBoxConnectedComponentIds.includes(data.id)) ||
      (isEndNodeBoxConnected &&
        endNodeBoxConnectedComponentIds.includes(data.id))
    ) {
      return false;
    }
  }
  if (!graph.hasEdge(startPos, endPos)) return true;

  graph.dropEdge(startPos, endPos);
  return true;
};

/**
 * Mutates the graph by adding a node to the graph
 * @param graph - graph to mutate
 * @param pos - position of the node to add
 * @returns mutated graph
 */
export const addNode = (graph: Graph, pos: string) => {
  if (graph.hasNode(pos)) {
    return;
  }

  graph.addNode(pos);
};

/**
 * Mutates the graph by adding an edge to the graph
 * @param graph - graph to mutate
 * @param startPos - start position of the edge to add
 * @param endPos - end position of the edge to add
 * @param data - data to add to the edge
 * @returns true if edge is added and false if not.
 */
const addComponentEdge = (props: {
  graph: Graph;
  startPos: string;
  endPos: string;
  data: GraphData;
}) => {
  const { graph, startPos, endPos, data } = props;

  if (graph.hasEdge(startPos, endPos)) return false;

  graph.addEdge(startPos, endPos, { component: data, type: "component" });
  return true;
};

export const toVectorStringRepresentation = (vector: Vector3Tuple) => {
  return (
    round(vector[0], 3) + "," + round(vector[1], 3) + "," + round(vector[2], 3)
  );
};

/**
 * Mutates the graph by adding nodes and edges to the graph for each component
 * @param props
 * @returns ids of the added components
 */
export const addComponentsToGraph = (props: {
  graph: Graph;
  components: GraphData[];
}) => {
  const { graph, components } = props;
  const addedComponentIds: string[] = [];
  const existingComponents: GraphData[] = [];

  for (let i = 0; i < components.length; i++) {
    const component = components[i];

    const startPosString = toVectorStringRepresentation(
      component.startPosition
    );
    const endPosString = toVectorStringRepresentation(component.endPosition);

    const hasStartNode = graph.hasNode(startPosString);
    const hasEndNode = graph.hasNode(endPosString);
    const hasEdge = graph.hasEdge(startPosString, endPosString);
    if (hasStartNode && hasEndNode && hasEdge) {
      const existingComponent = graph.getEdgeAttribute(
        startPosString,
        endPosString,
        "component"
      );

      existingComponents.push(existingComponent);
    } else {
      addComponentNode({
        graph,
        posString: startPosString,
        pos: component.startPosition,
        data: component
      });
      addComponentNode({
        graph,
        posString: endPosString,
        pos: component.endPosition,
        data: component
      });

      const addedEdge = addComponentEdge({
        graph,
        startPos: startPosString,
        endPos: endPosString,
        data: component
      });
      if (addedEdge) {
        addedComponentIds.push(component.id);
      }
    }
  }

  return { addedComponentIds, existingComponents };
};

/**
 * Mutates the graph by removing nodes and edges from the graph for each component
 * @param props
 * @returns mutated graph
 */
export const removeComponentsFromGraph = (props: {
  graph: Graph;
  components: GraphData[];
  force?: boolean;
}) => {
  const { graph, components, force } = props;
  const removedComponentIds: string[] = [];
  components.forEach((component) => {
    const isEdgeRemoved = removeComponentEdge({
      graph,
      startPos: toVectorStringRepresentation(component.startPosition),
      endPos: toVectorStringRepresentation(component.endPosition),
      data: component,
      force
    });

    if (isEdgeRemoved) {
      removeComponentNode({
        graph,
        pos: toVectorStringRepresentation(component.startPosition),
        data: component,
        force
      });
      removeComponentNode({
        graph,
        pos: toVectorStringRepresentation(component.endPosition),
        data: component,
        force
      });
      removedComponentIds.push(component.id);
    }
  });

  return removedComponentIds;
};

/**
 * Returns objects with the given key and value in their userData
 * @param props
 * @returns result array of objects
 */
export const getObjectsByUserData = (
  object: Object3D,
  key: string,
  value: string | number,
  result: Object3D[] = []
) => {
  if (object.userData[key] === value) {
    result.push(object);
  }

  for (let i = 0, l = object.children.length; i < l; i++) {
    const child = object.children[i];
    getObjectsByUserData(child, key, value, result);
  }

  return result;
};

export const getSelectedBoxComponents = (ids?: string[]) => {
  const {
    worldSelectedIds,
    beamSpigots,
    ledgers,
    standards,
    stairwayGuardRails,
    stairwayInnerGuardRails,
    stairways,
    consoles,
    planks,
    toeBoards,
    anchors,
    baseBoards,
    baseCollars,
    basePlates,
    diagonalBraces,
    frames,
    guardRails
  } = useStoreWithUndo.getState();

  const selectedIds = ids || worldSelectedIds;

  const selectedBoxComponents: BoxComponents = {
    ledgers: ledgers.filter((ledger) => selectedIds.includes(ledger.id)),
    beamSpigots: beamSpigots.filter((beamSpigot) =>
      selectedIds.includes(beamSpigot.id)
    ),
    standards: standards.filter((standard) =>
      selectedIds.includes(standard.id)
    ),
    stairwayGuardRails: stairwayGuardRails.filter((stairwayGuardRail) =>
      selectedIds.includes(stairwayGuardRail.id)
    ),
    stairwayInnerGuardRails: stairwayInnerGuardRails.filter(
      (stairwayInnerGuardRail) =>
        selectedIds.includes(stairwayInnerGuardRail.id)
    ),
    stairways: stairways.filter((stairway) =>
      selectedIds.includes(stairway.id)
    ),
    consoles: consoles.filter((console) => selectedIds.includes(console.id)),
    planks: planks.filter((plank) => selectedIds.includes(plank.id)),
    toeBoards: toeBoards.filter((toeBoard) =>
      selectedIds.includes(toeBoard.id)
    ),
    anchors: anchors.filter((anchor) => selectedIds.includes(anchor.id)),
    baseBoards: baseBoards.filter((baseBoard) =>
      selectedIds.includes(baseBoard.id)
    ),
    baseCollars: baseCollars.filter((baseCollar) =>
      selectedIds.includes(baseCollar.id)
    ),
    basePlates: basePlates.filter((basePlate) =>
      selectedIds.includes(basePlate.id)
    ),
    diagonalBraces: diagonalBraces.filter((diagonalBrace) =>
      selectedIds.includes(diagonalBrace.id)
    ),
    frames: frames.filter((frame) => selectedIds.includes(frame.id)),
    guardRails: guardRails.filter((guardRail) =>
      selectedIds.includes(guardRail.id)
    )
  };

  return selectedBoxComponents;
};
