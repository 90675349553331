import { SCAFFOLD_SUPPLIER } from "shared/enums/scaffold";
import { Supplier } from "./scaffold.interface";
import AllroundModul from "./layher/allroundModul/allroundModul";
import SpeedyScaf from "./layher/speedyScaf/speedyScaf";
import Universal from "./haki/universal/universal";
import SystemScaffold from "./pluseight/systemScaffold/systemScaffold";
import UpFlex from "./peri/upFlex/upFlex";

export const getScaffoldClass = (
  supplier: SCAFFOLD_SUPPLIER
): Supplier | null => {
  if (supplier === SCAFFOLD_SUPPLIER.LAYHER) {
    return new AllroundModul();
  } else if (supplier === SCAFFOLD_SUPPLIER.HAKI) {
    return new Universal();
  } else if (supplier === SCAFFOLD_SUPPLIER.SPEEDYSCAF) {
    return new SpeedyScaf();
  } else if (supplier === SCAFFOLD_SUPPLIER.PLUSEIGHT) {
    return new SystemScaffold();
  } else if (supplier === SCAFFOLD_SUPPLIER.UPFLEX) {
    return new UpFlex();
  }
  return null;
};
