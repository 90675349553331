import isDeepEqual from "fast-deep-equal/es6/react";
import { create, useStore } from "zustand";
import { devtools } from "zustand/middleware";
import { TemporalState, temporal } from "zundo";
import { PartializedStore, Store } from "./store.types";
import {
  createBlueprintSlice,
  createIfcSlice,
  createLedgerSlice,
  createMeasureSlice,
  createStandardSlice,
  createBaseBoardSlice,
  createBaseCollarSlice,
  createBasePlateSlice,
  createBoxSlice,
  createGraphSlice,
  createGroundSlice,
  createWorldPlaneSlice,
  createHelpersSlice,
  createPolygonSlice,
  createRoofSlice,
  createWorldSlice,
  createLightSlice,
  createBeamSpigotSlice
} from "store/world";
import {
  createToolsHouseSlice,
  createToolsBoxSlice,
  createToolsGroundSlice
} from "store/tools";
import createUserSlice from "store/user";
import createUiSlice from "store/ui";
import createCompanySlice from "store/company";
import createSnackbarSlice from "store/snackbar";
import createLoadingSlice from "store/loading";
import createByteTransferJobSlice from "./byteTransferJob";
import createPlankSlice from "./world/plank/plank";
import createToeBoardSlice from "./world/toeBoard/toeBoard";
import createConsoleSlice from "./world/console";
import createStairwaySlice from "./world/stairway";
import createTrsSlice from "./trs";
import createStairwayInnerGuardRailSlice from "./world/stairwayInnerGuardrail";
import createStairwayGuardRailSlice from "./world/stairwayGuardrail/stairwayGuardRails";
import createFemSlice from "./world/fem/fem";
import { genId } from "math/generators";
import createToolsStairBoxSlice from "./tools/stairBox/stairBox";
import createToolsAutoBoxSlice from "./tools/autoBox";
import createDiagonalBraceSlice from "./world/diagonalBrace";
import createAnchorSlice from "./world/anchor";
import createTutorialSlice from "store/tutorial";
import createToolsBoxPassageSlice from "./tools/boxPassage/boxPassage";
import createFrameSlice from "./world/frame";
import createGuardRailSlice from "./world/guardRail";

export const sliceResetFns = new Set<() => void>();

const resetStore = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });

  resetHelpers();

  useStoreWithUndo.temporal.getState().clear();
};

const resetHelpers = () => {
  useStoreWithUndo.getState().helperActions.setAxes([
    {
      id: genId(),
      position: [0, 0, 0],
      rotation: [0, 0, 0]
    }
  ]);
  useStoreWithUndo.getState().helperActions.setGrids([
    {
      id: genId(),
      position: [0, 0, 0],
      rotation: [0, 0, 0],
      size: 1000,
      divisions: 1000
    }
  ]);
  useStoreWithUndo.getState().worldPlaneActions.set({
    id: genId(),
    size: 1000
  });
  useStoreWithUndo.getState().lightActions.setAmbient([
    {
      id: genId(),
      position: [0, 100, 0],
      intensity: 1
    }
  ]);
  useStoreWithUndo.getState().lightActions.setDirectional([
    {
      id: genId(),
      position: [80, 40, 40],
      intensity: 2
    }
  ]);
};

const useStoreWithUndo = create<Store>()(
  devtools(
    temporal(
      (...set) => ({
        ...createUserSlice(...set),
        ...createUiSlice(...set),
        ...createCompanySlice(...set),
        ...createLoadingSlice(...set),
        ...createSnackbarSlice(...set),
        ...createBaseBoardSlice(...set),
        ...createBaseCollarSlice(...set),
        ...createBasePlateSlice(...set),
        ...createBlueprintSlice(...set),
        ...createBoxSlice(...set),
        ...createFemSlice(...set),
        ...createGraphSlice(...set),
        ...createGroundSlice(...set),
        ...createWorldPlaneSlice(...set),
        ...createIfcSlice(...set),
        ...createLedgerSlice(...set),
        ...createMeasureSlice(...set),
        ...createPolygonSlice(...set),
        ...createRoofSlice(...set),
        ...createStandardSlice(...set),
        ...createLightSlice(...set),
        ...createHelpersSlice(...set),
        ...createToolsHouseSlice(...set),
        ...createToolsGroundSlice(...set),
        ...createToolsBoxSlice(...set),
        ...createWorldSlice(...set),
        ...createByteTransferJobSlice(...set),
        ...createPlankSlice(...set),
        ...createToeBoardSlice(...set),
        ...createConsoleSlice(...set),
        ...createStairwaySlice(...set),
        ...createTrsSlice(...set),
        ...createStairwayInnerGuardRailSlice(...set),
        ...createStairwayGuardRailSlice(...set),
        ...createToolsStairBoxSlice(...set),
        ...createToolsAutoBoxSlice(...set),
        ...createDiagonalBraceSlice(...set),
        ...createAnchorSlice(...set),
        ...createTutorialSlice(...set),
        ...createToolsBoxPassageSlice(...set),
        ...createBeamSpigotSlice(...set),
        ...createFrameSlice(...set),
        ...createGuardRailSlice(...set)
      }),
      {
        /** States to track history */
        partialize: (state) => {
          const {
            anchors,
            baseBoards,
            baseCollars,
            basePlates,
            blueprints,
            boxes,
            consoles,
            diagonalBraces,
            graph,
            grounds,
            worldPlane,
            ifcModels,
            ledgers,
            lightsAmbient,
            lightsDirectional,
            lightsHemisphere,
            lightsPoint,
            lightsSpot,
            measurements,
            planks,
            polygons,
            roofs,
            stairwayGuardRails,
            stairwayInnerGuardRails,
            stairways,
            standards,
            toeBoards,
            beamSpigots,
            frames,
            guardRails
          } = state;

          return {
            anchors,
            baseBoards,
            baseCollars,
            basePlates,
            blueprints,
            boxes,
            consoles,
            diagonalBraces,
            graph,
            grounds,
            worldPlane,
            ifcModels,
            ledgers,
            lightsAmbient,
            lightsDirectional,
            lightsHemisphere,
            lightsPoint,
            lightsSpot,
            measurements,
            planks,
            polygons,
            roofs,
            stairwayGuardRails,
            stairwayInnerGuardRails,
            stairways,
            standards,
            toeBoards,
            beamSpigots,
            frames,
            guardRails
          };
        },
        limit: 100,
        equality: (prev, next) => isDeepEqual(prev, next)
      }
    ),
    {
      enabled: false
    }
  )
);

const useTemporalStore = <T extends unknown>(
  selector: (state: TemporalState<PartializedStore>) => T
) => useStore(useStoreWithUndo.temporal, selector);

export { useStoreWithUndo, useTemporalStore, resetStore, resetHelpers };
export default useStoreWithUndo;
