import { ReactElement } from "react";
import { Stack } from "@mui/material";

import InfoOverlayProps from "./InfoOverlay.types";
import { Outlet } from "react-router-dom";
import TrialInfo from "pages/sharedComponents/TrialInfo";
import { useIsAppLayout, useRightSidebarWidth } from "store/selectors/ui";
import UpdateInfo from "../UpdateInfo";
import Breakpoints from "managers/ThemeManager/theme/breakpoints";
import SnackbarInfo from "../SnackbarInfo";

const InfoOverlay = (props: InfoOverlayProps): ReactElement => {
  const isAppLayout = useIsAppLayout();
  const rightSidebarWidth = useRightSidebarWidth();

  const { belowSmallScreen } = Breakpoints();

  return (
    <>
      <Stack
        position={"absolute"}
        padding={2}
        bottom={0}
        right={!belowSmallScreen && isAppLayout ? rightSidebarWidth : 0}
        zIndex={1400}
        gap={1}
        alignItems={"flex-end"}
      >
        <SnackbarInfo />
        <TrialInfo />
        <UpdateInfo />
      </Stack>
      <Outlet />
    </>
  );
};

export default InfoOverlay;
