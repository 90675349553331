import { StateCreator } from "zustand";
import { Store } from "store/store.types";
import { PolygonSlice } from "./polygon.interface";
import useStoreWithUndo, { sliceResetFns } from "store/store";
import { Polygon } from "shared/interfaces/firestore";
import { isVector3Tuple } from "validation/three";
import { Shape, Vector3Tuple } from "three";
import { half, minus, plus } from "math";
import { HOUSE_TYPE } from "shared/enums/world";
import { addVector3Tuples } from "math/vectors";

/** Initial state */
const initialPolygonState = {
  polygons: []
};

/** Slice creation */
const createPolygonSlice: StateCreator<Store, [], [], PolygonSlice> = (set) => {
  /** Register reset function */
  sliceResetFns.add(() => set(initialPolygonState));

  /** Return state */
  return {
    ...initialPolygonState,
    polygonActions: {
      add: (polygons) => {
        set((state) => ({
          polygons: [...state.polygons, ...polygons]
        }));
      },
      set: (polygons) => {
        set({ polygons });
      },
      remove: (ids) => {
        set((state) => ({
          polygons: state.polygons.filter(
            (polygon) => !ids.includes(polygon.id)
          ),
          roofs: state.roofs.filter((roof) => !ids.includes(roof.polygonId))
        }));
      },
      heightOperation: (ids, operation, value) => {
        set((state) => ({
          polygons: state.polygons.map((polygon) => {
            if (!ids.includes(polygon.id)) return polygon;
            const heightDiff = operation === "add" ? value : -value;
            const newHeight = plus(polygon.height, heightDiff);
            if (newHeight <= 0.1) return polygon;
            return { ...polygon, height: newHeight };
          })
        }));
      },
      update: (ids, data) => {
        set((state) => ({
          polygons: state.polygons.map((polygon) => {
            if (!ids.includes(polygon.id)) return polygon;

            const cleanData = Object.entries(data).reduce(
              (acc, [key, value]) => {
                if (isVector3Tuple(value)) {
                  const polygonKeyValue = polygon[key] as Vector3Tuple;

                  acc[key] = value.map((v, idx) =>
                    isNaN(v) ? polygonKeyValue[idx] : v
                  ) as Vector3Tuple;
                } else {
                  acc[key] = value;
                }

                return acc;
              },
              {} as Partial<Polygon>
            );

            return { ...polygon, ...cleanData };
          })
        }));
      },
      updatePointsByLengthOnAxis: (ids, value, axis) => {
        set((state) => ({
          polygons: state.polygons.map((polygon) => {
            if (!ids.includes(polygon.id)) return polygon;

            const axisIdx = axis === "x" ? 2 : axis === "z" ? 0 : null;
            if (axisIdx === null) return polygon;

            const { points } = polygon;

            let newPoints = [...points];

            if (polygon.type === HOUSE_TYPE.RECTANGLE) {
              const p1 = points[0];
              const p2 = points[2];

              const delta = Math.abs(
                minus(p1.position[axisIdx], p2.position[axisIdx])
              );
              const scale = value / delta;

              newPoints = points.map((point) => {
                const newPosition = [...point.position].map((pos, idx) => {
                  if (idx === axisIdx) {
                    return pos * scale;
                  }

                  return pos;
                });

                return {
                  ...point,
                  position: newPosition as Vector3Tuple
                };
              });
            } else if (polygon.type === HOUSE_TYPE.CYLINDER) {
              const quarterIdx = half(half(points.length - 1));
              const p1 = points[0];
              const p2 = points[quarterIdx];

              const delta1 =
                axisIdx === 0
                  ? half(value)
                  : Math.abs(minus(p1.position[0], p2.position[0]));

              const delta2 =
                axisIdx === 2
                  ? half(value)
                  : Math.abs(minus(p1.position[2], p2.position[2]));

              const newShape = new Shape().absellipse(
                delta1,
                delta2,
                delta1,
                delta2,
                0,
                Math.PI * 2,
                false
              );

              newPoints = newShape.getPoints().map((point, idx) => ({
                position: [point.x, points[idx].position[1], point.y],
                id: points[idx].id
              }));
            }

            return { ...polygon, points: newPoints };
          })
        }));
      },
      addPosition: (ids, position) => {
        set((state) => ({
          polygons: state.polygons.map((polygon) => {
            if (!ids.includes(polygon.id)) return polygon;

            return {
              ...polygon,
              position: addVector3Tuples(polygon.position, position)
            };
          })
        }));
      }
    }
  };
};

export const applyPolygonDimensions = (
  dimensions: {
    id: string;
    position: Vector3Tuple;
    rotation: Vector3Tuple;
  }[]
) => {
  const state = useStoreWithUndo.getState();

  const ids = dimensions.map((p) => p.id);

  return state.polygons.map((polygon) => {
    if (!ids.includes(polygon.id)) return polygon;

    const dimensionObject = dimensions.find((p) => p.id === polygon.id);

    return {
      ...polygon,
      position: dimensionObject?.position ?? polygon.position,
      rotation: dimensionObject?.rotation ?? polygon.rotation
    };
  });
};

export default createPolygonSlice;
