import { FirebaseError } from "firebase/app";
import { DatabaseServiceProvider } from "./database.enum";
import { DatabaseService } from "./database.interface";
import {
  separateScaffoldDoc,
  initDatabaseService,
  genScaffoldFragmentsUpdates
} from "./database.utils";
import { DatabaseDoc } from "./database.type";
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import {
  CompanyDoc,
  FeedbackDoc,
  ProjectDoc,
  ReleaseDoc,
  ScaffoldDoc,
  TrsDoc,
  UserDoc
} from "shared/interfaces/firestore";

const usersCollection = "users";
const companyCollection = "companies";
const projectsCollection = "projects";
const scaffoldsCollection = "scaffolds";
const trsCollection = "temporaryRoofSystems";
const releaseCollection = "releases";
const feedbackCollection = "feedback";

class Database {
  /** The authService holds the abstracted database service */
  private databaseService: DatabaseService;

  /** The databaseService can be initialized with any DatabaseServiceProvider but default is .FIREBASE */
  public constructor(serviceProvider = DatabaseServiceProvider.FIREBASE) {
    /** A helper function is used to initialize the databaseService  */
    this.databaseService = initDatabaseService(serviceProvider);
  }

  public addProjectDoc = async (projectData: ProjectDoc) => {
    return this.databaseService.addDoc({
      collection: projectsCollection,
      data: projectData
    });
  };

  public addFeedbackDoc = async (feedbackData: FeedbackDoc) => {
    return this.databaseService.addDoc({
      collection: feedbackCollection,
      data: feedbackData
    });
  };

  public addScaffoldDoc = async (props: { doc: ScaffoldDoc }) => {
    const { doc } = props;

    const { scaffold } = separateScaffoldDoc(doc);

    return this.databaseService.addDoc({
      collection: scaffoldsCollection,
      data: scaffold as DatabaseDoc
    });
  };

  public addTRSDoc = async (trsData: TrsDoc) => {
    return this.databaseService.addDoc({
      collection: trsCollection,
      data: trsData as DatabaseDoc
    });
  };

  public deleteScaffoldDoc = async (scaffoldDocdId: string) => {
    /** Removing a scaffoldDoc will trigger a onDelete event in BE which will remove any ifcModels from storage */
    return this.databaseService.deleteDoc({
      collection: scaffoldsCollection,
      docId: scaffoldDocdId
    });
  };

  public deleteTRSDoc = async (trsDocId: string) => {
    return this.databaseService.deleteDoc({
      collection: trsCollection,
      docId: trsDocId
    });
  };

  public deleteProjectAndScaffoldDocs = async (projectDocId: string) => {
    const deleteDocs = [
      { docId: projectDocId, collection: projectsCollection }
    ];

    const scaffoldDocs = await this.databaseService.getCollection({
      collection: scaffoldsCollection,
      filter: "projectId",
      filterValue: projectDocId
    });
    scaffoldDocs.map((scaffoldDoc) =>
      deleteDocs.push({
        docId: scaffoldDoc.id,
        collection: scaffoldsCollection
      })
    );

    const trsDocs = await this.databaseService.getCollection({
      collection: trsCollection,
      filter: "projectId",
      filterValue: projectDocId
    });

    trsDocs.map((trsDoc) =>
      deleteDocs.push({ docId: trsDoc.id, collection: trsCollection })
    );

    /** Removing a scaffoldDoc will trigger a onDelete event in BE which will remove any ifcModels from storage */
    return this.databaseService.deleteDocs(deleteDocs);
  };

  public getUserDoc = async (uid: string) => {
    return this.databaseService.getDoc({
      collection: usersCollection,
      docId: uid
    });
  };

  public getScaffoldProjectDoc = async (scaffoldProjectDocId: string) => {
    return this.databaseService.getDoc({
      collection: scaffoldsCollection,
      docId: scaffoldProjectDocId
    });
  };

  public getTRSDoc = async (trsId: string) => {
    return this.databaseService.getDoc({
      collection: trsCollection,
      docId: trsId
    });
  };

  public getTRSDocSnap = async (trsId: string) => {
    return this.databaseService.getDocSnap({
      collection: trsCollection,
      docId: trsId
    });
  };

  public getScaffoldDocSnap = async (scaffoldId: string) => {
    return this.databaseService.getDocSnap({
      collection: scaffoldsCollection,
      docId: scaffoldId
    });
  };

  public getScaffoldDoc = async (scaffoldId: string) => {
    return this.databaseService.getDoc({
      collection: scaffoldsCollection,
      docId: scaffoldId
    });
  };

  public updateUserDoc = async (uid: string, newData: Partial<UserDoc>) => {
    return this.databaseService.updateDoc({
      collection: usersCollection,
      docId: uid,
      newData
    });
  };

  public updateCompanyDoc = async (
    companyDocId: string,
    newData: Partial<CompanyDoc>
  ) => {
    return this.databaseService.updateDoc({
      collection: companyCollection,
      docId: companyDocId,
      newData
    });
  };

  public updateProjectDoc = async (
    projectId: string,
    newData: Partial<ProjectDoc>
  ) => {
    return this.databaseService.updateDoc({
      collection: projectsCollection,
      docId: projectId,
      newData
    });
  };

  public updateTRSDoc = async (trsId: string, newData: Partial<TrsDoc>) => {
    return this.databaseService.updateDoc({
      collection: trsCollection,
      docId: trsId,
      newData
    });
  };

  public updateScaffoldDoc = async (props: {
    scaffoldDocId: string;
    partialDoc: Partial<ScaffoldDoc>;
  }) => {
    const { scaffoldDocId, partialDoc } = props;

    /** Split the scaffolddata into its root document and its fragments */
    const { fragments, scaffold } = separateScaffoldDoc(partialDoc);

    /** Create the fragments sub-collection path */
    const fragmentsCollectionPath = `${scaffoldsCollection}/${scaffoldDocId}/fragments`;

    /** Generate the referencese for each fragment */
    const fragmentUpdates = genScaffoldFragmentsUpdates({
      fragments
    }).map((fragment) => {
      const docRef = this.databaseService.generateDocRef(
        fragmentsCollectionPath,
        fragment.id
      );

      return {
        collection: fragmentsCollectionPath,
        data: { data: fragment.data } as any,
        docRef
      };
    });

    /** Create a batch for the fragments */
    const fragmentBatch = this.databaseService.addDocs(fragmentUpdates);

    /** Return a promise for both updates */
    return Promise.all([
      this.databaseService.updateDoc({
        collection: scaffoldsCollection,
        docId: scaffoldDocId,
        newData: scaffold
      }),
      fragmentBatch
    ]);
  };

  public duplicateProject = async (params: {
    projectId: string;
    userId: string;
  }) => {
    const { projectId, userId } = params;
    const projectDoc = await this.databaseService.getDoc({
      collection: projectsCollection,
      docId: projectId
    });
    if (projectDoc === null) {
      return Error("Project not found");
    }

    const projectDocData = projectDoc as ProjectDoc;

    const newProjectDoc = {
      ...projectDocData,
      name: `${projectDocData.name} (copy)`,
      created: Timestamp.now(),
      createdByUserId: userId,
      lastChangedByUserId: userId,
      lastChanged: Timestamp.now()
    } as ProjectDoc;

    return this.databaseService.addDoc({
      collection: projectsCollection,
      data: newProjectDoc
    });
  };

  public getProjectScaffoldDocs = async (projectId: string) => {
    return this.databaseService.getCollection({
      collection: scaffoldsCollection,
      filter: "projectId",
      filterValue: projectId
    });
  };

  public setTRSDoc = async (trsId: string, newData: TrsDoc) => {
    return this.databaseService.setDoc({
      collection: trsCollection,
      docId: trsId,
      newData
    });
  };

  public onUpdateUserDoc = (props: {
    uid: string;
    onSuccess: (userDoc: UserDoc) => void;
    onError?: (error?: FirebaseError) => void;
  }) => {
    const { uid, onSuccess, onError } = props;

    return this.databaseService.onUpdateDoc({
      collection: usersCollection,
      docId: uid,
      onSuccess: (doc) => {
        onSuccess(doc as UserDoc);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateCompanyDoc = (props: {
    companyId: string;
    onSuccess: (companyDoc: CompanyDoc) => void;
    onError?: (error?: FirebaseError) => void;
  }) => {
    const { companyId, onSuccess, onError } = props;

    return this.databaseService.onUpdateDoc({
      collection: companyCollection,
      docId: companyId,
      onSuccess: (doc) => {
        onSuccess(doc as CompanyDoc);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateCompanyDocs = (props: {
    onSuccess: (companyDoc: (CompanyDoc & { id: string })[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const { onSuccess, onError } = props;

    return this.databaseService.onUpdateCollection({
      collection: companyCollection,
      onSuccess: (docs) => {
        const linkedDocs = docs as (CompanyDoc & { id: string })[];
        onSuccess(linkedDocs);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateUserDocs = (props: {
    onSuccess: (companyDoc: (UserDoc & { id: string })[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const { onSuccess, onError } = props;

    return this.databaseService.onUpdateCollection({
      collection: usersCollection,
      onSuccess: (docs) => {
        const linkedDocs = docs as (UserDoc & { id: string })[];
        onSuccess(linkedDocs);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateCompanyUserDocs = (props: {
    companyId: string;
    onSuccess: (linkedDocs: (UserDoc & { id: string })[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const { companyId, onSuccess, onError } = props;

    return this.databaseService.onUpdateCollection({
      filterValue: companyId,
      collection: usersCollection,
      filter: "companyId",
      onSuccess: (docs) => {
        const linkedDocs = docs as (UserDoc & { id: string })[];
        onSuccess(linkedDocs);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateCompanyProjectDocs = (props: {
    companyId: string;
    onSuccess: (projectDocs: (ProjectDoc & { id: string })[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const { companyId, onSuccess, onError } = props;

    return this.databaseService.onUpdateCollection({
      filterValue: companyId,
      collection: projectsCollection,
      filter: "createdByCompanyId",
      onSuccess: (docs) => {
        onSuccess(docs as (ProjectDoc & { id: string })[]);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateProjectScaffoldsDocs = (props: {
    projectId: string;
    onSuccess: (scaffoldDocs: (ScaffoldDoc & { id: string })[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const { projectId, onSuccess, onError } = props;

    return this.databaseService.onUpdateCollection({
      filterValue: projectId,
      collection: scaffoldsCollection,
      filter: "projectId",
      onSuccess: (docs) => {
        onSuccess(docs as (ScaffoldDoc & { id: string })[]);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateProjectTrsDocs = (props: {
    projectId: string;
    onSuccess: (trsDocs: (TrsDoc & { id: string })[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const { projectId, onSuccess, onError } = props;

    return this.databaseService.onUpdateCollection({
      filterValue: projectId,
      collection: trsCollection,
      filter: "projectId",
      onSuccess: (docs) => {
        onSuccess(docs as (TrsDoc & { id: string })[]);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateProjectDoc = (props: {
    projectId: string;
    onSuccess: (projectDoc: ProjectDoc) => void;
    onError?: (error?: FirebaseError) => void;
  }) => {
    const { projectId, onSuccess, onError } = props;

    return this.databaseService.onUpdateDoc({
      collection: projectsCollection,
      docId: projectId,
      onSuccess: (doc) => {
        onSuccess(doc as ProjectDoc);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateCompanyScaffoldDocs = (props: {
    companyId: string;
    limit?: number;
    onSuccess: (scaffoldDocs: (ScaffoldDoc & { id: string })[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const { companyId, limit, onSuccess, onError } = props;

    return this.databaseService.onUpdateCalcsLimited({
      collection: scaffoldsCollection,
      limit: limit ?? undefined,
      filter: "createdByCompanyId",
      orderBy: "lastChanged",
      filterValue: companyId,
      onSuccess: (docs) => {
        const linkedDocs = docs as (ScaffoldDoc & { id: string })[];
        onSuccess(linkedDocs);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateCompanyTrsDocs = (props: {
    companyId: string;
    limit?: number;
    onSuccess: (scaffoldDocs: (TrsDoc & { id: string })[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const { companyId, limit, onSuccess, onError } = props;

    return this.databaseService.onUpdateCalcsLimited({
      collection: trsCollection,
      limit: limit ?? undefined,
      filter: "createdByCompanyId",
      orderBy: "lastChanged",
      filterValue: companyId,
      onSuccess: (docs) => {
        const linkedDocs = docs as (TrsDoc & { id: string })[];
        onSuccess(linkedDocs);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateScaffoldDoc = (props: {
    scaffoldId: string;
    onSuccess: (scaffoldDoc: ScaffoldDoc & { id: string }) => void;
    onError?: (error?: FirebaseError) => void;
  }) => {
    const { scaffoldId, onSuccess, onError } = props;

    let mergedScaffoldDoc: ScaffoldDoc & { id: string };
    let subScaffoldFinished = false;
    let subScaffoldFragmentsFinished = false;

    const reportFinished = () => {
      if (subScaffoldFinished && subScaffoldFragmentsFinished) {
        onSuccess(mergedScaffoldDoc);

        subScaffoldFinished = false;
        subScaffoldFragmentsFinished = false;
      }
    };

    const unsubScaffoldDoc = this.databaseService.onUpdateDoc({
      collection: scaffoldsCollection,
      docId: scaffoldId,
      onSuccess: (doc) => {
        const scaffoldDoc = doc as ScaffoldDoc & { id: string };
        mergedScaffoldDoc = { ...mergedScaffoldDoc, ...scaffoldDoc };
        subScaffoldFinished = true;
        reportFinished();
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });

    const fragmentsCollectionPath = `${scaffoldsCollection}/${scaffoldId}/fragments`;
    const unsubScaffoldFragmentsCollection =
      this.databaseService.onUpdateCollection({
        collection: fragmentsCollectionPath,
        onSuccess: (docs) => {
          let newDoc: any = {};

          const fragmentIds = [
            ...new Set(docs.map((doc) => doc.id.split("-")[0]))
          ];

          fragmentIds.forEach((fragmentId) => {
            const idFragments = docs
              .filter((doc) => doc.id.split("-")[0] === fragmentId)
              .map((doc: any) => doc.data)
              .flat();

            newDoc[fragmentId] = idFragments;
          });

          mergedScaffoldDoc = { ...mergedScaffoldDoc, ...newDoc };
          subScaffoldFragmentsFinished = true;
          reportFinished();
        },
        onError: (error) => {
          if (onError) onError(error);
        }
      });

    return () => {
      if (unsubScaffoldDoc) {
        unsubScaffoldDoc();
      }
      if (unsubScaffoldFragmentsCollection) {
        unsubScaffoldFragmentsCollection();
      }
    };
  };

  public onUpdateScaffoldDocs = (props: {
    limit?: number;
    startAfterDocSnap?: DocumentSnapshot<unknown>;
    endBeforeDocSnap?: DocumentSnapshot<unknown>;
    companyIdFilterValues?: string[];
    userIdFilterValues?: string[];
    docNameFilterValues?: string[];
    orderBy?: string;
    filterValue?: string;
    filter?:
      | "createdByCompanyId"
      | "createdByUserId"
      | "projectId"
      | "companyId"
      | "docId"
      | "sentForCalculationType"
      | "name";
    onSuccess: (linkedDocs: (ScaffoldDoc & { id: string })[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const {
      onSuccess,
      onError,
      limit,
      endBeforeDocSnap,
      startAfterDocSnap,
      companyIdFilterValues,
      userIdFilterValues,
      docNameFilterValues,
      filterValue,
      filter,
      orderBy
    } = props;

    return this.databaseService.onUpdateCalcCollection({
      collection: scaffoldsCollection,
      orderBy,
      limit,
      endBeforeDocSnap,
      startAfterDocSnap,
      companyIdFilterValues,
      userIdFilterValues,
      docNameFilterValues,
      filterValue,
      filter,
      onSuccess: (docs) => {
        const linkedDocs = docs as (ScaffoldDoc & { id: string })[];
        onSuccess(linkedDocs);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateTRSDoc = (props: {
    trsId: string;
    onSuccess: (trsDoc: TrsDoc & { id: string }) => void;
    onError?: (error?: FirebaseError) => void;
  }) => {
    const { trsId, onSuccess, onError } = props;

    return this.databaseService.onUpdateDoc({
      collection: trsCollection,
      docId: trsId,
      onSuccess: (doc) => {
        const trsDoc = doc as TrsDoc & { id: string };
        onSuccess(trsDoc);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateTRSDocs = (props: {
    limit?: number;
    startAfterDocSnap?: DocumentSnapshot<unknown>;
    endBeforeDocSnap?: DocumentSnapshot<unknown>;
    companyIdFilterValues?: string[];
    userIdFilterValues?: string[];
    docNameFilterValues?: string[];
    orderBy?: string;
    filterValue?: string;
    filter?:
      | "createdByCompanyId"
      | "createdByUserId"
      | "projectId"
      | "companyId"
      | "docId"
      | "sentForCalculationType"
      | "name";
    onSuccess: (linkedDocs: (TrsDoc & { id: string })[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const {
      onSuccess,
      onError,
      limit,
      endBeforeDocSnap,
      startAfterDocSnap,
      companyIdFilterValues,
      userIdFilterValues,
      docNameFilterValues,
      filterValue,
      filter,
      orderBy
    } = props;

    return this.databaseService.onUpdateCalcCollection({
      collection: trsCollection,
      orderBy,
      limit,
      endBeforeDocSnap,
      startAfterDocSnap,
      companyIdFilterValues,
      userIdFilterValues,
      docNameFilterValues,
      filterValue,
      filter,
      onSuccess: (docs) => {
        const linkedDocs = docs as (TrsDoc & { id: string })[];
        onSuccess(linkedDocs);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateProjectScaffoldDocs = (props: {
    projectId: string;
    onSuccess: (linkedDocs: (ScaffoldDoc & { id: string })[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const { projectId, onSuccess, onError } = props;

    return this.databaseService.onUpdateCollection({
      filterValue: projectId,
      collection: scaffoldsCollection,
      filter: "projectId",
      onSuccess: (docs) => {
        const linkedDocs = docs as (ScaffoldDoc & { id: string })[];
        onSuccess(linkedDocs);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public onUpdateProjectTRSDocs = (props: {
    projectId: string;
    onSuccess: (linkedDocs: (TrsDoc & { id: string })[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const { projectId, onSuccess, onError } = props;

    return this.databaseService.onUpdateCollection({
      filterValue: projectId,
      collection: trsCollection,
      filter: "projectId",
      onSuccess: (docs) => {
        const linkedDocs = docs as (TrsDoc & { id: string })[];
        onSuccess(linkedDocs);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };

  public getCollectionSize = async (props: {
    collection: string;
    companyIdFilterValues?: string[];
    userIdFilterValues?: string[];
    docNameFilterValues?: string[];
    filter?:
      | "createdByCompanyId"
      | "createdByUserId"
      | "projectId"
      | "companyId"
      | "docId"
      | "sentForCalculationType"
      | "name";
    filterValue?: string;
  }) => {
    const {
      collection,
      filterValue,
      filter,
      companyIdFilterValues,
      userIdFilterValues,
      docNameFilterValues
    } = props;
    return this.databaseService.getCollectionSize({
      companyIdFilterValues,
      userIdFilterValues,
      docNameFilterValues,
      collection,
      filter,
      filterValue
    });
  };

  public onUpdateReleaseDocs = (props: {
    onSuccess: (releaseDocs: ReleaseDoc[]) => void;
    onError?: (error: FirebaseError) => void;
  }) => {
    const { onSuccess, onError } = props;

    return this.databaseService.onUpdateCollection({
      collection: releaseCollection,
      onSuccess: (docs) => {
        const releaseDocs = docs as (ReleaseDoc & { id: string })[];
        onSuccess(releaseDocs);
      },
      onError: (error) => {
        if (onError) onError(error);
      }
    });
  };
}

/** Initialize a Database object */
const database = new Database();

export { database };
