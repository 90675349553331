import { FIREBASE_PROJECTID } from "services/firebase/enums";
import { DatabaseServiceProvider } from "./database.enum";
import FirebaseFirestore from "../firebase/firestore";
import getConfig from "services/firebase/config";
import {
  ScaffoldDoc,
  ScaffoldFragmentsCollection
} from "shared/interfaces/firestore";
import sizeof from "firestore-size";
import { MAX_DOCUMENT_SIZE } from "./database.constants";
import _ from "lodash";

export const initDatabaseService = (
  databaseProvider: DatabaseServiceProvider
) => {
  /** Extract projectId from env. variables */
  const envProjectId = process.env.REACT_APP_FB_ENV as FIREBASE_PROJECTID;

  if (databaseProvider === DatabaseServiceProvider.FIREBASE) {
    /** Get firebase config and return the FirebaseAuthentication service as the authService */
    const firebaseConfig = getConfig(envProjectId);
    return new FirebaseFirestore({
      config: firebaseConfig,
      isLocalDev: envProjectId === FIREBASE_PROJECTID.LOCAL
    });
  }

  /** Default return: Get firebase config and return the FirebaseAuthentication service as the authService */
  const firebaseConfig = getConfig(envProjectId);
  return new FirebaseFirestore({
    config: firebaseConfig,
    isLocalDev: envProjectId === FIREBASE_PROJECTID.LOCAL
  });
};

export const separateScaffoldDoc = (doc: Partial<ScaffoldDoc>) => {
  const {
    anchors,
    baseBoards,
    baseCollars,
    basePlates,
    beamSpigots,
    boxes,
    boxesComponentIds,
    consoles,
    diagonalBraces,
    ledgers,
    planks,
    stairwayGuardRails,
    stairwayInnerGuardRails,
    stairways,
    standards,
    toeBoards,
    frames,
    ...scaffold
  } = doc;

  const fragments: Partial<ScaffoldFragmentsCollection> = {
    anchors: anchors ?? [],
    baseBoards: baseBoards ?? [],
    baseCollars: baseCollars ?? [],
    basePlates: basePlates ?? [],
    beamSpigots: beamSpigots ?? [],
    boxes: boxes ?? [],
    boxesComponentIds: boxesComponentIds ?? [],
    consoles: consoles ?? [],
    diagonalBraces: diagonalBraces ?? [],
    ledgers: ledgers ?? [],
    planks: planks ?? [],
    stairwayGuardRails: stairwayGuardRails ?? [],
    stairwayInnerGuardRails: stairwayInnerGuardRails ?? [],
    stairways: stairways ?? [],
    standards: standards ?? [],
    toeBoards: toeBoards ?? [],
    frames: frames ?? []
  };

  return {
    fragments,
    scaffold
  };
};

export const genScaffoldFragmentsUpdates = (props: {
  fragments: Partial<ScaffoldFragmentsCollection>;
}) => {
  const { fragments } = props;

  /** Somethings is off with the type interpolation on fragment so hade to type it to any to make .chunk() work */
  const newFragments: { id: string; data: any }[] = [];
  Object.entries(fragments).forEach(([fragmentId, fragment]: [string, any]) => {
    if (fragment.length === 0) {
      newFragments.push({
        id: `${fragmentId}-${0}`,
        data: []
      });
    } else {
      const fragmentSize = sizeof(fragment);
      const nSplits = Math.ceil(fragmentSize / MAX_DOCUMENT_SIZE);
      const chunkSize = Math.ceil(fragment.length / nSplits);
      const chunks = _.chunk(fragment, chunkSize);
      chunks.forEach((chunk, idx) => {
        newFragments.push({
          id: `${fragmentId}-${idx}`,
          data: chunk
        });
      });
    }
  });

  return newFragments;
};
