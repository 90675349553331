import { half, round } from "math";
import config from "math/config";
import { SCAFFOLD_SUPPLIER } from "shared/enums/scaffold";
import { BoxFrame, StandardPosition } from "shared/interfaces/firestore";
import { getScaffoldClass } from "suppliers/scaffold";
import { rotatePointsAroundCenter } from "suppliers/scaffold/scaffold.utils";
import {
  Euler,
  Matrix4,
  Object3D,
  Quaternion,
  Raycaster,
  Vector3,
  Vector3Tuple
} from "three";
import { OBB } from "three/examples/jsm/Addons";
import { BoxFace } from "world/core/Box/Box.types";
import { STANDARD_RADIUS } from "world/core/Standard/constants";
import { calculateDirectedAngleBetweenVectors } from "world/manager/HouseBodyManager/HouseBodyManager.utils";

const EXTRA_SNAP_OFFSET_DISTANCE = STANDARD_RADIUS * 2;

export const compensateFace = (props: {
  position: Vector3;
  boxScale: Vector3;
  face: BoxFace | undefined;
  faceScale: Vector3;
  rotation: Euler;
}) => {
  const { position, boxScale, face, faceScale, rotation } = props;

  if (face === BoxFace.RIGHT) {
    return new Vector3(-half(boxScale.x), 0, 0)
      .sub(new Vector3(-half(faceScale.x), 0, 0))
      .applyAxisAngle(new Vector3(0, 1, 0), rotation.y)
      .add(position);
  } else if (face === BoxFace.LEFT) {
    return new Vector3(half(boxScale.x), 0, 0)
      .sub(new Vector3(half(faceScale.x), 0, 0))
      .applyAxisAngle(new Vector3(0, 1, 0), rotation.y)
      .add(position);
  } else if (face === BoxFace.FRONT) {
    return new Vector3(0, 0, half(boxScale.z))
      .sub(new Vector3(0, 0, half(faceScale.z)))
      .applyAxisAngle(new Vector3(0, 1, 0), rotation.y)
      .add(position);
  } else if (face === BoxFace.BACK) {
    return new Vector3(0, 0, -half(boxScale.z))
      .sub(new Vector3(0, 0, -half(faceScale.z)))
      .applyAxisAngle(new Vector3(0, 1, 0), rotation.y)
      .add(position);
  } else if (face === BoxFace.BOTTOM) {
    return new Vector3(0, -boxScale.y, 0)
      .sub(new Vector3(0, faceScale.y, 0))
      .applyAxisAngle(new Vector3(0, 1, 0), rotation.y)
      .add(position);
  } else if (face === BoxFace.TOP) {
    return new Vector3(0, 0, 0)
      .applyAxisAngle(new Vector3(0, 1, 0), rotation.y)
      .add(position);
  }

  return position;
};

export const getNBoxes = (distance: number, boxDepth: number) => {
  return Math.ceil(Math.max(distance - half(boxDepth), 0) / boxDepth) + 1;
};

export const calcDistance2D = (
  p1: Vector3,
  p2: Vector3,
  skipAxis: 0 | 1 | 2
) => {
  const p1Clone = p1.clone();
  const p2Clone = p2.clone();
  p1Clone.setComponent(skipAxis, 0);
  p2Clone.setComponent(skipAxis, 0);
  return p1Clone.distanceTo(p2Clone);
};

export const isTargetSameWidth = (props: {
  targetHalfSize: Vector3;
  width: number;
}) => {
  const { targetHalfSize, width } = props;

  return isRoundedSame(targetHalfSize.x * 2, width, 1);
};

export const isTargetSameDepth = (props: {
  targetHalfSize: Vector3;
  depth: number;
}) => {
  const { targetHalfSize, depth } = props;

  return isRoundedSame(targetHalfSize.z * 2, depth, 1);
};

const isRoundedSame = (a: number, b: number, precision?: number) => {
  const precisionDecimals = precision ? precision : config.decimals;
  return round(a, precisionDecimals) === round(b, precisionDecimals);
};

export const getCollisionFace = (props: {
  colliderCenter: Vector3;
  targetCenter: Vector3;
  targetRotation: Euler;
  targetHalfSize: Vector3;
}) => {
  const { colliderCenter, targetCenter, targetRotation, targetHalfSize } =
    props;

  const acAngle = Math.atan2(targetHalfSize.x, targetHalfSize.z);

  const targetVector = new Vector3(0, 0, 1).applyEuler(targetRotation);

  const colliderVector = colliderCenter.clone().sub(targetCenter).normalize();

  const angle = calculateDirectedAngleBetweenVectors({
    startPointVector: targetVector,
    endPointVector: colliderVector,
    middlePointVector: new Vector3(0, 1, 0)
  });

  if (angle > Math.PI + acAngle && angle < Math.PI * 2 - acAngle) {
    return BoxFace.RIGHT;
  } else if (angle > Math.PI - acAngle && angle < Math.PI + acAngle) {
    return BoxFace.BACK;
  } else if (angle > acAngle && angle < Math.PI - acAngle) {
    return BoxFace.LEFT;
  } else {
    return BoxFace.FRONT;
  }
};

export const getCollidedOBBs = (props: {
  targetObbs: { obb: OBB; id: string }[];
  colliderObb: OBB;
}) => {
  const { targetObbs, colliderObb } = props;

  return targetObbs.filter(({ obb }) => {
    return obb.intersectsOBB(colliderObb);
  });
};

export const getClosestOBB = (props: {
  targetObbs: { obb: OBB; id: string }[];
  colliderObb: OBB;
}) => {
  const { targetObbs, colliderObb } = props;

  if (targetObbs.length === 0) return undefined;
  return targetObbs.reduce((prev, curr) => {
    const prevDistance = prev.obb.center.distanceTo(colliderObb.center);
    const currDistance = curr.obb.center.distanceTo(colliderObb.center);

    return prevDistance < currDistance ? prev : curr;
  });
};

export const extraSnapOffset = (props: {
  supplier: SCAFFOLD_SUPPLIER;
  snapBoxFace: BoxFace;
  snapBoxSupplier?: SCAFFOLD_SUPPLIER;
}) => {
  const { supplier, snapBoxFace, snapBoxSupplier } = props;
  if (supplier !== snapBoxSupplier) return EXTRA_SNAP_OFFSET_DISTANCE;
  const isFrameSupplier = getScaffoldClass(supplier)?.isFrameSupplier();
  if (
    isFrameSupplier &&
    (snapBoxFace === BoxFace.LEFT || snapBoxFace === BoxFace.RIGHT)
  )
    return EXTRA_SNAP_OFFSET_DISTANCE;

  return undefined;
};

export const getSnapCenterPosition = (props: {
  targetObb: OBB;
  colliderObb: OBB;
  face: BoxFace;
  snapBoxRotation?: Euler;
  extraOffset?: number;
}) => {
  const { targetObb, colliderObb, face, snapBoxRotation, extraOffset } = props;
  const extraOffsetValue = extraOffset ? extraOffset : 0;

  const targetRotation = snapBoxRotation
    ? snapBoxRotation
    : new Euler().setFromQuaternion(
        new Quaternion().setFromRotationMatrix(
          new Matrix4().setFromMatrix3(targetObb.rotation)
        )
      );

  const offsetVector = new Vector3();
  if (face === BoxFace.LEFT) {
    offsetVector
      .set(
        targetObb.halfSize.x + colliderObb.halfSize.x + extraOffsetValue,
        0,
        0
      )
      .applyEuler(targetRotation);
  } else if (face === BoxFace.RIGHT) {
    offsetVector
      .set(
        -(targetObb.halfSize.x + colliderObb.halfSize.x + extraOffsetValue),
        0,
        0
      )
      .applyEuler(targetRotation);
  } else if (face === BoxFace.FRONT) {
    offsetVector
      .set(
        0,
        0,
        targetObb.halfSize.z + colliderObb.halfSize.z + extraOffsetValue
      )
      .applyEuler(targetRotation);
  } else if (face === BoxFace.BACK) {
    offsetVector
      .set(
        0,
        0,
        -targetObb.halfSize.z - colliderObb.halfSize.z - extraOffsetValue
      )
      .applyEuler(targetRotation);
  }

  const snapCenterPosition = offsetVector
    .clone()
    .add(targetObb.center)
    .setY(targetObb.center.y - targetObb.halfSize.y);

  return snapCenterPosition;
};

export const isLastBay = (props: {
  index: number;
  nBoxes: number;
  flip: boolean;
}) => {
  const { index, nBoxes, flip } = props;

  return flip ? index === 0 : index === nBoxes - 1;
};

export const isFirstBay = (props: {
  index: number;
  nBoxes: number;
  flip?: boolean;
}) => {
  const { index, nBoxes, flip } = props;

  return flip ? index === nBoxes - 1 : index === 0;
};

export const getAdjustedBoxFrames = (props: {
  boxFrames: BoxFrame[];
  standardPositions: StandardPosition[];
  boxMeshScale: Vector3;
  boxWorldPosition: Vector3;
}): BoxFrame[] => {
  const { boxFrames, standardPositions, boxMeshScale, boxWorldPosition } =
    props;

  /** Maximum standard y position */
  const maxYPosMilimeter = Math.round(
    Math.max(...standardPositions.map((pos) => pos.position[1])) * 1000
  );
  /** Minimum standard y position */
  const minYPosMilimeter = Math.round(
    Math.min(...standardPositions.map((pos) => pos.position[1])) * 1000
  );

  const heightAdjustmentMilimeter = getHeightAdjustmentMilimeter({
    boxMeshScale,
    boxWorldPosition,
    standardPositions
  });

  const deltaYPos = (maxYPosMilimeter - minYPosMilimeter) / 1000;

  return boxFrames
    .map((f) => ({
      ...f,
      height: Math.round(f.height * 1000 + heightAdjustmentMilimeter) / 1000,
      ...(f.platform && {
        platform:
          Math.round(f.platform * 1000 + heightAdjustmentMilimeter) / 1000
      })
    }))
    .filter((frame) => frame.height > deltaYPos + 0.5);
};

export const getAdjustedBoxHeight = (props: {
  boxMeshScale: Vector3;
  boxWorldPosition: Vector3;
  originalBoxHeight: number;
  standardPositions: StandardPosition[];
}): number => {
  const {
    boxMeshScale,
    boxWorldPosition,
    originalBoxHeight,
    standardPositions
  } = props;

  const originalBoxHeightMilimeter = Math.round(originalBoxHeight * 1000);

  const heightAdjustmentMilimeter = getHeightAdjustmentMilimeter({
    boxMeshScale,
    boxWorldPosition,
    standardPositions
  });

  return (
    Math.round(originalBoxHeightMilimeter + heightAdjustmentMilimeter) / 1000
  );
};

const getHeightAdjustmentMilimeter = (props: {
  boxMeshScale: Vector3;
  boxWorldPosition: Vector3;
  standardPositions: StandardPosition[];
}): number => {
  const { boxMeshScale, boxWorldPosition, standardPositions } = props;

  /** Minimum standard y position */
  const minYPosMilimeter = Math.round(
    Math.min(...standardPositions.map((p) => p.position[1])) * 1000
  );
  const boxWorldPositionMilimeter = Math.round(boxWorldPosition.y * 1000);
  const boxMeshScaleMilimeter = Math.round(boxMeshScale.y * 1000);

  /** Calculate box center bottom y position */
  const boxCenterBottomYPosMillimeter =
    boxWorldPositionMilimeter - boxMeshScaleMilimeter / 2;

  const heightAdjustmentMilimeter =
    boxCenterBottomYPosMillimeter - minYPosMilimeter;
  return heightAdjustmentMilimeter;
};

export const getAdjustedBoxPosition = (props: {
  boxWorldPosition: Vector3;
  standardPositions: StandardPosition[];
}): Vector3Tuple => {
  const { boxWorldPosition, standardPositions } = props;

  /** Minimum standard y position */
  const minYPos = calcMinyPos(standardPositions);

  return [boxWorldPosition.x, minYPos, boxWorldPosition.z];
};

const calcMinyPos = (standardPositions: StandardPosition[]) => {
  return round(Math.min(...standardPositions.map((pos) => pos.position[1])), 3);
};

export const getRayHitPosition = (props: {
  raycaster: Raycaster | null;
  intersectables: Object3D[];
  casters: {
    id: string;
    origin: Vector3;
    direction: Vector3;
    near: number;
    far: number;
  }[];
}) => {
  const { raycaster, intersectables, casters } = props;

  const hitPoints: Vector3Tuple[] = [];
  if (raycaster === null) return hitPoints;
  casters.forEach((caster) => {
    raycaster.ray.origin.copy(caster.origin);
    const intersections = raycaster.intersectObjects(intersectables);

    if (intersections.length > 0) {
      const hit = intersections[0];
      hitPoints.push(hit.point.toArray());
    } else {
      hitPoints.push([0, 0, 0]);
    }
  });

  return hitPoints;
};

export const getStandardPositions = (props: {
  rayHitPositions: Vector3Tuple[];
  idx: number;
  flip?: boolean;
}): [
  StandardPosition,
  StandardPosition,
  StandardPosition,
  StandardPosition
] => {
  const { rayHitPositions, idx, flip } = props;

  /** Extract standard y positions */
  const standardPos = rayHitPositions.slice(idx * 2, idx * 2 + 4) as [
    Vector3Tuple,
    Vector3Tuple,
    Vector3Tuple,
    Vector3Tuple
  ];
  if (flip) {
    standardPos.reverse();
  }

  const standardPositions = standardPos.map((pos) => ({
    position: pos
  })) as [
    StandardPosition,
    StandardPosition,
    StandardPosition,
    StandardPosition
  ];

  return standardPositions;
};

export const getOrderedStandardYPosition = (props: {
  standardPositions:
    | [StandardPosition, StandardPosition, StandardPosition, StandardPosition]
    | undefined;
}): [number, number, number, number] => {
  const { standardPositions } = props;
  if (!standardPositions) return [0, 0, 0, 0];

  const standardYPositions: [number, number, number, number] = [
    standardPositions[2].position[1],
    standardPositions[3].position[1],
    standardPositions[1].position[1],
    standardPositions[0].position[1]
  ];

  return standardYPositions;
};

export const calculatePositionFromHinge = (props: {
  boxScale: Vector3;
  hingePosition: Vector3;
  yAngle: number;
  boxInitRotation: Euler;
  negativeXOffset?: boolean;
}) => {
  const { boxScale, hingePosition, yAngle, boxInitRotation } = props;

  const aPoint = new Vector3(0, 0, boxScale.z / 2)
    .applyEuler(boxInitRotation)
    .add(hingePosition);

  const newAPointPosition = rotatePointsAroundCenter({
    points: [aPoint],
    center: hingePosition,
    rotation: new Euler(0, yAngle, 0)
  });

  const newBoxAngle = Math.atan2(
    hingePosition.x - newAPointPosition[0].x,
    hingePosition.z - newAPointPosition[0].z
  );

  const boxXOffset = props.negativeXOffset ? -boxScale.x / 2 : boxScale.x / 2;
  const newPoint = newAPointPosition[0]
    .clone()
    .add(
      new Vector3(boxXOffset, 0, 0).applyEuler(new Euler(0, newBoxAngle, 0))
    );

  return newPoint;
};

export const calculateRotationFromHinge = (props: {
  boxScale: Vector3;
  hingePosition: Vector3;
  yAngle: number;
  boxInitRotation: Euler;
}) => {
  const { boxScale, hingePosition, yAngle, boxInitRotation } = props;

  const aPoint = new Vector3(0, 0, boxScale.z / 2)
    .applyEuler(boxInitRotation)
    .add(hingePosition);

  const newAPointPosition = rotatePointsAroundCenter({
    points: [aPoint],
    center: hingePosition,
    rotation: new Euler(0, yAngle, 0)
  });

  const newBoxAngle = Math.atan2(
    hingePosition.x - newAPointPosition[0].x,
    hingePosition.z - newAPointPosition[0].z
  );

  return new Euler(0, newBoxAngle + Math.PI, 0);
};
