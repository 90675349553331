import Component, {
  BayBoxProps,
  LANGUAGE_CODE,
  PassageBoxProps,
  ReplacedComponent,
  StairBoxProps,
  Supplier
} from "suppliers/scaffold/scaffold.interface";
import {
  componentsDiagonalBraces,
  componentsLedgers,
  componentsDecks,
  componentsToeBoards,
  componentsStandards,
  componentsStairs,
  componentsStairwayGuardrails,
  componentsStairwayGuardrailsWideInner,
  componentsConsoles,
  componentsBaseplates,
  componentsBaseCollars,
  componentsAnchors
} from "./components";
import { SCAFFOLD_PLANKTYPE } from "shared/enums/scaffold";
import { calculateSegmentLengthCombinations } from "math/optimizers";
import {
  DEFAULT_FALL_PROTECTION_HEIGHT,
  MIN_STANDARD_HEIGHT
} from "suppliers/scaffold/constants";
import { minus, plus } from "math";
import {
  calcLengthPartitions,
  getBoxStandardPositions,
  getStandardsRailTop
} from "suppliers/scaffold/scaffold.utils";

import { Euler, Vector3 } from "three";
import {
  generateBaseComponents,
  generateConsoleComponents,
  generateDiagonalBraces,
  generateFrameAnchors,
  generateFrameDeckPlanks,
  generateFrameLedgers,
  generateGuardRails,
  generatePassageComponents,
  generateStairComponents,
  generateStandards,
  generateToeBoards
} from "./utils/component";
import { Ledger } from "world/core/Ledger/Ledger.types";
import {
  generateOptimalPlankConfiguration,
  getValidFrames
} from "./utils/utils";
import { Standard } from "world/core/Standard/Standard.types";
import { BaseCollar } from "world/core/BaseCollar/BaseCollar.types";
import { BaseBoard } from "world/core/BaseBoard/BaseBoard.types";
import { halfPi } from "math/constants";
import { Box, BoxFrame } from "shared/interfaces/firestore";
import { getOrderedStandardYPosition } from "pages/ScaffoldPage/deps/ToolManager/tools/BoxTool/BoxTool.utils";
import { BasePlate } from "world/core/BasePlate/BasePlate.types";

class UpFlex implements Supplier {
  private ledgerComponents: Component[] = [];

  constructor() {
    this.ledgerComponents = componentsLedgers;
  }

  isFrameSupplier(): boolean {
    return false;
  }

  getPlankMaterials() {
    const plankTypes = componentsDecks.map(
      (component) => component.material
    ) as SCAFFOLD_PLANKTYPE[];

    const uniquePlankTypes = new Set(plankTypes);

    return [...uniquePlankTypes];
  }

  getBayBoxLengths() {
    const ledgerLenghts = componentsLedgers.map(
      (component) => component.length
    );

    const uniqueLengths = new Set([...ledgerLenghts]);
    return [...uniqueLengths];
  }

  getBayBoxWidths(): number[] {
    return this.getBayBoxLengths();
  }

  getPassageBaseBoxWidths(): number[] {
    const ledgerLenghts = componentsLedgers.map(
      (component) => component.length
    );

    const uniqueLengths = new Set([...ledgerLenghts]);
    return [...uniqueLengths];
  }

  getStandardLengths() {
    const uniqueLengths = new Set(
      componentsStandards.map((standard) => standard.length)
    );
    return [...uniqueLengths];
  }

  generateBayBoxComponents(props: {
    boxProps: BayBoxProps;
    allBoxes?: Box[];
    skipStandardColumnComponents?: boolean;
    diagonalFrames?: BoxFrame[];
    standardsToCheck?: Standard[];
  }) {
    const {
      boxProps,
      allBoxes,
      skipStandardColumnComponents,
      diagonalFrames,
      standardsToCheck
    } = props;
    /** Extract box properties */
    const { height, depth, width, rotation, options, position, stair, id } =
      boxProps;

    const ledgers: Ledger[] = [];
    const standards: Standard[] = [];
    const baseCollars: BaseCollar[] = [];
    const baseBoards: BaseBoard[] = [];
    const basePlates: BasePlate[] = [];
    const replacedStandards: ReplacedComponent[] = [];

    /** Fallprotection height */
    const fallProtectionHeight =
      options?.fallProtectionHeight ?? DEFAULT_FALL_PROTECTION_HEIGHT;

    const standardPositions = options?.standardPositions
      ? ([
          new Vector3().fromArray(options?.standardPositions[2].position),
          new Vector3().fromArray(options?.standardPositions[3].position),
          new Vector3().fromArray(options?.standardPositions[1].position),
          new Vector3().fromArray(options?.standardPositions[0].position)
        ] as [Vector3, Vector3, Vector3, Vector3])
      : ([
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position)
        ] as [Vector3, Vector3, Vector3, Vector3]);

    /** Ray standard positions */
    const rayHitsYPosition = getOrderedStandardYPosition({
      standardPositions: options?.standardPositions
    });
    /** Top deck height */
    const topDeckHeight = plus(height, position[1]);

    /** Guard rails or not
     * F, R, B, L
     */
    const guardRailsOptions = options?.guardRails ?? [true, true, true, true];
    let standardsRailTop = getStandardsRailTop(guardRailsOptions);

    const diagonalBraceOptions = options?.diagonals ?? [
      false,
      false,
      false,
      false
    ];

    const anchorOptions = options?.anchors ?? [false, false, false, false];

    const frames = getValidFrames({
      frames: options?.frames,
      height,
      boxPosition: position,
      standardLengths: this.getStandardLengths()
    });

    const standardLengths = standardPositions.map((pos, index) => {
      return {
        pos: pos.clone().setY(plus(pos.y, MIN_STANDARD_HEIGHT)),
        standardLength: calcLengthPartitions(
          plus(
            minus(topDeckHeight, pos.y, MIN_STANDARD_HEIGHT),
            0.1,
            standardsRailTop[index] ? fallProtectionHeight : 0
          ),
          this.getStandardLengths()
        )
      };
    });

    if (!skipStandardColumnComponents) {
      /** Creation of standards */
      const standardComponents = generateStandards({
        standardLengths,
        boxRotation: rotation,
        boxId: id,
        allBoxes,
        standardsToCheck
      });
      standards.push(...standardComponents.standards);
      replacedStandards.push(...standardComponents.replacedStandards);

      /** Creation of:
       * - Base collars
       * - Base boards
       * - Base plates
       */
      const baseComponents = generateBaseComponents({
        standardLengths,
        boxOptions: options,
        rayHitsYPosition,
        boxRotation: rotation
      });
      baseCollars.push(...baseComponents.baseCollars);
      baseBoards.push(...baseComponents.baseBoards);
      basePlates.push(...baseComponents.basePlates);
    }

    /** Creation of longitudinal and transverse frame ledgers
     * - Builds from the back -> front
     */

    const { ledgers: frameLedgers, replacedLedgers } = generateFrameLedgers({
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      frames,
      standardsToSplit: standards,
      boxId: id
    });
    ledgers.push(...frameLedgers);

    /** Longitudinal Guard rails */
    ledgers.push(
      ...generateGuardRails({
        guardRailsOptions,
        standardPositions,
        boxRotation: rotation,
        depth,
        width,
        fallProtectionHeight,
        frames,
        standardsToSplit: standards
      })
    );

    /** Diagonal braces */
    const diagonalBraces = generateDiagonalBraces({
      standardPositions,
      frames: diagonalFrames ?? frames,
      depth,
      width,
      boxRotation: rotation,
      standardsToSplit: standards,
      diagonalBraceOptions,
      diagonalPattern: options?.diagonalPattern
    });

    /** Anchors */
    const anchors = generateFrameAnchors({
      standardPositions,
      frames,
      boxRotation: rotation,
      standardsToSplit: standards,
      anchorOptions
    });

    /** Planks */
    const frameDeckPlanks = generateFrameDeckPlanks({
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      frames,
      stair,
      ledgersToSplit: ledgers
    });

    /** Consoles */
    const { consoles, planks: consolePlanks } = generateConsoleComponents({
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      frames,
      consoleOptions: options?.consoles,
      consoleWidth: options?.consoleWidth
    });

    /** Longitudinal and transverse toe boards */
    const toeBoards = generateToeBoards({
      standardPositions,
      boxRotation: rotation,
      depth,
      width,
      frames,
      guardRailsOptions
    });

    const components = {
      baseBoards,
      baseCollars,
      basePlates,
      consoles,
      ledgers,
      planks: [...frameDeckPlanks, ...consolePlanks],
      stairwayGuardRails: [],
      stairwayInnerGuardRails: [],
      stairways: [],
      beamSpigots: [],
      frames: [],
      standards,
      toeBoards,
      diagonalBraces,
      anchors,
      guardRails: []
    };
    return {
      components,
      replacedComponents: [...replacedStandards, ...replacedLedgers]
    };
  }

  generatePassageBoxComponents(boxProps: PassageBoxProps, allBoxes?: Box[]) {
    const { height, depth, width, rotation, options, position, id } = boxProps;

    const standardPositions = options?.standardPositions
      ? ([
          new Vector3().fromArray(options?.standardPositions[2].position), //FR
          new Vector3().fromArray(options?.standardPositions[3].position), // FL
          new Vector3().fromArray(options?.standardPositions[1].position), //BL
          new Vector3().fromArray(options?.standardPositions[0].position) // BR
        ] as [Vector3, Vector3, Vector3, Vector3])
      : ([
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position),
          new Vector3().fromArray(position)
        ] as [Vector3, Vector3, Vector3, Vector3]);

    /** Fallprotection height */
    const fallProtectionHeight =
      options?.fallProtectionHeight ?? DEFAULT_FALL_PROTECTION_HEIGHT;

    /** Ray standard positions */
    const rayHitsYPosition = getOrderedStandardYPosition({
      standardPositions: options?.standardPositions
    });

    /** Top deck height */
    const topDeckHeight = plus(position[1], height);

    /** Guard rails or not
     * F, R, B, L
     */
    const guardRailsOptions = options?.guardRails ?? [true, true, true, true];
    let standardsRailTop = getStandardsRailTop(guardRailsOptions);

    /** passage Base height  */
    const topBaseHeight = plus(position[1], options?.baseHeight ?? 0);

    const baseHeight = options?.baseHeight ?? 0;
    const baseWidth = options?.baseWidth ?? width;

    const directionVector = new Vector3(0, 0, 1)
      .applyEuler(new Euler().fromArray(rotation))
      .applyAxisAngle(new Vector3(0, 1, 0), halfPi); // perpendicular to the box to get direction of width

    const scalarVector = directionVector.multiplyScalar(width);

    const topFRStandardPosition = standardPositions[1]
      .clone()
      .add(scalarVector);
    const topBRStandardPosition = standardPositions[2]
      .clone()
      .add(scalarVector);

    const topStandard = [
      { railIdx: 0, pos: topFRStandardPosition.clone() },
      { railIdx: 3, pos: topBRStandardPosition.clone() }
    ] as { railIdx: number; pos: Vector3 }[];
    const standardLengths = standardPositions.map((pos, index) => {
      const isOuterStandard = index === 0 || index === 3;
      const railTop = standardsRailTop[index] ? fallProtectionHeight : 0;

      const standardHeight = isOuterStandard
        ? topBaseHeight
        : topDeckHeight + railTop;

      return {
        pos: pos.clone().setY(plus(pos.y, MIN_STANDARD_HEIGHT)),
        standardLength: calcLengthPartitions(
          plus(minus(standardHeight, pos.y, MIN_STANDARD_HEIGHT), 0.1),
          this.getStandardLengths()
        )
      };
    });

    const topOuterstandardLengths = topStandard.map((standard) => {
      const railTop = standardsRailTop[standard.railIdx]
        ? fallProtectionHeight
        : 0;

      return {
        pos: standard.pos.clone().setY(plus(position[1], baseHeight)),
        standardLength: calcLengthPartitions(
          plus(minus(topDeckHeight + railTop, position[1], baseHeight), 0.1),
          this.getStandardLengths()
        )
      };
    });

    const topBayComponents = this.generateBayBoxComponents({
      boxProps: {
        ...boxProps,
        options: {
          ...boxProps.options,
          standardPositions: [
            // Screw up order of standard positions again
            { position: topBRStandardPosition.toArray() },
            { position: standardPositions[2].toArray() },
            { position: topFRStandardPosition.toArray() },
            { position: standardPositions[1].toArray() }
          ]
        }
      },
      allBoxes,
      skipStandardColumnComponents: true,
      diagonalFrames: options?.frames?.filter(
        (frame) => frame.height > plus(topBaseHeight, 2)
      )
    });
    /** Passage components */
    const passageComponents = generatePassageComponents({
      baseStandardLengths: standardLengths,
      topOuterStandardPositions: [topStandard[0].pos, topStandard[1].pos],
      boxOptions: options,
      rayHitsYPosition,
      boxRotation: rotation,
      depth,
      baseWidth,
      boxPosition: position
    });
    const innerStandardLengths = [standardLengths[1], standardLengths[2]];

    const innerStandards = generateStandards({
      standardLengths: innerStandardLengths,
      boxRotation: rotation,
      boxId: id,
      allBoxes
    });

    const outerStandards = generateStandards({
      standardLengths: topOuterstandardLengths,
      boxRotation: rotation,
      boxId: id,
      allBoxes
    });

    const components = {
      ...topBayComponents.components,
      beamSpigots: passageComponents.beamSpigots,
      baseCollars: passageComponents.baseCollars,
      basePlates: passageComponents.basePlates,
      ledgers: [
        ...topBayComponents.components.ledgers,
        ...passageComponents.ledgers
      ],
      standards: [
        ...passageComponents.standards,
        ...innerStandards.standards,
        ...outerStandards.standards
      ]
    };

    return {
      components,
      replacedComponents: topBayComponents.replacedComponents
    };
  }

  generateStairBoxComponents(props: {
    boxProps: StairBoxProps;
    allBoxes?: Box[];
    standardsToCheck?: Standard[];
  }) {
    const { boxProps, allBoxes, standardsToCheck } = props;
    const { height, depth, width, rotation, options, position } = boxProps;

    const {
      components: boxComponents,
      replacedComponents: replacedBoxComponents
    } = this.generateBayBoxComponents({
      boxProps: {
        ...boxProps,
        stair: true
      },
      allBoxes,
      standardsToCheck
    });

    /** Ray standard positions */
    const rayHitsYPosition = getOrderedStandardYPosition({
      standardPositions: options?.standardPositions
    });

    const frames = getValidFrames({
      frames: options?.frames,
      height,
      boxPosition: position,
      standardLengths: this.getStandardLengths()
    });

    /** Extract standardposition from box properties */
    const { FL, FR, BR, BL } = getBoxStandardPositions({
      position,
      rotation,
      depth,
      width,
      rayHitsYPosition
    });

    const standardPositions = [FL, FR, BR, BL] as [
      Vector3,
      Vector3,
      Vector3,
      Vector3
    ];

    /** Stairs */
    const { stairwayInnerGuardRails, stairways, stairwayGuardRails } =
      generateStairComponents({
        standardPositions,
        boxRotation: rotation,
        depth,
        frames,
        stairwayInnerGuardRail: options?.stairwayInnerGuardRail,
        stairwayGuardRail: options?.stairwayGuardRail
      });

    const components = {
      ...boxComponents,
      stairwayGuardRails,
      stairwayInnerGuardRails,
      stairways
    };

    return {
      components,
      replacedComponents: replacedBoxComponents
    };
  }

  getComponents() {
    return [
      ...componentsAnchors,
      ...componentsBaseCollars,
      ...componentsBaseplates,
      ...componentsConsoles,
      ...componentsDecks,
      ...componentsDiagonalBraces,
      ...componentsLedgers,
      ...componentsStairs,
      ...componentsStairwayGuardrails,
      ...componentsStairwayGuardrailsWideInner,
      ...componentsStandards,
      ...componentsToeBoards
    ];
  }

  getStairBoxLengths() {
    return componentsStairs.map((component) => component.length);
  }

  getStairBoxWidths() {
    const allLengths = this.getBayBoxLengths();

    const stairMinWidth = Math.min(
      ...componentsStairs.map((component) => component.width, 0)
    );
    return allLengths.filter((length) => length >= stairMinWidth);
  }

  getAvailableLockAnchorRanges() {
    return [{ min: 0, max: 360 }];
  }

  getPlankConfigurations = (props: {
    length: number;
    width: number;
    plankType?: SCAFFOLD_PLANKTYPE;
  }) => {
    const { length, width, plankType } = props;
    if (!plankType) return [];
    const decks = componentsDecks.filter((deck) => deck.material === plankType);

    const correctLengthDecks = decks.filter((deck) => deck.length === length);

    if (correctLengthDecks.length === 0) {
      return [];
    }

    const uniqueWidths = [
      ...new Set(correctLengthDecks.map((deck) => deck.width))
    ];

    const plankConfigurations = calculateSegmentLengthCombinations({
      lengthSegments: uniqueWidths,
      targetLength: width
    });

    return plankConfigurations;
  };

  getOptimalPlankConfiguration = (props: {
    length: number;
    width: number;
    plankType?: SCAFFOLD_PLANKTYPE;
  }) => {
    return generateOptimalPlankConfiguration(props);
  };

  getComponentLabel = (id: string, languageCode: LANGUAGE_CODE) => {
    const component = this.getComponents().find(
      (component) => component.article_id === id
    );

    if (!component) return "";
    switch (languageCode) {
      case LANGUAGE_CODE.SE:
        return component.label_se;
      case LANGUAGE_CODE.NO:
        return component.label_no;
      case LANGUAGE_CODE.EN:
      default:
        return component.label_en;
    }
  };

  getComponentWeight = (id: string) => {
    const component = this.getComponents().find(
      (component) => component.article_id === id
    );
    if (!component) return 0;
    return component.weight;
  };

  getConsoleWidths = () => {
    return componentsConsoles.map((component) => component.width);
  };

  getLedgerAlternatives(props: { length: number }) {
    const { length } = props;

    return this.ledgerComponents.filter((ledger) => ledger.length === length);
  }
}

export default UpFlex;
