import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactElement, useCallback, useEffect, useRef } from "react";
import { Unsubscribe } from "firebase/auth";
import { authentication, database } from "services";
import { CompanyDoc, UserDoc } from "shared/interfaces/firestore";
import { useUserActions, useUserAppPreferences } from "store/selectors/user";
import { useCompanyActions } from "store/selectors/company";
import { useSnackbarActions } from "store/selectors/snackbar";
import { useLoadingActions } from "store/selectors/loading";
import SessionManagerProps from "./SessionManager.types";
import { useTranslation } from "react-i18next";

const SessionManager = (props: SessionManagerProps): ReactElement => {
  /** Hook: store userActions */
  const {
    setUserEmail,
    setUserFirstName,
    setUserLastName,
    setUserId,
    clearUser,
    setUserAppPreferences,
    setUserScaffoldPreferences,
    setUserAchievements,
    setUserAccountType,
    setUserExpires,
    setUserRole
  } = useUserActions();

  // const userId = useUserId();

  const userAppPreferences = useUserAppPreferences();

  /** Hook: store companyAction */
  const { setCompanyName, setCompanyId, setCompanyUsers } = useCompanyActions();

  /** Hook: snackbar */
  const snackbarActions = useSnackbarActions();

  /** Hook: store loadingActions */
  const { setLoading } = useLoadingActions();

  const { i18n } = useTranslation();

  /** Hook: location */
  const location = useLocation();

  /** Hook: navigation */
  const navigate = useNavigate();

  /** Ref: unsubscribe function for onAuthStateChanged */
  const unSubOnAuthStateChanged = useRef<void | Unsubscribe>();
  /** Ref: unsubscribe function for onUpdateUserDoc */
  const unSubOnUpdateUserDoc = useRef<void | Unsubscribe>();
  /** Ref: unsubscribe function for onUpdateCompanyDoc */
  const unSubOnUpdateCompanyDoc = useRef<void | Unsubscribe>();
  /** Ref: unsubscribe function for onUpdateCompanyUsersDoc */
  const unSubOnUpdateCompanyUserDocs = useRef<void | Unsubscribe>();

  /** Callback: error handler */
  const handleOnError = useCallback(
    (error?: any) => {
      clearUser();
      if (unSubOnUpdateUserDoc.current) unSubOnUpdateUserDoc.current();
      if (unSubOnUpdateCompanyDoc.current) unSubOnUpdateCompanyDoc.current();
      if (unSubOnUpdateCompanyUserDocs.current)
        unSubOnUpdateCompanyUserDocs.current();
      setLoading(false);
      authentication.signOut();
      navigate("/login");
    },
    [clearUser, navigate, setLoading]
  );

  const handleOnUpdateCompanyDocSuccess = useCallback(
    (companyDoc: CompanyDoc, companyId: string) => {
      const { name } = companyDoc;
      setCompanyName(name);
      // storage.getCompanyLogoDownloadUrl({
      //   companyId,
      //   onSuccess: (logoUrl) => {
      //     setCompanyLogoUrl(logoUrl);
      //   },
      //   onError: () => {},
      // });
      // setLoading(false);
    },
    [setCompanyName]
  );

  const setUserStateProperties = useCallback(
    (userDoc: UserDoc, userId: string) => {
      const {
        email,
        firstName,
        lastName,
        companyId,
        appPreferences,
        scaffoldPreferences,
        accountType,
        expires,
        role,
        achievements
      } = userDoc;

      setUserEmail(email);
      setUserId(userId);
      setUserFirstName(firstName);
      setUserLastName(lastName);
      setCompanyId(companyId);
      setUserAppPreferences(appPreferences);
      setUserScaffoldPreferences(scaffoldPreferences);
      achievements && setUserAchievements(achievements);
      setUserAccountType(accountType);
      setUserExpires(expires.toDate());
      setUserRole(role);
    },
    [
      setUserEmail,
      setUserId,
      setUserFirstName,
      setUserLastName,
      setCompanyId,
      setUserAppPreferences,
      setUserScaffoldPreferences,
      setUserAchievements,
      setUserAccountType,
      setUserExpires,
      setUserRole
    ]
  );

  const handleOnEmailNotVerified = useCallback(() => {
    snackbarActions.add({
      text: "Please verify your email address",
      type: "error",
      key: "email-not-verified"
    });
    authentication.signOut();
  }, [snackbarActions]);

  const handleOnUpdateUserDocSuccess = useCallback(
    (userDoc: UserDoc, userId: string) => {
      const { companyId, active } = userDoc;

      if (!active) return handleOnError();
      setUserStateProperties(userDoc, userId);

      /** Setup company document listener and provide onSuccess function */
      unSubOnUpdateCompanyDoc.current = database.onUpdateCompanyDoc({
        companyId,
        onSuccess: (companyDoc) =>
          handleOnUpdateCompanyDocSuccess(companyDoc, companyId),
        onError: handleOnError
      });

      /** Setup company document listener and provide onSuccess function */
      unSubOnUpdateCompanyUserDocs.current = database.onUpdateCompanyUserDocs({
        companyId,
        onSuccess: (userDocs) => {
          setCompanyUsers(userDocs);
        },
        onError: handleOnError
      });
    },
    [
      handleOnError,
      handleOnUpdateCompanyDocSuccess,
      setCompanyUsers,
      setUserStateProperties
    ]
  );

  useEffect(() => {
    /** Setup listener on auth object and provide onUpdate function */
    unSubOnAuthStateChanged.current = authentication.onSessionUpdate(
      (authUser) => {
        if (!authUser) {
          handleOnError();
          return;
        }

        const { uid, emailVerfied } = authUser;
        if (!emailVerfied) {
          handleOnEmailNotVerified();
          return;
        }

        /** Setup user document listener and provide onSuccess function */
        unSubOnUpdateUserDoc.current = database.onUpdateUserDoc({
          uid,
          onSuccess: (userDoc) => handleOnUpdateUserDocSuccess(userDoc, uid),
          onError: handleOnError
        });

        /** Navigate to /home if current route is /login
         * otherwise continue with specified route
         */
        if (location.pathname === "/login") navigate("/home");
      }
    );
  }, [
    handleOnEmailNotVerified,
    handleOnError,
    handleOnUpdateUserDocSuccess,
    location.pathname,
    navigate
  ]);

  useEffect(() => {
    if (userAppPreferences?.languageCode)
      i18n.changeLanguage(userAppPreferences?.languageCode);
  }, [i18n, userAppPreferences?.languageCode]);

  return <Outlet />;
};

export default SessionManager;
